import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import SummaryTable from "./SummaryTable";
import { CalendarIconSmall } from "./icons";
import { format } from "date-fns";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataTableContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  factorTable: {
    marginBottom: 20,
    marginTop: 20,
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  factorLabel: {
    fontWeight: "600",
    fontSize: 10,
  },
  factorDescription: {
    fontSize: 10,
    marginBottom: 10,
  },
  descriptionTwo: {
    fontSize: 10,
    marginBottom: 10,
  },
  tableWrapper: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    borderRadius: 5,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingBottom: 5,
    paddingTop: 5,
  },
  rowHeader: {
    fontSize: 10,
    fontWeight: 600,
    alignItems: "center",
    display: "flex",
  },
  tableColumn: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  tableData: {
    fontSize: 10,
  },
  dateValue: {
    color: "#666d79",
    fontWeight: 500,
    fontSize: 10,
    marginTop: 3,
    marginRight: 15,
    marginLeft: 5,
  },
});

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const impactOptions = [
  {
    key: 1,
    value: "People",
    text: "People",
  },
  {
    key: 2,
    value: "System",
    text: "System",
  },
  {
    key: 3,
    value: "Culture",
    text: "Culture",
  },
];

const hpOutcomeOptions = [
  {
    key: 1,
    value: "Organizational Culture",
    text: "Organizational Culture",
  },
  {
    key: 2,
    value: "Clear Leadership",
    text: "Clear Leadership",
  },
  {
    key: 3,
    value: "Physical Safety",
    text: "Physical Safety",
  },
  {
    key: 4,
    value: "Recognition and Reward",
    text: "Recognition and Reward",
  },
  {
    key: 5,
    value: "Workload Management",
    text: "Workload Management",
  },
  {
    key: 6,
    value: "Growth and Development",
    text: "Growth and Development",
  },
  {
    key: 7,
    value: "Psychological support",
    text: "Psychological support",
  },
  {
    key: 8,
    value: "Promotion of Health",
    text: "Promotion of Health",
  },
];

const WellnessTable = (props) => {
  const { dimensions, id, data, primary } = props;

  const tableData = data?.planData?.[3];
  const dynamicRows = data?.planData[id];
  return (
    <View style={styles.container}>
      {data?.planData?.[3] && (
        <View style={styles.dataTableContainer}>
          <SummaryTable
            title={"Domain Overall Scores"}
            title2="By Roles"
            headers={["Athlete", "Coach", "SSSM", "NSO Staff"]}
            data={tableData[0]}
            isFrench={false}
            average={tableData[0]?.dimensionAvg}
          />
        </View>
      )}
      {dimensions?.map((wd, f) => (
        <View style={styles.factorTable} break={true}>
          <View style={styles.descriptionContainer}>
            <Text style={styles.factorLabel}>{wd?.title}</Text>
            <Text style={styles.factorDescription}>{wd?.first}</Text>
            <Text style={styles.descriptionTwo}>{wd?.second}</Text>
          </View>
          {dynamicRows?.[f]?.map((r, i) => {
            const altBackground = i % 2 === 0;
            return (
              <View style={styles.tableWrapper}>
                <View style={styles.tableContainer}>
                  <View style={styles.tableRow}>
                    <Text style={[styles.rowHeader, { width: 160 }]}>
                      NSO Initiative
                    </Text>
                    <Text style={[styles.rowHeader, { width: 225 }]}>
                      Description of Action
                    </Text>
                    <Text style={[styles.rowHeader, { width: 175 }]}>
                      HP Outcome(s)
                    </Text>
                    <Text style={[styles.rowHeader, { width: 145 }]}>
                      HP Impact(s)
                    </Text>
                    <Text style={[styles.rowHeader, { width: 135 }]}>
                      Lead Personnel
                    </Text>
                  </View>
                  <>
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: altBackground ? "#F8FAFF" : "#fff",
                          minHeight: 60,
                        },
                      ]}
                    >
                      <View style={[styles.tableColumn, { width: 160 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[0] || ""}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 225 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[1] || ""}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 175 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[2]?.map((o, i) => {
                            if (i === 0) return o;
                            return `, ${o}`;
                          })}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 145 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[3]?.map((o) => {
                            if (i === 0) return o;
                            return `, ${o}`;
                          })}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 135 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[4] || ""}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableRow}>
                      <Text style={[styles.rowHeader, { width: 160 }]}>
                        Budget
                      </Text>
                      <Text style={[styles.rowHeader, { width: 160 }]}>
                        Timeline
                      </Text>
                      <Text style={[styles.rowHeader, { width: 160 }]}>
                        Status
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: "#fff",
                        },
                      ]}
                    >
                      <View style={[styles.tableColumn, { width: 160 }]}>
                        <Text style={styles.tableData}>
                          {data?.planData?.[id]?.[f]?.[i]?.[5] || ""}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 160 }]}>
                        <CalendarIconSmall />
                        <Text style={styles.dateValue}>
                          {format(
                            (data?.planData?.[id]?.[f]?.[i]?.[6] &&
                              new Date(data?.planData?.[id]?.[f]?.[i]?.[6])) ||
                              new Date(),
                            "yyyy-MM-dd"
                          )}
                        </Text>
                      </View>
                      <View style={[styles.tableColumn, { width: 160 }]}>
                        <Text style={[styles.tableData, { color: "#2d70e2" }]}>
                          {planStatus?.find(
                            (s) =>
                              s?.value ===
                              props.data?.planData?.[id]?.[f]?.[i]?.[7]
                          )?.text || "Planned"}
                        </Text>
                      </View>
                    </View>
                  </>
                </View>
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
};

export default WellnessTable;
