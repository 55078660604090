import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import { erf } from "mathjs";
import { Modal, Icon, Popup, Message } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import QuestionDrop from "./QuestionDrop";
import useAutoHeight from "./useAutoHeight";
import { v4 as uuidv4 } from "uuid";
import NavBar2 from "./NavBar2";
import {
  SET_FILTERED_DATA,
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
  SET_WEB_CHAT_MESSAGES,
  SG_DELETE_CHAT_ROOM,
  CLEAR_DELETE_MESSAGE,
  SG_GET_CHAT_ROOM,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_FETCH_USER_EMPLOYEE,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SET_QUESTIONS,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SET_NAVIGATION,
  ADD_PENDING_MESSAGE,
  SET_SELECTED_QUESTION,
  SET_NEW_FILTERS,
  SG_GET_OUTCOME_QUESTIONS
} from "constants/actions";
import { useToasts } from "react-toast-notifications";
import { SURVEY_INFO } from "constants/surveys";
import SurveyVersions from "reports/Audit/VersionControl/index";
import useGetOrgData from "hooks/useGetOrgData";
import {
  filter_data
} from "../Tag/calculations";

import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  get_by_id,
  change_data,
} from "reports/Audit/data_navigation/calculate_data";

import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router";

import ChatHistory from "./ChatHistory";
import ChatWindow from "./ChatWindow";
import { SURVEY_QUESTION_TYPE } from "constants/surveys";

import { TEST_FEEDBACK } from "./InternalData/test_file";
import { EMP_FEEDBACK } from "./InternalData/emp_responses";
import { EMP_FEEDBACK_SMALL } from "./InternalData/emp_smaller_responses";

import { addIcon, chatBubble, elipseDot, toolTip, deleteIcon } from "./Icons";

import Back from "ProtoType/SimpleDash/Back"

const restructure_Questions = (questions) => {
  const questionsCopy = JSON.parse(JSON.stringify(questions));
  let d1 = {
    id: 1,
    title: "DEI Factors",
    factors: questionsCopy?.questions?.factors,
  };
  questionsCopy.questions.dimensions = [d1];

  return questionsCopy;
};

const checkPersonality = (org) => {
  return org.organization.services_enabled?.find((f) => f.id === 21)?.enabled;
};

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

// Using prioritiesToFilterStructure and surveyStructureFiltered
// find the matching priorities and from the surveyStructureFiltered?.categories?.id
// and filter out any of the options that are not in the prioritiesToFilterStructure?.values array.
// If the prioritiesToFilterStructure?.values array is empty, then return the surveyStructureFiltered
// as is.
const filterSurveyStructure = (
  surveyStructureFiltered,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return surveyStructureFiltered;
  }
  const filteredCategories = surveyStructureFiltered?.[0]?.categories?.map(
    (category) => {
      const permissionsToFilter = prioritiesToFilterStructure?.find(
        (priority) => {
          return priority?.priority === category?.id;
        }
      );
      if (!permissionsToFilter) {
        return category;
      }

      const filteredOptions = category?.options?.filter((option) => {
        return permissionsToFilter?.values?.includes(option?.id);
      });

      const updatedCategory = { ...category };
      updatedCategory.options = filteredOptions;
      return {
        ...category,
        options: filteredOptions,
      };
    }
  );

  const newSurveyStructureFiltered = {
    ...surveyStructureFiltered[0],
    categories: filteredCategories,
  };

  return [newSurveyStructureFiltered];
};

const convert_personality_to_categories = (resp) => {
  resp.map((item) => {
    item.responses.map((r) => {
      if (Object.keys(r.response.survey_personality).length > 0) {
        let personality = calculate_personality_categories(
          r.response.survey_personality
        );
        r.response.categories.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};

const convert_DEI = (resp) => {
  resp.map((item) => {
    item.responses = item.responses.map((r) => {
      r.response.questions = r.response.questions.map((q) => {
        q.id = 0;
        return q;
      });
      return r;
    });
    return item;
  });
  return resp;
};

const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

const personality_names_cat = [
  ["Extraversion", "Extraverted"],
  ["Conscientiousness", "Conscientiousness"],
  ["Agreeableness", "Agreeable"],
  ["Neuroticism", "Neurotic"],
  ["Openess", "Open"],
];

const personality_categories = personality_names_cat.map((item) => {
  return {
    id: item[0],
    name: item[0],
    options: [
      { id: "0", name: "Least " + item[1], color: "blue" },
      { id: "1", name: "Moderately " + item[1], color: "red" },
      { id: "2", name: "Most " + item[1], color: "green" },
    ],
    priority: "personality",
    subtitle: item,
    demographic: true,
  };
});

const AI = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams();

  const messagesEndRef = useRef(null);
  const { ref: inputRef, resize } = useAutoHeight(20);
  const [reportData2, setReportData2] = useState([]);
  const [text, setText] = useState("");
  const [prompt, setPrompt] = useState("");
  const [feedback, setFeedback] = useState("");
  const [question, setQuestion] = useState({ id: 0 });
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [feedback_length, setFLength] = useState(0);
  const [feedbackLoading, setFeedbackLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [sortedConversation, setSortedConversation] = useState();
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [waitingText, setWaitingText] = useState("Thinking");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteQueue, setDeleteQueue] = useState();
  const location = useLocation();
  const isDEI = location.pathname.includes("edi");
  const [questionHistory, setQuestionHistory] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [categoryAccess, setCategoryAccess] = useState(false);
  const [subAccess, setSubAccess] = useState(false);
  const [topicCreated, setTopicCreated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState();
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [newChannel, setNewChannel] = useState(0);
  const [selectedChatRoom, setSelectedChatRoom] = useState(false);
  const [pendingChatId, setPendingChatId] = useState(null);
  const [updatedMessage, setUpdatedMessage] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [hideInitial, setHideInitial] = useState(true);
  const [hideHeading, setHideHeading] = useState(false);
  const scrollRef = useRef(null);
  const [reportData, setReportData] = useState([]);
  const [tags, setTags] = useState([]);
  const [params, setParams] = useState({});
  const [outcomeQ, setOutcomeQ] = useState(null);

  const [cultureAuditQues, setCultureAuditQues] = useState();
  const [questionStructure, setQuestionStructure] = useState(null);

  const { organizationData } = useGetOrgData();

  // const token = "osowcnormp"; // TODO: Replace with your actual authentication token

  let surveyType = isDEI ? 7 : 6;
  let auditTypeId = isDEI ? 4 : 3;

  let simpleDash = location.pathname.includes("simple");
  // Perform your action for this specific path

  const {
    sub_state,
    nav_state,
    core_data,
    get_survey_structure,
    get_chat_topics,
    get_web_sock,
    chat_history,
    chatToken,
    employeeId,
    get_employee_categories,
    get_employee,
    ambassadorId,
    get_selectedOrg,
    get_auth,
    get_organizations,
    get_survey_questions,
    get_culture_audit_reports,
    pending_chat_topic,
    loadingChatHistory,
    get_employee_user_category,
    get_employee_permission,
    survey_version,
    selectedFilters,
    surveyQuestions,
  } = useSelector(
    (state) => ({
      get_employee_user_category:
        state.employee_category.employee_user_category,
      sub_state: state.audit.sub,
      nav_state: state.audit.nav,
      get_chat_topics: state.ai_chat?.chat_topics,
      chat_history: state.ai_chat?.chat_history,
      pending_chat_topic: state.ai_chat?.pending_chat_topic,
      loadingChatHistory: state.ai_chat?.isLoading,
      get_employee: state.employees,
      get_web_sock: state.ai_websocket,
      chatToken: state.auth.chat_token,
      employeeId: Number(state.auth?.employee_id),
      get_employee_categories: state.employee_category.employee_category,
      ambassadorId: Number(state.auth?.ambassador_id),
      get_selectedOrg: state.selectedOrg,
      get_auth: state.auth,
      get_survey_structure: state.surveystructure.survey_structure,
      get_organizations: state.organizations,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_employee_permission: state.employee_permission,
      survey_version: state.audit.survey_version,
      selectedFilters: state.audit?.new_filters,
      core_data: state.audit?.core_data,
      surveyQuestions: state.surveyquestions.outcome_questions,
    }),
    shallowEqual
  );

  const { get_error } = useSelector(
    (state) => ({
      get_error: state.ai_errors.error,
    }),
    shallowEqual
  );

  const filtered_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );

  useEffect(() => {
    if (surveyQuestions?.length === 0) {
      dispatch({
        type: SG_GET_OUTCOME_QUESTIONS,
        payload: `survey_type=${10}&status=2`,
      });
    }
  }, [surveyQuestions]);

  useEffect(() => {
    if (pending_chat_topic) {
      setPendingChatId(pending_chat_topic);
    }
  }, [pending_chat_topic]);

  // don't pre-select last conversation on render.
  useEffect(() => {
    setSelectedChatRoom(false);
  }, []);

  useEffect(() => {
    if (
      core_data?.categories &&
      core_data?.categories.length > 0 &&
      filteredCategories
    ) {
      let structure = core_data?.categories
        ? core_data?.categories
        : get_survey_structure;

      let surveyStructureFiltered = structure?.map((s) => {
        if (filteredCategories?.length > 0 && !isAdmin) {
          const tempCategories = [...s?.categories];
          const primaryCategories = s?.categories?.find(
            (c) => c.priority === "primary"
          );
          const replaceIndex = s?.categories?.findIndex(
            (c) => c.priority === "primary"
          );
          if (surveyType === 8) {
            const filteredOptions = primaryCategories?.options.filter((so) => {
              return so.subfactors.filter((sub) =>
                filteredCategories?.includes(sub.name)
              );
            });

            tempCategories[replaceIndex].options = filteredOptions;
            return { ...s, categories: tempCategories };
          } else {
            const filteredOptions = primaryCategories?.options.filter((so) => {
              return filteredCategories?.includes(so.name);
            });
            tempCategories[replaceIndex].options = filteredOptions;
            return { ...s, categories: tempCategories };
          }
        } else {
          return { ...s };
        }
      });

      if (
        get_organizations.organization.services_enabled?.find((f) => f.id == 21)
          ?.enabled &&
        surveyStructureFiltered[0]
      ) {
        if (
          !surveyStructureFiltered[0].categories.find(
            (f) => f.id == "Extraversion"
          )
        ) {
          surveyStructureFiltered[0].categories = [
            ...surveyStructureFiltered[0].categories,
            ...personality_categories,
          ];
        }
      }

      if (get_employee_permission?.employee_permission?.length > 0) {
        const currentEmployeePerms =
          get_employee_permission?.employee_permission?.filter(
            (f) => f.employee_id === employeeId
          );

        if (currentEmployeePerms?.length > 0) {
          const prioritiesToFilter = currentEmployeePerms[0]?.details?.map(
            (m) => {
              return { priority: m.name_id, value: m.value_id };
            }
          );

          // Create a new array of objects with prioritiesToFilter
          // loop through the array and add all of the values to a new array
          // with the same key as the priority.
          //  the structure should look like this:
          // [{priority: "d113fcb2-513c-4743-8e06-02534529da63", values: ["6a3a3a96-702e-4a15-9c52-fa390b202cc5"]}]
          const prioritiesToFilterStructure = prioritiesToFilter.reduce(
            (acc, curr) => {
              const { priority, value } = curr;
              const existingPriority = acc.find(
                (item) => item.priority === priority
              );
              if (existingPriority) {
                existingPriority.values.push(value);
              } else {
                acc.push({ priority, values: [value] });
              }
              return acc;
            },
            []
          );

          surveyStructureFiltered = filterSurveyStructure(
            surveyStructureFiltered,
            prioritiesToFilterStructure
          );
        }
      }
    }
  }, [
    get_survey_structure,
    filteredCategories,
    isAdmin,
    get_culture_audit_reports,
    core_data,
  ]);

  useEffect(() => {
    if (organizationId) {
      const organization = get_organizations?.[organizationId];
      let survey_type = isDEI ? SURVEY_INFO.edi.name : SURVEY_INFO.audit.name;
      const sortOrder =
        survey_version?.sort_order ||
        (organization?.services_enabled &&
          organization?.services_enabled.filter(
            (sc) => sc?.name === survey_type
          )?.[0]?.question_sort_order);

      setCultureAuditQues(sortOrder);
    }
  }, [get_organizations, organizationId, survey_version]);

  useEffect(() => {
    // We need to get the outcome question id
    // Then parse the outcome question list for the proper version
    const sortOrder = organizationData?.styling?.survey_sequence?.find(
      (item) => item.value === "outcome_question"
    )?.question?.sort_order;

    if (sortOrder) {
      const outcomeQ = surveyQuestions?.find(
        (item) => item.sort_order === sortOrder
      );
      setOutcomeQ(outcomeQ);
    }
  }, [organizationData, surveyQuestions]);

  useEffect(() => {
    let structure = core_data?.categories
      ? core_data?.categories
      : get_survey_structure;

    if (structure && structure[0] && survey_version) {
      const DefState = build_default_State(structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });

      if (get_culture_audit_reports.response) {
        if (get_culture_audit_reports.response.length > 0) {
          let sorted_responses = get_culture_audit_reports.response
            .filter((r) => {
              return r?.question_sort_order === survey_version?.sort_order;
            })
            .sort((a, b) => b.id - a.id);

            console.log(sorted_responses)

          if (checkPersonality(get_organizations)) {
            //Modify the results to include personality as a categorical variable
            sorted_responses =
              convert_personality_to_categories(sorted_responses);
          }

          if (isDEI) {
            sorted_responses = convert_DEI(sorted_responses);
          }

          change_data(sorted_responses[0], 10);

          // change_data(sorted_responses[0],10)
          let most_recent;
          most_recent = get_most_recent(sorted_responses);

          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );

            setReportData([most_recent, ...historical_data]);

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    core_data,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
  ]);

  useEffect(() => {
    if (cultureAuditQues > 0) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === cultureAuditQues
          )?.[0],
        };

        if (isDEI && SurveyQues && SurveyQues?.questions) {
          SurveyQues = restructure_Questions(SurveyQues);
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };

        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (isDEI && SurveyQues && SurveyQues?.questions) {
        SurveyQues = restructure_Questions(SurveyQues);
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, cultureAuditQues]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${get_auth?.employee_id}`,
      });
    }
  }, [dispatch, get_auth]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      const structure = core_data?.categories
        ? core_data?.categories
        : get_survey_structure;
      if (isAdmin) {
        const filterCat = structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
    core_data,
  ]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    setTimeout(() => {
      setHideInitial(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    if (get_employee) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employee?.userEmp);
      // check for admin
      if (get_employee.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employee.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employee.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employee]);

  useEffect(() => {
    setConversationHistory(get_chat_topics?.chat_topics);
  }, [get_chat_topics, topicCreated]);

  useEffect(() => {
    const findScrollableParent = (element) => {
      if (!element) return null;

      const computedStyle = window.getComputedStyle(element);
      if (
        computedStyle.overflowY === "scroll" ||
        computedStyle.overflowY === "auto"
      ) {
        return element;
      } else {
        return findScrollableParent(element.parentElement);
      }
    };

    const handleScroll = () => {
      // ... your logic here ...
    };

    const scrollableParent = findScrollableParent(
      scrollRef.current.parentElement
    );

    if (scrollableParent) {
      scrollableParent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableParent) {
        scrollableParent.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const sameDay = (d1, d2) => {
    const dateOne = new Date(d1);
    const dateTwo = new Date(d2);
    return (
      dateOne.getFullYear() === dateTwo.getFullYear() &&
      dateOne.getMonth() === dateTwo.getMonth() &&
      dateOne.getDate() === dateTwo.getDate()
    );
  };

  const groupConversationsByDate = (conversations) => {
    return conversations.reduce((acc, conversation) => {
      const date = conversation.date;
      const conversationDate = acc.find((item) => sameDay(item.date, date));
      if (conversationDate) {
        conversationDate.conversations.push(conversation);
      } else {
        acc.push({
          date,
          conversations: [conversation],
        });
      }
      return acc;
    }, []);
  };

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);
  const { get_chat_gpt_resp, get_chat_gpt } = useSelector(
    (state) => ({
      get_chat_gpt_resp: state.chat_gpt.chat_gpt,
      get_chat_gpt: state.chat_gpt,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (chat_history && selectedConversation) {
      const history = chat_history?.interaction_history;
      if (history) {
        const historyFormatted = history?.map((h) => {
          const responseFormatted = h?.server?.response?.replace(
            "Processed message:",
            ""
          );
          return {
            q: h?.message,
            a: responseFormatted,
          };
        });
        setQuestionHistory(historyFormatted);
        setLoading(false);
      }
    }
  }, [chat_history, selectedConversation]);

  useEffect(() => {
    if (conversationHistory?.interaction_history?.length > 0) {
      dispatch({
        type: SET_WEB_CHAT_MESSAGES,
        payload: conversationHistory?.interaction_history,
      });
    }
  }, [dispatch, conversationHistory]);

  useEffect(() => {
    if (get_employee.length < 1) {
      return;
    }
    let role = get_employee?.userEmp?.account_type_text === "Admin";
    if (
      get_employee.userEmp.employee_employee_category?.category.length > 0 &&
      !role
    ) {
      setCategoryAccess(
        get_employee.userEmp.employee_employee_category.category
      );
    }

    setSubAccess(get_employee.userEmp.access_subfactor_data);
  }, [get_auth, get_employee_categories]);

  useEffect(() => {
    if (deleteQueue) {
      setShowDeleteConfirm(true);
    }
  }, [deleteQueue]);

  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth]);

  useEffect(() => {
    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [dispatch, employeeId]);

  useEffect(() => {
    dispatch({
      type: SG_FETCH_USER_EMPLOYEE,
      payload: {
        id: Number(get_auth?.employee_id),
      },
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${
          isDEI ? SURVEY_QUESTION_TYPE.EDI : SURVEY_QUESTION_TYPE.AUDIT
        }&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}&organization=${organizationId}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}`,
        });
      }
    }
  }, [dispatch, organizationId]);

  // useEffect(() => {
  //   setMessages(chatHistory?.interaction_history);
  // }, [chatHistory])

  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        if (waitingText === "Thinking") {
          setWaitingText("Still thinking");
        } else {
          setWaitingText("Thinking");
        }
      }, 5000);
    } else {
      return interval;
    }
  }, [loading]);

  useEffect(() => {
    if (conversationHistory?.length > 0) {
      const sorted = groupConversationsByDate(conversationHistory);
      setSortedConversation(sorted);
    }
  }, [conversationHistory]);

  useEffect(() => {
    if (get_chat_topics?.deleted) {
      addToast("Conversation successfully deleted", {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch({
        type: CLEAR_DELETE_MESSAGE,
      });
    }
  }, [dispatch, get_chat_topics]);

  useEffect(() => {
    // pending once action is completed
    setLoading(get_chat_gpt.pending);
    // errors in getting response from server
    setError(get_chat_gpt.chat_gpt_error);
    if (get_chat_gpt.chat_gpt_error) {
      setText(
        "An error occured. If this issue persists please contact us for support."
      );
      setLoading(false);
    }
    // errors in executing

    // completed response
    if (get_chat_gpt.chat_gpt_ml?.response) {
      setText(get_chat_gpt.chat_gpt_ml?.response);
      setLoading(false);
    }
    // completed response other info
  }, [get_chat_gpt]);

  function ClearChatGpt() {
    setFormErrors([]);
    setPristine(true);
    setText("");
    setPrompt("");
    setLoading(false);
    resize();
  }

  useEffect(() => {
    setLoading(get_chat_gpt_resp.pending);
    setText(get_chat_gpt_resp?.response?.ai_result);
    setLoading(false);
    setPrompt("");
  }, [get_chat_gpt_resp]);

  const getFeedbackContext = () => {
    let _data = [];
    let filteredData = filtered_data;
    if (filtered_data?.[0]?.[0]) {
      filteredData = filtered_data[0];
    } else {
      filteredData = filtered_data;
    }

    filteredData
      ?.filter((f) => "feedback" in f)
      ?.filter((f) => f.feedback.length > 0)
      ?.map((item) => {
        item.feedback.map((f) => {
          _data.push({ feedback: f, categories: item.categories });
        });
      });

    filteredData
      ?.filter((f) => "feedback_builder" in f)
      ?.filter((f) => {
        return f.feedback_builder?.responses?.length > 0;
      })
      ?.map((item) => {
        item?.feedback_builder?.responses
          ?.filter((f) => f?.response)
          ?.map((f) => {
            _data.push({
              feedback: f?.responses || f,
              categories: item?.categories,
              type: "outcome",
            });
          });
      });

    filteredData
      ?.filter((f) => "comments" in f)
      ?.filter((f) => f.comments?.length > 0)
      ?.map((item) => {
        item.comments
          .filter((f) => f.feedback)
          .map((f, i) => {
            _data.push({
              feedback: f,
              categories: item.categories,
              type: "comments",
              id: i,
            });
          });
      });

    return _data;
  };

  function handleKeyDown(e) {
    if (!loading) {
      if (e.key === "Enter" && prompt.trim().length !== 0) {
        setQuery(prompt);
        // Call your function here
        console.log("Enter key pressed while input is selected");
        call_API(prompt);
        setTimeout(() => {
          e.preventDefault();
          setPrompt("");
          resize();
        }, 150);
      } else if (e.key === "Enter" && prompt.trim().length === 0) {
        e.preventDefault();
        resize();
      } else if (e.key === "Enter" && prompt.trim().length !== 0 && loading) {
        e.preventDefault();
        resize();
      }
    } else {
      setTimeout(() => {
        setPrompt("");
        resize();
      }, 15);
    }
  }

  // useEffect(() => {
  //   if (!updatedMessage && get_web_sock.ws && query) {
  //     if (!get_web_sock.pending) {
  //       setUpdatedMessage(true);
  //       const updatedChat = [...questionHistory];

  //       updatedChat.push({
  //         q: query,
  //         a: get_web_sock?.data?.server?.response,
  //         error: get_web_sock?.data?.server?.error,
  //       });
  //       setQuestionHistory(updatedChat);
  //       setLoading(false);
  //     }
  //   }
  // }, [prompt, loading, get_web_sock]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      let _tags = selectedFilters.map((item) => item.name);
      setTags(_tags);
    }

    if (reportData && reportData.length > 0) {
      dispatch({
        type: SET_FILTERED_DATA,
        payload: filter_data(reportData[0]?.responses, selectedFilters),
      });
    }
  }, [selectedFilters, reportData]);

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [questionHistory]);

  const format_text = (text) => {
    const num = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let _text = text;
    num.map((item) => {
      let n = _text.indexOf(item + ".");
      if (n > 0) {
        _text = _text.substring(0, n) + "\n\n" + _text.substring(n);
      }
    });
    return _text;
  };

  const call_API = async (promptQuery) => {
    if (loading) {
      return;
    }

    let params = {
      feedback_length,
      tags,
      factors: selected,
      date: "",
      //! NOTE: don't overwrite this, as it is an expected param for the API.
      context_data_length: 0,
    };

    setUpdatedMessage(false);
    ClearChatGpt();

    setLoading(true);

    if (get_web_sock.ws) {
      const msgId = uuidv4();
      dispatch({
        type: SAGA_MESSAGE_WEBSOCKET,
        payload: {
          name: selectedConversation?.name,
          web_chat_id: get_web_sock?.channel?.id,
          id: msgId,
          message: promptQuery,
        },
      });

      // Add to the pending messages.
      dispatch({
        type: ADD_PENDING_MESSAGE,
        payload: {
          id: msgId,
          web_chat_id: get_web_sock?.channel?.id,
        },
      });
    } else {
      const name = promptQuery
        .slice(0, 50)
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/[^a-zA-Z0-9_]/g, "");
      if (Object.keys(params).length) {
        dispatch({
          type: SAGA_START_AND_MESSAGE_WEBSOCKET,
          payload: {
            token: chatToken,
            // token,
            name,
            message: {
              id: uuidv4(),
              message: promptQuery,
            },
            // contextData: TEST_FEEDBACK,
            contextData: feedback,
            params,
            // params: {"topic":"random param"}
          },
        });
        setParams(params);
        setTopicCreated(Math.random());

        //Need to clear the selected filters + the selected culture factors
        //So that the conversation params are reset for the next conversation
        dispatch({
          type: SET_SELECTED_QUESTION,
          payload: { title: "Overall Culture", level: 0, id: 0 },
        });

        dispatch({
          type: SET_NEW_FILTERS,
          payload: [],
        });

        setTags([]);
      }
    }
  };

  useEffect(() => {
    if (!chatToken) {
      <Message color="red">
        "No chat token found (fix this message in src/reports/AI/index.js line
        581)"
      </Message>;
    }
  });
  // console.log("chatHistory", chatHistory)
  // console.log("chatHistory?.interaction_history", chatHistory?.interaction_history)
  // console.log("questionsHistory", questionHistory)

  useEffect(() => {
    let feed = "";
    if (selected.level == 4) {
      feed +=
        "The following feedback are responses to the question: " +
        selected.title +
        "\n\n";
    }

    feed += "### Feedback\n\n";
    let feedback_data = getFeedbackContext();

    if (selected.level === 1) {
      if (!isDEI) {
        feedback_data = feedback_data.filter(
          (f) =>
            f.feedback.id === selected.dimension ||
            f?.feedback?.dimension === selected.dimension
        );
      }
    }
    if (selected.level === 2 && !isDEI) {
      feedback_data = feedback_data.filter(
        (f) =>
          (f.feedback.id === selected.dimension ||
            f?.feedback?.dimension === selected.dimension) &&
          f.feedback.factor === selected.factor
      );
    }

    if (selected.level === 2 && isDEI) {
      feedback_data = feedback_data.filter(
        (f) => f.feedback.factor === selected.factor
      );
    }

    if (selected.level == 3) {
      feedback_data = feedback_data.filter(
        (f) =>
          f.type === "outcome" && Number(f.feedback.q) == Number(selected.id)
      );
    }

    if (selected.level == 4) {
      feedback_data = feedback_data.filter(
        (f) => f.type === "comments" && f.id == selected.id
      );
    }
    feedback_data.map((item) => {
      if (
        !Number.isInteger(item.feedback.response) &&
        item?.type !== "outcome"
      ) {
        return;
      }

      if ("prompt_feedback" in item.feedback) {
        feed += "Follow up question: " + item.feedback.prompt_question + "\n";
        // feed += 'User score:'+item.feedback.response+'\n'
        feed += "User feedback:" + item.feedback.prompt_feedback;
        return;
      }

      if ("feedback" in item.feedback) {
        // feed += 'User score:'+item.feedback.response+'\n'
        feed += "User feedback:" + item.feedback.feedback;
      }
      if (item.feedback.follow_up) {
        feed += "\nFollow up: " + item.feedback.follow_up;
      }
      if (item.feedback?.response && selected.type == "outcome") {
        feed += item.feedback.response;
      }
      feed += "\n\n";
    });
    setFeedback(feed + "### End of Feedback\n\n");
    setTimeout(() => {
      setFeedbackLoading(false);
    }, 1500);
    setFLength(feedback_data.length);
  }, [filtered_data, selected]);

  // Should happen on page load,
  // ensures previous socket is disconnected
  useEffect(() => {
    // DISCONNECT_WEBSOCKET
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
  }, []);

  const removeConversation = (convoId) => {
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });

    dispatch({
      type: SG_DELETE_CHAT_ROOM,
      payload: { id: deleteQueue },
    });

    dispatch({
      type: SG_GET_CHAT_ROOM,
    });
    setTopicCreated(Math.random());
    setShowDeleteConfirm(false);
    setChatHistory([]);

    setSelectedChatRoom(null);
    setNewChannel(null);
  };

  const startNewConversation = () => {
    setChatHistory([]);
    setQuestionHistory([]);
    setQuery("");

    setSelectedChatRoom(null);
    setSelectedConversation(null);
    setNewChannel(null);
    setLoading(false);
    setPendingChatId(null);
    // DISCONNECT_WEBSOCKET
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
  };

  const askRecommendation = (prompt) => {
    setQuery(prompt);
    call_API(prompt);
    setQuestionHistory((questionHistory) => [...questionHistory]);
    setPrompt("");
  };

  const deleteConvoModal = (
    <Modal
      onClose={() => setShowDeleteConfirm(false)}
      onOpen={() => setShowDeleteConfirm(true)}
      open={showDeleteConfirm}
      size="mini"
      closeOnDimmerClick
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Delete conversation?</Header>
          <p>Are you sure you want to delete this conversation?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ActionWrapper>
          <CancelText color="black" onClick={() => setShowDeleteConfirm(false)}>
            Cancel
          </CancelText>
          <DeleteConfirm onClick={() => removeConversation(deleteQueue)}>
            Delete
          </DeleteConfirm>
        </ActionWrapper>
      </Modal.Actions>
    </Modal>
  );

  const activeConversationModal = (
    <Modal
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      open={showModal}
      size="mini"
      closeOnDimmerClick
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Unable to switch conversations</Header>
          <p>Please wait until the current response completes</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ActionWrapper>
          <CancelText color="black" onClick={() => setShowModal(false)}>
            Close
          </CancelText>
        </ActionWrapper>
      </Modal.Actions>
    </Modal>
  );

  const getTags = () => {
    if (
      selectedChatRoom?.params?.tags?.length === 0 ||
      chatHistory?.params?.tags?.length === 0
    ) {
      return <Tag>Entire Organization</Tag>;
    }

    if (selectedChatRoom?.params?.tags?.length > 0) {
      return selectedChatRoom?.params?.tags?.map((item) => {
        return <Tag>{item}</Tag>;
      });
    }

    if (chatHistory?.params?.tags?.length > 0) {
      return chatHistory?.params?.tags?.map((item) => {
        return <Tag>{item}</Tag>;
      });
    }
  };

  return (
    <>
      {deleteConvoModal}
      {activeConversationModal}
      <Container simple={simpleDash}>
        <SideBarContainer>
          <HiddenComponent>
            <SurveyVersions />
          </HiddenComponent>
          <Section
            selectedChatRoom={questionHistory?.length > 0}
            simple={simpleDash}
          >
            {simpleDash && (
              <Back
                url={"/simple/prototype/simpledash"}
                location={"dashboard"}
              />
            )}
            <NewConversation onClick={() => startNewConversation()}>
              <AddIconWrapper>{addIcon()}</AddIconWrapper>
              New Conversation
            </NewConversation>
            <ChatHistory
              selectedChatRoom={selectedChatRoom}
              chatLoading={loading}
              setSelectedChatRoom={setSelectedChatRoom}
              topicCreated={topicCreated}
              setTopicCreated={setTopicCreated}
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
              setNewChannel={setNewChannel}
              setDeleteQueue={setDeleteQueue}
              setSelectedConversation={setSelectedConversation}
              loadingChatHistory={loadingChatHistory}
              hideInitial={hideInitial}
            />
          </Section>
          <PageDivider />
          <Chat ref={scrollRef}>
            {Object.keys(selectedChatRoom?.params || chatHistory?.params || {})
              .length > 0 && (
              <HeadingSection hide={!selectedChatRoom} simple={simpleDash}>
                <div>
                  <Label2>Chatting with the following data:</Label2>
                  <div style={{ display: "flex" }}>
                    <Tag>
                      {selectedChatRoom?.params?.factors?.title ||
                        chatHistory?.params?.factors?.title}
                    </Tag>
                    {getTags()}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    height: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Label3>
                    Date of data collected:{" "}
                    {selectedChatRoom?.params?.date ||
                      chatHistory?.params?.date}
                  </Label3>
                  <Label3>
                    # of data points included:{" "}
                    <strong>
                      {selectedChatRoom?.params?.feedback_length ||
                        chatHistory?.params?.feedback_length}
                    </strong>
                  </Label3>
                </div>
              </HeadingSection>
            )}
            {/* <HeadingSection hide={hideHeading}>
              <TopHeadingText>Culture Pillar</TopHeadingText>
              <ToolTipWrapper>
                <Popup
                  position="right center"
                  inverted
                  content="Select an area of your culture you’d like to know more about."
                  trigger={toolTip()}
                />
              </ToolTipWrapper>

              {(questionStructure && !feedbackLoading && (
                <>
                  <QuestionDrop
                    structure={questionStructure}
                    selected={question}
                    setSelected={setQuestion}
                  />
                  <TotalConversations>
                    # of feedback:{` `}
                    <NumConversations>{feedback_length}</NumConversations>
                  </TotalConversations>
                </>
              )) || <LoadingText>Loading</LoadingText>}
              <FilterSection>
                {get_survey_structure[0] && (
                  <NavBar2
                    structure={get_survey_structure[0]}
                    subfactor_access={subAccess}
                    employee_categories={categoryAccess}
                    isAdmin={get_employee.userEmp.account_type_text === "Admin"}
                  />
                )}
              </FilterSection>
              <VersionsSection>
                <SurveyVersions />
              </VersionsSection>
            </HeadingSection> */}

            <ChatWindow
              selectedChatRoom={selectedChatRoom}
              setSelectedChatRoom={setSelectedChatRoom}
              askRecommendation={askRecommendation}
              setTopicCreated={setTopicCreated}
              questionHistory={questionHistory}
              chatHistory={chatHistory}
              setNewChannel={setNewChannel}
              newChannel={newChannel}
              query={query}
              text={text}
              params={params}
              setLoading={setLoading}
              pendingChatId={pendingChatId}
              setPendingChatId={setPendingChatId}
              // setChatHistory={setQuestionHistory}
              // loading={loading}
              waitingText={waitingText}
              hide={hideHeading}
              context_length={
                selectedChatRoom?.params?.feedback_length ||
                chatHistory?.params?.feedback_length
              }
              outcomeQ={outcomeQ}
              feedback_length={feedback_length}
              raw_feedback={getFeedbackContext()}
            />

            <Bottom>
              <ChatInput>
                <Input
                  ref={inputRef}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  style={{ width: "100%", marginRight: 10 }}
                  placeholder="Ask a question about your culture."
                  onKeyDown={handleKeyDown}
                  rows="1"
                  disabled={feedback_length === 0}
                  disabledStyle={feedback_length === 0}
                />
                <SendButton onClick={() => handleKeyDown({ key: "Enter" })}>
                  <Icon name={"send"} />
                </SendButton>
              </ChatInput>
            </Bottom>
          </Chat>
        </SideBarContainer>
      </Container>
    </>
  );
};

export default AI;

const HeadingSection = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 550px);
  max-width: 1400px;
  padding-right: 30px;
  height: 50px;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: ${(props) => (props.simple ? "85px" : "50px")};
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  transition: transform 0.3s ease;
  transform: translateY(${(props) => (props.hide ? "-100%" : "0")});

  margin-left: -15px;
`;

const LoadingText = styled.div`
  font-style: normal;
  font-size: 16px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const DeleteIconWrapper = styled.div`
  margin-left: 6px;
  align-self: center;
  margin-top: 2px;
`;

const ToolTipWrapper = styled.div`
  display: flex;
  vertical-align: middle;
`;

const VersionsSection = styled.div`
  display: flex;
  padding-left: 20px;
`;

const TopHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TotalConversations = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #666d79;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const CancelText = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #666d79;
  cursor: pointer;
`;

const DeleteConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;

const ConversationSnippet = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Raleway;
  font-size: 16px;
  cursor: pointer;
  background: ${({ isHovered }) => (isHovered ? "#F8FAFF" : "white")};
  color: ${({ isHovered }) => (isHovered ? "#2d70e2" : "#2A3039")};
  font-weight: ${({ isHovered }) => (isHovered ? "600" : "400")};
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 8px;
  margin-left: -15px;
  margin-right: 30px;
  max-height: 48px;
`;

const DaySection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const Conversations = styled.div`
  flex-direction: column;
  padding-top: 23.5px;
  padding-left: 50px;
`;

const DaysAgo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 22px;
  color: #666d79;
`;

const EmptyText = styled.div`
  padding-right: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-bottom: 22px;
  color: #666d79;
`;

const PageDivider = styled.div`
  width: 1px;
  height: 100%;
`;

const AddIconWrapper = styled.div`
  margin-right: 10px;
`;

const NumConversations = styled.span`
  font-weight: 600;
  color: #2d70e2;
`;

const NewConversation = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 0px;
  cursor: pointer;
  color: #2d70e2;
`;

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  font-family: "Raleway";
  width: 100%;
  margin-top: ${(props) => (props.simple ? "10px" : "10px")};

  @media screen and (min-width: 1260px) {
    margin-left: -55px;
  }
  @media screen and (max-width: 1480px) {
    margin-left: 5px;
  }

  @media screen and (min-width: 1630px) {
    margin-left: -155px;
  }

  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
`;

const SideBarContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 50px;
  padding-left: 52px;
`;

const TopHeadingText = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

const Chat = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  max-width: 1500px;

  position: relative;
  margin-left: 270px;
`;

const Top = styled.div`
  width: 100%;
  padding: 10px;
  font-family: "Poppins";
`;

// const ChatWindow = styled.div`
//   height: 67vh;
//   overflow-y: auto;
//   overflow-x: hidden;
//   margin-top: 160px;
//   &::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
//     background-color: rgba(0, 0, 0, 0);
//     margin-top: 10px;
//     margin-bottom: 35px;
//   }

//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: rgba(0, 0, 0, 0);
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.03);
//   }

//   width: 58vw;

//   @media screen and (min-width: 1260px) {
//     min-width: 900px;
//   }
// `;

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
`;

const Summary = styled.div`
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Text = styled.div`
  color: grey;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 10px;

  width: 120px;
  height: 30px;

  /* Primary/500 */

  background: #2d70e2;
  border-radius: 8px;
  color: white;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 40px;
`;

const Section = styled.div`
  width: 250px;
  flex-direction: column;
  display: flex;
  position: fixed;
  padding-top: ${(props) => (props.simple ? "20px" : "0px")};
`;

const Section2 = styled.div`
  width: calc(100% - 470px);
`;

const ChatHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;

  max-width: 970px;
  width: calc(100% - 510px);
  background-color: white;
  padding-bottom: 40px;
`;

const ChatInput = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  padding: 16px;
  width: calc(100% - 50px);
`;

const Input = styled.textarea`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  max-height: 300px; /* Height for 4 lines */
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;

  font-family: Raleway;
  font-size: 14px;

  opacity: ${({ disabledStyle }) => (disabledStyle ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  outline: none;
  padding: 5px;
  margin-left: 5px;
`;



const HeadingPosition = styled.div`
  position: fixed;
`;

const ConversationSection = styled.div`
  margin-top: 160px;
`;

const Tag = styled.div`
  background: #EBF1FD;
  border-radius: 8px;
  color: #2D70E2;
  padding: 0px 6px;
  font-size: 10px;
  font-weight: 400;
  margin-right: 10px;
  text-overflow: ellipsis;
  max-width:400px;
  overflow: hidden;
  white-space: nowrap;
`;

const Label2 = styled.div`
  font-size:10px;
  color: #666d79;
  margin-bottom:2px;
`

const Label3 = styled.div`
  font-size:10px;
  color: #666d79;
  margin-bottom:-3px;
`

const HiddenComponent = styled.div`
  display: none;
`;