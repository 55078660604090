import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import IMG from 'assets/images/UI/Chat2.svg'
import IMG2 from 'assets/images/UI/List.svg'
import IMG3 from 'assets/images/UI/Communication2.svg'

const ReportType = ({ title, description, id, next, pageLength }) => {
  return (
    <Container>
      <Icon src={id == 1 ? IMG : id == 2 ? IMG2 : IMG3} />
      <Title>{title}</Title>
      <Length>Report length: {pageLength} pages</Length>
      <Description>{description}</Description>

      <ButtonContainer>
        <Button onClick={() => next()}>Select</Button>
      </ButtonContainer>
    </Container>
  );
};


export default ReportType

const Container = styled.div`
    width:350px;
    border:1px solid #E0E0E0;
    border-radius:10px;
    padding:20px;
    margin-bottom:20px;
    margin-right:10px;
    font-family:'Raleway';
`


const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 5px;
  letter-spacing: -0.55px;
  line-height: 1.3;
  margin-top: 10px;
`;

const Description = styled.div`
    line-height:1.5;
    margin-bottom:20px;
    height:100px;
`

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center

`

const Button = styled.div`
    color:#2D70E2;
    border:1px solid #2D70E2;
    font-size:14px;
    padding:5px 10px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`

const Tags = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
`

const Length = styled.div`
    font-size:12px;
    font-weight:400;
    color:#B3B3B3;
    margin-bottom:10px;
    margin-top:0px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`

const Icon = styled.img`
    height:40px;
    margin-bottom:0px;
`