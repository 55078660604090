import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, dispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import List from "./List";
import { Dimmer, Loader, Checkbox, Modal } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";

const NewTemplate = ({
  add,
  templates,
  edit,
  setUpdateModal,
  setDeleteId,
  copy,
  selectTab,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateAssignee, setTemplateAssignee] = useState("");
  const [templateStatus, setTemplateStatus] = useState("New");
  const [pageMode, setPageMode] = useState("view");
  const [templateQuestions, setTemplateQuestions] = useState([]);
  const [stage, setStage] = useState(0);
  const [newQuestion, setNewQuestion] = useState({});
  const [showNewQuestionBox, setShowNewQuestionBox] = useState(false);
  const [editTemplate, setEditTemplate] = useState(null);
  const [feedbackNotes, setFeedbackNotes] = useState(false);
  const messagesEndRef = useRef(null);
  const [newQuestionId, setNewQuestionId] = React.useState(null);
  const navigate = useNavigate();
  const { get_auth, get_employees } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  const handleAdd = () => {
    if (
      templateName === "" ||
      templateDescription === "" ||
      templateQuestions.length === 0
    ) {
      return;
    }
    add({
      name: templateName,
      description: templateDescription,
      questions: templateQuestions,
      created_by: `${get_auth?.employee?.first_name} ${get_auth?.employee?.last_name}`,
      updated_at: new Date().toISOString(),
      status: templateStatus,
      assignees: [],
      feedbackNotes: feedbackNotes,
    });

    setTemplateName("");
    setTemplateDescription("");
    setTemplateAssignee("");
    setStage(0);
    setTemplateQuestions([]);
    setPageMode("view");
  };

  const handleAddTemplateQuestion = (question, aiEnhanced) => {
    setTemplateQuestions([
      ...templateQuestions,
      {
        id: uuidv4(),
        question,
        aiEnhanced,
      },
    ]);

    // Scroll to bottom when question is added
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleRemoveTemplateQuestion = (id) => {
    setTemplateQuestions(
      templateQuestions.filter((question) => question.id !== id)
    );
  };

  const handleUpdateQuestion = (id, question, aiEnhanced) => {
    setTemplateQuestions(
      templateQuestions.map((q) => {
        if (q.id === id) {
          return {
            ...q,
            question,
            aiEnhanced,
          };
        }
        return q;
      })
    );
  };

  const handleAddTemplateQ = () => {
    const qId = uuidv4();
    const newQuestion = { id: qId, question: "", aiEnhanced: false };
    setTemplateQuestions([...templateQuestions, newQuestion]);
    setNewQuestionId(qId);
  };

  const handleRemoveTemplateQ = (id) => {
    setTemplateQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== id)
    );
    if (id === newQuestionId) setNewQuestionId(null);
  };

  const TemplateInfo = (
    <InfoSection>
      <Option>
        <Label>Template Name</Label>
        <Input
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </Option>
      <Option>
        <Label>Template Purpose</Label>
        <Input
          value={templateDescription}
          onChange={(e) => setTemplateDescription(e.target.value)}
        />
      </Option>
    </InfoSection>
  );

  const TemplateQuestions = (
    <QuestionSection>
      {templateQuestions.length === 0 ? (
        <NoQuestionsMessage>
          No questions have been added yet.
        </NoQuestionsMessage>
      ) : (
        templateQuestions
          .filter((q) => q.id !== newQuestionId)
          .map((question) => (
            <Question added key={question.id}>
              <QuestionContainer>
                <Label>Question</Label>
                <QuestionText
                  onChange={(e) =>
                    handleUpdateQuestion(
                      question.id,
                      e.target.value,
                      question.aiEnhanced
                    )
                  }
                  value={question.question}
                />
                <Label>AI Enhanced</Label>
                <Checkbox
                  checked={question.aiEnhanced}
                  toggle
                  onChange={(e) =>
                    handleUpdateQuestion(
                      question.id,
                      question.question,
                      e.target.checked
                    )
                  }
                />
              </QuestionContainer>
              <Remove onClick={() => handleRemoveTemplateQ(question.id)}>
                Remove
              </Remove>
            </Question>
          ))
      )}
      {newQuestionId && (
        <Question>
          <QuestionContainer>
            <NewLabel>New Question</NewLabel>
            <QuestionText
              onChange={(e) =>
                handleUpdateQuestion(newQuestionId, e.target.value, false)
              }
              value={
                templateQuestions.find((q) => q.id === newQuestionId)?.question
              }
            />
            <ActionSection>
              <ToggleWrapper>
                <Label>AI Enhanced</Label>
                <Checkbox
                  checked={
                    templateQuestions.find((q) => q.id === newQuestionId)
                      ?.aiEnhanced
                  }
                  toggle
                  onChange={(e) =>
                    handleUpdateQuestion(
                      newQuestionId,
                      templateQuestions.find((q) => q.id === newQuestionId)
                        ?.question,
                      e.target.checked
                    )
                  }
                />
              </ToggleWrapper>
            </ActionSection>
          </QuestionContainer>
        </Question>
      )}
      <NewQuestionButton onClick={handleAddTemplateQ}>
        + New Question
      </NewQuestionButton>
    </QuestionSection>
  );

  const renderStage = (stage) => {
    switch (stage) {
      case 0:
        return TemplateInfo;
      case 1:
        return TemplateQuestions;
      default:
        return TemplateInfo;
    }
  };

  return (
    <Container>
      {/* Create a sub title to show the name of the template if stage is 1 */}

      {/* Add create button position to far right */}

      {stage === 1 && (
        <StageOneInfo>
          <TemplateTitle>{templateName}</TemplateTitle>
          <TemplatePurpose>{templateDescription}</TemplatePurpose>

          <ToggleWrapper>
            <Label>Feedback Tips</Label>
            <Checkbox
              checked={feedbackNotes}
              toggle
              onChange={(e) => setFeedbackNotes(!!feedbackNotes ? false : true)}
            />
          </ToggleWrapper>
        </StageOneInfo>
      )}
      {pageMode === "view" ? (
        <ViewContainer>
          <Description>
            Below are the custom surveys that have been created by you.
          </Description>
          <List
            templates={templates}
            setUpdateModal={setUpdateModal}
            edit={edit}
            setDeleteId={setDeleteId}
            copy={copy}
            setTab={selectTab}
            setEditTemplate={setEditTemplate}
            setPageMode={setPageMode}
          />

          <CreateButton
            onClick={() =>
              navigate(`/leader/audit/manage/tasktemplates/create`)
            }
          >
            Create
          </CreateButton>
        </ViewContainer>
      ) : (
        <>
          {renderStage(stage)}
          <ButtonWrapper>
            <Button
              onClick={() => {
                if (stage === 0) {
                  setPageMode("view");
                } else {
                  setStage(stage - 1);
                }
              }}
            >
              Back
            </Button>

            {stage > 0 && (
              <Button
                disabled={
                  templateDescription?.length === 0 ||
                  templateName?.length === 0 ||
                  templateQuestions?.length === 0
                }
                style={{
                  opacity:
                    templateDescription?.length === 0 ||
                    templateName?.length === 0 ||
                    templateQuestions?.length === 0
                      ? 0.5
                      : 1,
                  cursor:
                    templateDescription?.length === 0 ||
                    templateName?.length === 0 ||
                    templateQuestions?.length === 0
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={handleAdd}
              >
                Create Custom Survey
              </Button>
            )}
            {stage < 1 && (
              <Button
                disabled={
                  templateDescription?.length === 0 ||
                  templateName?.length === 0
                }
                style={{
                  opacity:
                    templateDescription?.length === 0 ||
                    templateName?.length === 0
                      ? 0.5
                      : 1,
                  cursor:
                    templateDescription?.length === 0 ||
                    templateName?.length === 0
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    templateDescription?.length === 0 ||
                    templateName?.length === 0
                  )
                    return;
                  setStage(stage + 1);
                }}
              >
                Next
              </Button>
            )}
          </ButtonWrapper>
        </>
      )}
      <div style={{ marginBottom: 20 }} />
      {/* Scroll to bottom when question is added */}
      <div ref={messagesEndRef} />
    </Container>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #2d70e2;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #2d70e2;
`;

const CreateButton = styled.button`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  position:absolute;
  right: 0;
`;

const NewQuestionButton = styled(Button)`
  margin-top: 20px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  font-family: "Raleway";
  margin-bottom: 40px;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 20px;
`;

const QuestionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
`;

const Remove = styled.div`
  color: red;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Raleway";
  opacity: 0;
  width: 10%;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NoQuestionsMessage = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2a3039;
  font-family: "Raleway";
  margin-bottom: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Question = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  font-family: "Raleway";
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  margin-top: 10px;
  position: relative;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ added }) => (added ? "#e6f7ff" : "#fff")};
`;

const QuestionText = styled.input`
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-family: "Raleway";
  margin-bottom: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  width: 90%;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const AddQuestion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #2d70e2;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #2d70e2;
  font-family: "Raleway";
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ActionSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3px;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #2a3039;
  font-family: "Raleway";
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const NewLabel = styled(Label)`
  color: #2d70e2;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-family: "Raleway";
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const StageOneInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  font-family: "Raleway";
`;

const TemplateTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 5px;
  letter-spacing: -0.55px;
  line-height: 1.3;
  margin-top: 10px;
`;

const TemplatePurpose = styled.div`
  line-height: 1.5;
`;


export default NewTemplate;
