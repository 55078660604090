import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginBottom: 30,
    position: "relative",
    marginTop: 20,
  },
  bottom: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid black",
  },
  title: {
    width: "35%",
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    paddingLeft: 5,
    fontSize: 10,
    borderRight: "1px solid #000921",
    paddingTop: 2,
    paddingBottom: 2,
  },
  averageTitle: {
    width: "35%",
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    paddingLeft: 5,
    fontSize: 10,
    borderRight: "1px solid #000921",
    paddingTop: 2,
    paddingBottom: 2,
    color: "#031A4A",
  },
  bar: {
    height: 8,
    opacity: 0.9,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingTop: 2,
    paddingBottom: 2,
  },
  score: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 4,
    position: "absolute",
    right: -20,
    top: -2,
    opacity: 1,
  },
  lines: {
    position: "absolute",
    left: "20%",
    height: "100%",
    width: "calc(80% + 20px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
  },
  line: {
    height: "calc(100% + 10px)",
    width: 1,
    borderLeft: "0.5px dashed grey",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chart: {
    position: "relative",
  },
  text: {
    position: "absolute",
    top: -35,
    left: -25,
    fontSize: 7,
    color: "grey",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 50,
    textAlign: "center",
  },
  norm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "20%",
  },
  subTitle: {
    fontWeight: "bold",
    color: "#031A4A",
  },
  top: {
    width: "100%",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    backgroundColor: "#031A4A",
    display: "flex",
    flexDirection: "row",
    color:'white'
  },
  numbers: {
    width: "20%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    borderRight: "1px solid #000921",
    fontSize: 10,
  },
  spacer: {
    width: "54%",
  },
  order: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
  },
  topNum: {
    width: "9.09%",
    fontSize: 10,
  },
});

const OTPChart = (props) => {
  const { data, categories, isFrench } = props;
  const lang = isFrench ? "fr" : "en";
  const getSecondaryLabel = (id) => {
    const secondaryItems = categories.categories.find(
      (c) => c.priority === "secondary"
    );
    const label = secondaryItems?.options?.find((o) => o.id === id);

    return label;
  };

  return (
    <View style={styles.page} wrap={false}>
      <View style={styles.section}>
        <Text style={styles.subTitle}>{data.factor}</Text>
      </View>
      <View style={styles.top}>
        <View style={styles.spacer} />
        <View style={styles.order}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
            return <Text style={styles.topNum}>{item}</Text>;
          })}
        </View>
      </View>

      <View style={styles.chart}>
        <View style={[styles.line, { left: "58.59%" }]} />
        <View style={[styles.line, { left: "62.68%" }]} />
        <View style={[styles.line, { left: "66.77%" }]} />
        <View style={[styles.line, { left: "70.86%" }]} />
        <View style={[styles.line, { left: "74.95%" }]} />
        <View style={[styles.line, { left: "79.04%" }]} />
        <View style={[styles.line, { left: "83.13%" }]} />
        <View style={[styles.line, { left: "87.22%" }]} />
        <View style={[styles.line, { left: "91.31%" }]} />
        <View style={[styles.line, { left: "95.4%" }]} />
        <View style={styles.row}>
          <Text style={styles.averageTitle}>
            {getTranslatedText("average", lang, translations)}
          </Text>
          <Text style={styles.numbers}> {data.factorAverage.toFixed(1)} </Text>
          <View
            style={[
              styles.bar,
              {
                width: 40.9 * ((data.factorAverage * 10) / 100) + "%",
                backgroundColor: "#d9d9d9",
              },
            ]}
          ></View>
        </View>
        {data.factorResponses
          ?.sort((a, b) => b.average - a.average)
          .map((item) => {
            if (!item.average) {
              return null;
            }

            const label = getSecondaryLabel(item.id);

            return (
              <View style={styles.row}>
                <Text style={styles.title}>
                  {isFrench ? label?.fr : label?.name}
                </Text>
                <Text style={[styles.numbers]}>{item.average.toFixed(1)}</Text>
                <View
                  style={[
                    styles.bar,
                    {
                      width: 40.9 * ((item.average * 10) / 100) + "%",
                      backgroundColor: label?.color,
                    },
                  ]}
                ></View>
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default OTPChart;
