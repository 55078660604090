import styled from "styled-components";
import React, { useState, useEffect } from "react";

import {
    Button,
    Header,
    Confirm,
    Icon,
    Modal,
    Table
  } from "semantic-ui-react";

  import Dropdown from './BasicDropdown';
import { get } from "http";



  const ComplianceReport = ({open,setOpen,data,get_survey_structure}) => {
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [completionData, setCompletionData] = useState([])


    useEffect(() => {
        if (get_survey_structure) {
          setOptions(get_survey_structure[0].categories.filter(f=>f.visibility == "public" && !f?.demographic && !f?.hidden));
        }

        }, [get_survey_structure]);


    useEffect(()=>{
        let completion = []
        selected?.options.map((option) => {
            const req = data.filter(f=>f.categories.find(c=>c.name_id==selected.id)?.value_id == option.id)
            const total = req.length
            const completed = req.filter(f=>f.debrief_response==1).length
            console.log(req)
            if(total >0){
                completion.push({
                    name:option.name,
                    total,
                    completed
                })
            }
        }
        )
        setCompletionData(completion)

        
    },[selected,data])

    const getCompletion = (a,b) => {
        return (b.completed/b.total) - (a.completed/a.total)
    }


    return (
       <Modal
        open={open}
        onClose={() => setOpen(false)}
       >
        <Container>
            <Title>Compliance Report</Title>
        <Dropdown items={options} onSelect={setSelected} />

        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Completion</Table.HeaderCell>
                    <Table.HeaderCell>Percentage</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {completionData.sort((a,b)=>getCompletion(a,b)).map((item) => {
                    return (
                        <Table.Row>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.completed}/{item.total}</Table.Cell>
                            <Table.Cell>{Math.round((item.completed/item.total)*100)}%</Table.Cell>
                        </Table.Row>
                    )
                })
                }
            </Table.Body>

        </Table>

        </Container>


      



        </Modal>
    )

  }


export default ComplianceReport;

const Container = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family:'Ralway', sans-serif;
`

const Title = styled.div`
  font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
`

const Row = styled.div`
  display:flex;
  width:100%;
  margin-top:20px;
`

const Name = styled.div`
  width:300px;
  font-weight:500;
  font-size:16px;
`

const Value = styled.div`
  font-size:16px;

`