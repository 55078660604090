import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  SG_GET_DATA_STORY_LOGS,
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_GET_DATA_STORIES,
  LOAD_EMPLOYEES,
} from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ActivityTable from "./ActivityTable";

const Activity = ({ id }) => {
  const dispatch = useDispatch();
  const [usersLogs, setUsersLogs] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [employeeObj, setEmployeeObj] = useState();
  const {
    story_logs,
    get_employee_permission,
    get_employees,
    get_chat_gpt_recommendations,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
      pending_stories: state.schedule_reports?.pending_schedule_reports,
      story_logs: state.story_logging,
      get_employees: state.employees,
    }),
    shallowEqual
  );

// const Activity = () => {
//   return (
//     <Container>
//       {activities.map((activity, index) => {
//         return (
//           <ActivityItem key={index}>
//             <Icon
//               name={activity.icon}
//               style={{ fontSize: 18, color: "#B3B3B3" }}
//             />
//             <Text>{activity.text}</Text>
//             <Date>{activity.date}</Date>
//             {index + 1 != activities.length ? <Line /> : ""}
//           </ActivityItem>
//         );
//       })}
//     </Container>
//   );
// };
  
  useEffect(() => {
    if (get_employees?.employees?.length > 0) {
      setEmployeeObj(
        get_employees?.employees?.find((employee) => employee.id === Number(id))
      );
    }
  }, [get_employees]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DATA_STORY_LOGS,
      payload: `employee=${id}`,
    });

    dispatch({
      type: SG_GET_EMPLOYEE_PERMISSIONS,
      payload: `employee=${id}`,
    });

    dispatch({
      type: SG_GET_DATA_STORIES,
      payload: `employee=${id}`,
    });

    dispatch({
      type: LOAD_EMPLOYEES,
    });
  }, [dispatch]);

  useEffect(() => {
    if (story_logs?.data_story_logs?.results?.length > 0) {
      // Filter by employee id
      const logs = story_logs?.data_story_logs?.results?.filter(
        (log) => log.employee_id === Number(id)
      );

      setUsersLogs(logs);
    }
  }, [story_logs]);

  useEffect(() => {
    if (get_employee_permission?.employee_permission?.length > 0) {
      setUserPermissions(
        get_employee_permission?.employee_permission?.find(
          (permission) => permission.employee === Number(id)
        )
      );
    }
  }, [get_employee_permission]);

  return (
    <Container>
      {usersLogs && (
        <ActivityTable
          data={usersLogs}
          employee={employeeObj}
          userData={userPermissions}
          loading={false}
          pendingStories={get_chat_gpt_recommendations?.data_stories?.results}
        />
      )}
    </Container>
  );
};

export default Activity;

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-size: 20px;
  font-family: "Raleway";
  position: relative;
`;

const ActivityInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const AdditionalInfo = styled.div`
  font-size: 14px;
  color: #ccc;
  margin-top: 5px;
  font-weight: 600;
  font-family: "Raleway";
`;


const Text = styled.div`
  margin-left: 20px;
`;

const DateStyle = styled.div`
  margin-left: 20px;
  color: #ccc;
  font-size: 14px;
`;

const Line = styled.div`
    height:40px;
    width:1px;
    background-color:#ccc;
    position:absolute;
    left:10px;
    bottom:-46px;
`

const Title = styled.div`
    font-size:18px;
    font-weight:600;
    font-family:'Raleway';
    margin-bottom:40px;
`