import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import DataTypeDropdown from "./FeedbackTypeDropdown";
import { useSelector, shallowEqual } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import { Icon } from "semantic-ui-react";
import Filters from "./Filters";

import Chart from "react-apexcharts";
import { set } from "date-fns";

const FeedbackTag = ({
  data,
  setFilter,
  setOrientation,
  outcomeQ,
  selectedSentiment,
  copilot,
  selectedFilter,
}) => {
  const [series, setSeries] = useState(option.series);
  const [options, setOptions] = useState(option.options);
  const [frequencyData, setFrequencyData] = useState({});

  const [loading, setLoading] = useState(false);

  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoading(true);
    const frequency = {};
    data?.forEach((item) => {
      const type = item?.nlp?.sentiment;
      const action_oriented = item?.nlp?.action_orientation;

      if (type) {
        if (frequency[type]) {
          frequency[type]++;
        } else {
          frequency[type] = 1;
        }
      }

      if (action_oriented) {
        if (frequency[action_oriented]) {
          frequency[action_oriented]++;
        } else {
          frequency[action_oriented] = 1;
        }
      }
    });

    setFrequencyData(frequency);
    const sentiment_order = [
      "Very Negative",
      "Slightly Negative",
      "Neutral",
      "Slightly Positive",
      "Very Positive",
    ];
    let _series = sentiment_order.map((sentiment) => {
      return { name: sentiment, data: [frequency[sentiment] || 0] };
    });

    let _options = options;
    _options.chart.events = {
      dataPointSelection: (event, chartContext, config) => {
        setFilter(sentiment_order[config.seriesIndex]);
      },
    };

    setOptions(_options);
    setSeries(_series);

    const orientation_order = ["action_oriented", "not_action_oriented"];
    let _series2 = orientation_order.map((sentiment) => {
      return { name: sentiment, data: [frequency[sentiment] || 0] };
    });

    setTimeout(() => {
      setLoading(false);
    }, [300]);
  }, [data]);

  const getFeedbackPercentage = () => {
    let total = 0;
    let action = 0;
    data?.forEach((item) => {
      const action_oriented = item?.nlp?.action_orientation;
      if (action_oriented === "action_oriented") {
        action += 1;
      }
      total += 1;
    });

    if (total === 0) {
      return 0;
    }

    return (action / total) * 100;
  };

  return (
    <Container>
      <Title>Feedback Sentiment</Title>
      <Description>
        A summary of the sentiment of each comment from the survey. This
        feedback was labeled using a machine learning algorithm trained on
        culture feedback.
      </Description>
      {selectedFilter && <Clear onClick={() => setFilter(false)}>Reset</Clear>}
      {!loading && (
        <ChartContainer><Chart
        options={options}
        series={series}
        type="bar"
        width={800}
        height={150}
      /></ChartContainer>
        
      )}
      {loading && <LoadingBox />}

      <Action>
        {" "}
        <strong> {getFeedbackPercentage().toFixed(0)}%</strong> of this feedback
        was tagged as actionable.
      </Action>

      <DropdownSection>
        <DataTypeDropdown outcomes={outcomeQ} core_data={core_data} />
        <Dropdown
          label="Filter by sentiment"
          selectedOption={selectedSentiment}
          options={[
            {
              key: "Very Negative",
              name: "Very Negative",
              value: "Very Negative",
            },
            {
              key: "Slightly Negative",
              name: "Slightly Negative",
              value: "Slightly Negative",
            },
            {
              key: "Neutral",
              name: "Neutral",
              value: "Neutral",
            },
            {
              key: "Slightly Positive",
              name: "Slightly Positive",
              value: "Slightly Positive",
            },
            {
              key: "Very Positive",
              name: "Very Positive",
              value: "Very Positive",
            },
          ]}
          setSelected={(o) => setFilter(o)}
        />
        <Dropdown
          label="Filter by action orientation"
          options={[
            {
              key: "Action Oriented",
              name: "Action Oriented",
              value: "action_oriented",
            },
            {
              key: "Not Action Oriented",
              name: "Not Action Oriented",
              value: "not_action_oriented",
            },
          ]}
          setSelected={(o) => setOrientation(o)}
        />
      </DropdownSection>
      <BottomRow>
        <Filters />
        {copilot}
      </BottomRow>
    </Container>
  );
};

export default FeedbackTag;

const Dropdown = ({ options, setSelected, label, selectedOption }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [selected, setSelectedOption] = useState(null);

  const selectOption = (option) => {
    setOpen(false);
    setSelected(option?.value);
    setSelectedOption(option);
  };

  const clearSelection = () => {
    setSelected(null);
    setSelectedOption(null);
    setTimeout(() => {
      setOpen(false);
    }, 1);
  };

  useEffect(() => {
    if (selectedOption) {
      setSelectedOption(
        options.find((option) => option.value === selectedOption)
      );
    } else {
      setSelectedOption(null);
    }
  }, [selectedOption]);

  return (
    <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DropdownContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Nav onClick={() => setOpen(!open)}>
            <Selection>{selected?.name || "Select an option"}</Selection>
            <Icon
              name="triangle down"
              style={{ marginTop: -4, color: "#B3B3B3" }}
            />
            {selected && (
              <ClearButton onClick={clearSelection}>
                <Icon name="times" style={{ color: "#2D70E2" }} />
              </ClearButton>
            )}
          </Nav>
          {open && (
            <DropdownContent>
              {options.map((option, i) => (
                <Option key={i} onClick={() => selectOption(option)}>
                  {option.name}
                </Option>
              ))}
            </DropdownContent>
          )}
        </DropdownContainer>
      </ClickAwayListener>
    </Box>
  );
};

const LoadingBox = styled.div`
  height: 150px;
  width: 800px;
  margin-bottom:15px;
  background-color: #f0f0f0; /* Grey background */
  animation: shimmer 1.5s infinite linear; /* Shimmer animation */
  opacity:0.2;

  @keyframes shimmer {
    0% {
      background-color: #555; /* Darker grey */
    }
    50% {
      background-color: #999; /* Lighter grey */
    }
    100% {
      background-color: #555; /* Back to darker grey */
    }
  }
`;

const ChartContainer = styled.div`
  height:150px;
  margin-bottom:15px;
  width:100%;
`

const DropdownSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Container = styled.div`
  margin-bottom: 20px;
  position:relative;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;
const Description = styled.div``;

const Clear = styled.div`
  font-size: 12px;
  color: #2d70e2;
  cursor: pointer;
  position:absolute;
  top:100px;
  z-index:99;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const DropdownContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  padding: 5px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Selection = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 5px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: 200px;
`;

const Option = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: #2a3039;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  &:focus {
    outline: none;
  }
`;

const Action = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

const Label = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-right: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 20px;
`;

const option ={
          
    series: [{
      name: 'Very Negative',
      data: [3]
    }, {
      name: 'Slightly Negative',
      data: [25]
    }, {
      name: 'Neutral',
      data: [37]
    }, {
      name: 'Slightly Positive',
      data: [13]
    }, {
      name: 'Very Positive',
      data: [22]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 150,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
         
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: ['Feedback sentiment'],
       labels: {
          show: false
       },
       axisBorder: {
        show: false // Disable the x-axis border
      },
      axisTicks: {
        show: false // Disable the x-axis ticks
      }
      },
      colors:['#de2121','#de2121','#f7b531','#33b054','#33b054'],
      yaxis:{
        labels:{
            show:false
        },
        axisBorder: {
            show: false // Disable the x-axis border
          },
          axisTicks: {
            show: false // Disable the x-axis ticks
          }
      },
      grid: {
        show: false // Disable the grid
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " comments"
          }
        }
      },
      fill: {
        opacity: 1
      
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY:30
      }
    },
  
  
  };