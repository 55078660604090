import React, { useState,useEffect } from 'react';
import Chart from "react-apexcharts";
import styled, { keyframes } from "styled-components";
import FeedbackTable from "./FeedbackTable";
import BenchmarkChart from "./components/BenchmarkChart";
import TrendChart from "./components/TrendChart";
import Timeline from "./components/Timeline";
import ROITable from "./ROITable";
import {calculate_average_outcome} from "../calculations";
import Likelihood from './Likelihood';
import EffectPlot from './EffectPlot';
import Predictions from './Predictions';
import {Dropdown} from "semantic-ui-react";
import demo from './demo.json'
import { set } from 'date-fns';


const math = require('mathjs');

const Model = ({data,core_data,selected,responses,outcomeStandards}) =>{
   const [min,setMin] = useState(0)
    const [max,setMax] = useState(0)
    const [modifiedData,setModifiedData] = useState()


    const [relativeImportance,setRelativeImportance] = useState([])
    const [series,setSeries] = useState(data3.series)
    const [options,setOptions] = useState(data3.options)
    const [loading,setLoading] = useState(true)
    const [feedback,setFeedback] = useState([])
    const [outcomeScore,setOutcomeScore] = useState(0)
    const [selectedNav, setSelectedNav] = useState(0)
    const [selectedModel, setSelectedModel] = useState('Culture')


    useEffect(()=>{
        setModifiedData(data)
    },[data])

    useEffect(()=>{
        setLoading(true)
        if(data && selectedModel=='EQ'){
            let _data = JSON.parse(JSON.stringify(data))
            _data.score = demo
            setModifiedData(_data)
            setRelativeImportance(calculate_relative_importance(_data))
        }
        
        if(data && selectedModel=='Culture'){
            setModifiedData(data)
            setRelativeImportance(calculate_relative_importance(data))
            
        }

        setTimeout(()=>{
            setLoading(false)
        },300)

    },[selectedModel,data])


    const get_all_feedback = () =>{
        if(relativeImportance.length> 0 && max>0){
            let feedback = []
            relativeImportance.slice(0,2).map(item=>{
                let importance = (item.value-min)/(max-min)*100

                    feedback.push(
                        {data:core_data.feedback?.filter(f=>f?.dimension == item.index?.dimension && f?.factor == item.index.factor),
                            name:item.name,
                        })
            })
            feedback.push({data:core_data.feedback?.filter(f=>f.type=='Outcome' && f.outcome == parseInt(data.outcome.id)),
                name:data.outcome.name
            })

            return feedback
        }

        return []
    }

    
    const calculate_relative_importance = (newData) =>{
        let _data = []
         Object.keys(newData.score.model).map((item,index)=>{
            let _item = newData.score.model[item]
            if(_item.importance > 0){
                _data.push( {
                    name:item,
                    index:_item.index,
                    value:Math.abs(_item.coefficient)
                })
            }
            
        })

        _data.sort((a,b)=>b.value-a.value)

        return _data.slice(0,6)

    }


    useEffect(()=>{
        if(relativeImportance.length> 0){
        setMin(math.min(relativeImportance.map(item=>item.value)))
        setMax(math.max(relativeImportance.map(item=>item.value)))
        }
    }
    ,[relativeImportance])

    useEffect(()=>{
        if(relativeImportance.length> 0){
            console.log(relativeImportance)
            setLoading(true)
            let _options = {...options}
            setSeries(relativeImportance.map(item=>item.value))
            _options.labels = relativeImportance.map(item=>item.name)
            const total = relativeImportance.reduce((sum, item) => sum + item.value, 0);
            _options.tooltip= {
                y: {
                    formatter: (val, { series, seriesIndex }) => {
                        return `explains ${(val/total*100).toFixed(1)}% of the total predictive power of culture.`; // Displaying percentage and absolute value
                      }
                }
              }

            _options.colors = [
                '#27CDA7',  // Fully opaque red
                '#2D70E2',  // Semi-transparent blue
                'rgba(252, 220, 224, 0.6)',  // More transparent yellow
                'rgba(255, 237, 207, 0.6)',
                'rgba(213, 226, 249, 0.6)',  // Semi-transparent green
                 'rgba(213, 226, 249, 0.6)'
              ]
            setOptions(_options)
            setTimeout(()=>{
                setLoading(false)
            },300)

            setFeedback(get_all_feedback())
        }
    },[relativeImportance,min,max])


    const calculate_scores = () =>{
        return calculate_average_outcome(responses[0],selected)
    }

    useEffect(()=>{
        if(responses?.length>0){
        setOutcomeScore(calculate_average_outcome(responses[0],selected))
        }
    },[responses,data])

    const get_outcome_benchmark = () =>{
        console.log(outcomeStandards)
    }

    // const generate_content = () =>{
    //     switch(selectedNav){
    //         case 0:
    //             return <Predictions data={modifiedData} options={options} series={series} loading={loading}/>
    //         case 1:
    //             return <Likelihood data={data} />
    //         case 2:
    //             return <ROITable data={modifiedData} core_data={core_data} title={data?.outcome.name}/>
    //         case 3:
    //             return <BenchmarkChart score={outcomeScore}/>
    //         case 4:
    //             return <FeedbackTable 
    //             feedback={feedback} 
    //             name={data?.outcome.name} 
    //             importance={relativeImportance}
    //             selected={selected}/>

    //         case 5:
    //             return (<>
    //                 <TrendChart/>
    //                 <Timeline/>
    //             </>)

    //         case 6:
    //             return <EffectPlot data={data}/>
    //         default:
    //             return <div>1</div>
    //     }
    // }
    


    return (
        <Container>
            {/* <SideNav>
                <NavItem selected={selectedNav == 0} onClick={()=>setSelectedNav(0)}>
                    Predictions
                </NavItem>
                <NavItem selected={selectedNav == 1} onClick={()=>setSelectedNav(1)}>
                    Likelihood analysis
                </NavItem>
                <NavItem    selected={selectedNav == 6} onClick={()=>setSelectedNav(6)}>
                    Effect analysis
                </NavItem>
                <NavItem selected={selectedNav == 2} onClick={()=>setSelectedNav(2)}>
                    ROI analysis
                </NavItem>
                <NavItem selected={selectedNav == 3} onClick={()=>setSelectedNav(3)}>
                    Scores
                </NavItem>
                <NavItem    selected={selectedNav == 4} onClick={()=>setSelectedNav(4)}>
                    Themes
                </NavItem>
                <NavItem    selected={selectedNav == 5} onClick={()=>setSelectedNav(5)}>
                    Trends
                </NavItem>
            </SideNav> */}
            <Content>
                <DropdownArea>

                <SmallTitle>Select predictive model</SmallTitle>
                <Dropdown
                    placeholder='Select Model'
                    fluid
                    selection
                    value={selectedModel}
                    onChange={(e,{value})=>setSelectedModel(value)}
                    options={[
                        { key: 'Culture', text: 'Culture', value: 'Culture' },
                        { key: 'EQ', text: 'EQ', value: 'EQ' }
                    ]}
                />
                </DropdownArea>

            <BenchmarkChart score={outcomeScore} title={data?.outcome.name} standards={outcomeStandards} selected={selected}/>
            <Predictions data={modifiedData} options={options} series={series} loading={loading} type={selectedModel}/>
            <ROITable data={modifiedData} core_data={core_data} title={data?.outcome.name} type={selectedModel}/>
            

                

                
            </Content>
           

            
           
            
        </Container>
    )

}

export default Model


const Container = styled.div`   
    margin-top:40px;
    display:flex;
    position:relative;
`
const SideNav = styled.div`
    width:175px;
    border-right:1px solid #DFDFDF;
`
const Content = styled.div`
    width:100%;
    padding:0px 20px;
    display:flex;
    flex-wrap:wrap;
`

const NavItem = styled.div`
    padding:10px;
    cursor:pointer;
    width:150px;
    color:${props=>props.selected?'#2D70E2':'#666D79'};
    background-color:${props=>props.selected?'#F8FAFF':'#fff'};
    font-weight:${props=>props.selected?'bold':'normal'};
    font-size:12px;
    border-radius:5px;
    &:hover{
        background:#f7f7f7;
    }
`

const Title = styled.div`
color: #2d70e2;
text-transform: uppercase;
font-size: 14px;
font-weight: 600;
margin-bottom: 20px;
display: flex;
`

const Description = styled.div`
    font-size:30px;
    margin-bottom:40px;
`

const T2 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:5px;
`

const T3 = styled.div`

`

const SmallTitle = styled.div`
    font-size:11px;

`

const DropdownArea = styled.div`
    position:absolute;
    right:20px;
    top:-100px;
`


const data3 = {
	series: [22,18,17,10],
	options: {
		chart: {
			type: "pie",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			width: 400,
		},
        legend: {
            show: true,
            position: 'bottom',
          }

        
	}
};
