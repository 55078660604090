import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import List from "./pdf/List";
import Create from "./pdf/Create";
import Success from "./components/Success";
import { useNavigate } from "react-router-dom";

import {
  SG_DELETE_DEBRIEF_PDF_REPORT,
  SG_DELETE_DEBRIEF_PDF_REPORT_ITEM,
} from "constants/actions";
import { useLocation } from "react-router-dom";
import DeleteModal from "./components/DeleteModal";

// Create a function that takes the data object and a type as arguments
// return a list of debrief_pdf_report objects that have the template_id matching the id of the template with the given type
// the first step is to create a list of ids of the templates that have the type equal to the given type
// then filter the debrief_pdf_report objects using the list of ids
function filterDebriefPdfReport(data, type) {
  const templateIds = data?.templates?.response
    ?.filter((template) => {
      return template?.type === type;
    })
    ?.map((template) => template?.id);
  return data?.debrief_pdf_report?.filter((report) =>
    templateIds?.includes(report?.template_id)
  );
}

const PDFReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stage, setStage] = useState(0);
  const [pdfList, setPdfList] = useState([]);
  const [pdfId, setPdfId] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (pdfReports && pdfReports?.created) {
      setShowSuccess(true);
    } else {
      setShowSuccess(false);
    }

    if (pdfReports?.debrief_pdf_report && pdfReports?.templates) {
      setPdfList(filterDebriefPdfReport(pdfReports, "pdf"));
    }
  }, [showSuccess, pdfReports]);

  useEffect(() => {
    let pathname = location.pathname.split("/");

    switch (pathname[pathname.length - 1]) {
      case "list":
        setStage(0);
        break;
      case "create":
        setStage(1);
        break;
      default:
        setStage(0);
    }
  }, [location.pathname]);

  const handleBack = () => {
    setStage(0);
  };

  const deleteConfirm = (id) => {
    dispatch({
      type: SG_DELETE_DEBRIEF_PDF_REPORT,
      payload: id,
    });
    setDeleteId(null);
    setConfirmDelete(false);
  };

  const deleteConfirmItem = (item) => {
    dispatch({
      type: SG_DELETE_DEBRIEF_PDF_REPORT_ITEM,
      payload: item,
    });
    setDeleteItem(null);
    setConfirmDelete(false);
  };

  const renderContent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <List
            pdfList={pdfList}
            setDeleteId={(id) => setDeleteId(id)}
            setDeleteItem={(item) => {
              setDeleteItem(item);
            }}
            loading={loading}
          />
        );
      case 1:
        return (
          <Create handleBack={handleBack} pdfId={pdfId} pdfList={pdfList} />
        );
      default:
        return <List pdfList={pdfList} />;
    }
  };

  const deleteModal = (
    <DeleteModal
      open={deleteId || deleteItem}
      setOpen={() => {
        setDeleteId(null);
        setDeleteItem(null);
      }}
      handleDeletePDFSavedState={() => {
        if (deleteItem) {
          deleteConfirmItem(deleteItem);
        } else {
          deleteConfirm(deleteId);
        }
      }}
    />
  );

  return (
    <Container>
      {deleteModal}
      {showSuccess && (
        <Success
          open={showSuccess}
          navFunction={() => {
            // Update for the data story.
            navigate("/leader/audit/reports/create");
          }}
          onClose={() => {
            dispatch({
              type: "CLEAR_DEBRIEF_PDF_REPORT",
            });
            setShowSuccess(false);
          }}
        />
      )}
      {renderContent(stage)}
    </Container>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  font-family: "Raleway";
  transition: all 0.3s ease-in-out;
`;

export default PDFReport;
