import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Form, Dropdown } from "semantic-ui-react";
import {
  SG_ADD_USER_TASK,
  SG_CREATE_TASK_SCHEDULE,
  SG_FETCH_TASK_TEMPLATE,
} from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { format } from "date-fns";
import { backIcon } from "../../../../reports/components/Icons";

const userMap = (usersList, employeeList) => {
  const list = usersList?.map((user) => {
    const employeeObj = employeeList?.employees?.find(
      (emp) => emp.id === user?.employee_id
    );

    return {
      key: user?.employee_id,
      value: user?.employee_id,
      text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
      id: user?.id,
    };
  });

  const empList = employeeList?.employees;
  const inactiveList = empList?.filter(
    (emp) => emp.status === 99 || emp.status === 98 || emp.status === 108
  );

  const enabledLogins = empList?.filter((emp) => emp.enable_login === true);

  const filteredList = list
    .filter((emp) => !inactiveList.find((inactive) => inactive.id === emp.key))
    ?.filter((emp) => enabledLogins.find((enabled) => enabled.id === emp.key));

  // Add an option with a -1 value to prevent users from being added when clicking away from dropdown.
  filteredList.unshift({ key: -1, value: -1, text: "Select User" });
  return filteredList;
};

const ScheduleTemplate = ({ taskId, prevCb }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { id } = useParams();

  const [taskName, setTaskName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignedUsersList, setAssignedUsersList] = useState([]);
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    get_employee_org_category,
    get_employees,
    user_manager,
    fetchedTaskTemplate,
    createdScheduledTemplate,
  } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_employee_org_category: state.employee_category?.employee_org_category,
      user_manager: state.user_manager,
      scheduledTasks: state.scheduled_tasks,
      fetchedTaskTemplate: state.task_templates?.fetched_template,
      createdScheduledTemplate: state.task_templates?.created_scheduled_task,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_FETCH_TASK_TEMPLATE,
      payload: id || taskId,
    });
  }, [dispatch, id]);

  const handleUserAdd = (e, data) => {
    const { value } = data;
    if (value && !assignedUsersList.includes(Number(value))) {
      const user = get_employee_org_category.find(
        (u) => u.employee_id === value
      );
      if (user) {
        setAssignedUsersList((prevList) => [...prevList, Number(value)]);
        setSelectedUsers((prevUsers) => [...prevUsers, user]);
      }
    }
  };

  const handleUserRemove = (value) => {
    const userId = Number(value?.employee);
    setAssignedUsersList((prevList) => prevList.filter((id) => id !== userId));
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((user) => user.employee_id !== userId)
    );
  };

  const scheduleTask = async (taskName, assignees) => {
    const payloadObj = {
      task_template_user_tasks: Number(id || taskId),
      name: fetchedTaskTemplate?.name,
      assigned_employees: assignees,
    };

    setLoading(true);
    dispatch({
      type: SG_CREATE_TASK_SCHEDULE,
      payload: payloadObj,
    });
  };

  const handleNext = () => {
    if (stage === 0) {
      setStage(1);
    } else if (stage === 1) {
      scheduleTask(taskName, assignedUsersList);
    }
  };

  useEffect(() => {
    if (createdScheduledTemplate?.id) {
      navigate(`/leader/audit/manage/tasktemplates_v2`);
      addToast(
        "Survey scheduled successfully, you should be able to access it through the Pending/Completed tab",
        { appearance: "success" }
      );
    }
  }, [createdScheduledTemplate]);

  const getFetchedDetails = (fetchedTaskTemplate) => {
    const templateName = fetchedTaskTemplate.name;
    // use the format function to format the date as follows: Jan 2, 2021
    const createdDate = format(
      new Date(fetchedTaskTemplate.created_at),
      "MMM d, yyyy"
    );
    const updatedDate = format(
      new Date(fetchedTaskTemplate.updated_at),
      "MMM d, yyyy"
    );

    const taskInformation = fetchedTaskTemplate.user_tasks;
    const taskDescription = taskInformation?.find(
      (task) => task.task_type === "description"
    )?.text;
    const taskQuestions = taskInformation?.filter(
      (task) => task.task_type === "question"
    );

    const feedbackNotes = taskInformation?.find(
      (task) => task.task_type === "feedback_notes"
    )?.selection;

    if (fetchedTaskTemplate) {
      const createdUser = fetchedTaskTemplate?.user_tasks?.find(
        (task) => task.task_type === "created_by"
      );
      return (
        <SummaryBox>
          <SummaryItem>
            <SummaryTitle>Name:</SummaryTitle> {templateName}
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Description:</SummaryTitle> {taskDescription}
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Feedback Tips:</SummaryTitle>{" "}
            {feedbackNotes ? "Enabled" : "Disabled"}
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Questions:</SummaryTitle>
            <QuestionList>
              {taskQuestions?.map((question) => (
                <QuestionItem key={question.id}>
                  {question.question}&nbsp;
                  {question?.aiEnhanced && (
                    <AiEnhanced>
                      <span>AI Enhanced</span>
                    </AiEnhanced>
                  )}
                </QuestionItem>
              ))}
            </QuestionList>
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Created By:</SummaryTitle> {`${createdUser?.name}`}
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Created Date:</SummaryTitle> <i>{createdDate}</i>
          </SummaryItem>
          <SummaryItem>
            <SummaryTitle>Last Updated:</SummaryTitle> <i>{updatedDate}</i>
          </SummaryItem>
        </SummaryBox>
      );
    }
  };

  return (
    <PageContainer>
      <EditButton onClick={prevCb}>{backIcon()} Back</EditButton>
      <Header>Schedule Survey</Header>
      <TaskSummary>
        {fetchedTaskTemplate?.name && getFetchedDetails(fetchedTaskTemplate)}
      </TaskSummary>
      {error && (
        <ErrorContainer>
          <ErrorTitle>Scheduling Error</ErrorTitle>
          <ErrorMessage>
            An error occurred while scheduling your survey. Users cannot be
            assigned at this time.
          </ErrorMessage>
          <ErrorActionTitle>Suggested Actions:</ErrorActionTitle>
          <ErrorActions>
            Check the Pending or Completed tab for your survey.
            <br />
            Try adding users from the Summary screen.
          </ErrorActions>
          <Button
            onClick={() => navigate(`/leader/audit/manage/tasktemplates_v2`)}
          >
            Go Back
          </Button>
        </ErrorContainer>
      )}
      {loading && (
        <LoadingContainer>
          <p>
            Preparing your survey, you will be redirected once this is complete.
          </p>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      {!loading && !error && (
        <>
          {stage === 0 && (
            <StageContainer>
              <h2>Set the custom survey name</h2>
              <p>Provide a name for the survey</p>
              <Form>
                <Form.Input
                  placeholder="Survey Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                />
              </Form>
            </StageContainer>
          )}
          {stage === 1 && (
            <StageContainer>
              <h2>Assign the survey to employees</h2>
              <p>Select employees to assign this survey</p>
              <Dropdown
                placeholder="Select Users"
                fluid
                search
                value={selectedUsers}
                selection
                options={userMap(get_employee_org_category, get_employees)}
                onChange={handleUserAdd}
              />
              {selectedUsers && selectedUsers.length > 0 && (
                <Table>
                  <TableHeader>
                    <TH width={80}>User</TH>
                    <TH width={20}>Action</TH>
                  </TableHeader>
                  {selectedUsers.map((user) => (
                    <Row key={user.id}>
                      <TD width={80}>{`${user.employee_full_name}`}</TD>
                      <TD width={20}>
                        <RemoveText onClick={() => handleUserRemove(user)}>
                          Remove
                        </RemoveText>
                      </TD>
                    </Row>
                  ))}
                </Table>
              )}
            </StageContainer>
          )}
          <Footer>
            <Button
              onClick={() => {
                navigate(`/leader/audit/manage/tasktemplates_v2`);
              }}
            >
              Cancel
            </Button>
            <Button primary onClick={handleNext}>
              {stage === 0 ? "Next" : "Send Survey"}
            </Button>
          </Footer>
        </>
      )}
    </PageContainer>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

// Should be red text with a cursor pointer
const RemoveText = styled.div`
  color: #f15062;
  cursor: pointer;
`;

const EditButton = styled.div`
  color: #666d79;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-family: "Raleway";
  width: fit-content;
`;

const Header = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
`;

const ErrorContainer = styled.div`
  color: red;
  text-align: center;
  margin: 20px 0;
  font-family: "Raleway", sans-serif;
`;

const ErrorMessage = styled.p`
  margin: 0;
  font-size: 16px;
`;

const ErrorTitle = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
`;

const ErrorActionTitle = styled.span`
  font-weight: bold;
  display: block;
  margin-top: 15px;
  font-size: 16px;
`;

const ErrorActions = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const LoadingAnimation = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const StageContainer = styled.div`
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const PageContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Button = styled.div`
  color: white;
  background-color: ${(props) => (props.primary ? "#2D70E2" : "#666d79")};
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
  margin-top: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const TaskSummary = styled.div`
  margin-bottom: 20px;
`;

const SummaryBox = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  font-family: "Raleway";
  text-align: left;
`;

const SummaryItem = styled.div`
  margin-bottom: 10px;
`;

const SummaryTitle = styled.span`
  font-weight: bold;
`;

const QuestionList = styled.ul`
  margin: 10px 0;
  padding-left: 20px;
`;

const QuestionItem = styled.li`
  font-size: 14px;
  color: #2a3039;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const AiEnhanced = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  color: #007acc;
  font-weight: bold;
`;

const AiIcon = styled.span`
  margin-right: 5px;
  font-size: 14px;
`;

const Table = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#f8faff" : "white")};
  border-radius: 5px;
  padding: 0 10px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

export default ScheduleTemplate;
