import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";



const Likelihood = ({data})=>{

    const [series,setSeries] = useState(data3.series)
    const [options,setOptions] = useState(data3.options)
    const [loading,setLoading] = useState(false)

    const [outcomeName,setOutcomeName] = useState('')
    const [low_score,setLowScore] = useState(0)
    const [high_score,setHighScore] = useState(0)
    const [likelihood,setLikelihood] = useState(0)

    console.log(data)

    useEffect(()=>{
        if(data){
            setLoading(true)
            let _series = [
                {
                    data: [(data.likelihood.low_score*100).toFixed(0), (data.likelihood.high_score*100).toFixed(0)],
                },
            ]            
            setSeries(_series)

            let _options = options
            _options.yaxis.title.text = `Likelihood of high ${data.outcome.name.toLowerCase()} score`
            setOptions(_options)
            
            setTimeout(()=>{
                setLoading(false)
            },200)
        }

    },[data])

    useEffect(()=>{
        if(data){
            setOutcomeName(data.outcome.name.toLowerCase())
            setLowScore(data.likelihood.low_score)
            setHighScore(data.likelihood.high_score)
            let likelihood_score = (data.likelihood.high_score / data.likelihood.low_score)
            likelihood_score = likelihood_score > 10 ? likelihood_score.toFixed(0) : likelihood_score.toFixed(1)
            setLikelihood(likelihood_score)
        }
    },[data])


    if(!data){
        return ""
    }


    return (

        <Container>

           
            <Title>Likelihood analysis</Title>
            <BigNumber>
                High culture scores lead to a {likelihood}x higher likelihood of strong {outcomeName} 
            </BigNumber>
            <Text>
                The likelihood of an individual or group scoring {outcomeName} high (above 8) is {((data.likelihood.high_score / data.likelihood.low_score)*100).toFixed(0)}% higher when culture scores are high.
            </Text>
            {loading?"":<Chart options={options} series={series} type="bar" width={800} />}


          <Question>What this shows?</Question>
          <Answer>
          This chart demonstrates the strong correlation between an organization’s culture and its {outcomeName} outcomes. Specifically, it shows that groups or individuals with high culture scores are {likelihood} times more likely to achieve strong {outcomeName} compared to those with low culture scores. The bar chart visualizes the likelihood of scoring high on {outcomeName}, with a significant difference between groups that have high culture scores ({(high_score*100).toFixed(0)}% likelihood) and those with low culture scores ({(low_score*100).toFixed(0)}% likelihood).
          </Answer>

          <Question>Why it matters?</Question>

          <Answer>
          This analysis highlights the critical role that culture plays in driving {outcomeName} within your organization. A high likelihood of strong {outcomeName} in groups with robust culture scores indicates that fostering a positive, supportive, and well-aligned culture can lead to significantly better performance outcomes. Understanding this relationship helps you prioritize cultural initiatives, knowing that improvements in culture can directly translate to measurable gains in {outcomeName}, ultimately boosting overall business success.
          </Answer>
            
        </Container>
    )

}

export default Likelihood;

const Container = styled.div`
    margin-bottom:40px;
`

const BigNumber = styled.div`
    font-size: 28px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 10px;
`

const Text = styled.div`

`
const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

`


const Question = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`

const Answer = styled.div`
    font-size:14px;
    margin-bottom:40px;
    line-height:1.6;
`



const data3 = {
    series: [
      {
        data: [10, 8],
      },
    ],
    options: {
      chart: {
        type: "bar",
        fontFamily: "Poppins, sans-serif",
        foreColor: "#7E7E7E",
        toolbar: {
          show: false,
        },
        width: 800,
        height: 400,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            if (val === 0.1) {
              return "0%";
            }
            return val + "% of individuals in this group gave a high score.";
          },
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        show: false,
      },
      grid: {
        padding: {
          bottom: 60,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            // Position the label in the center of the bar
            position: "center",
            style: {
              fontSize: "12px",
              colors: ["#000000"],
            },
          },
        },
      },
      colors: [
        "#2D70E2",
        "#2D70E2"
      ],
      xaxis: {
        categories: [
          "Low scoring culture",
          "High scoring culture",
        ],
        tickPlacement: "on",
        labels: {
          style: {
            fontSize: "9px",
            colors: "#2D70E2",
          },
          maxHeight: 200,
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: 1,
        forceNiceScale: true,
        title: {
          text: "Likelihood of high outcome score",
          style: {
            fontWeight: 400,
          },
        },
      },
    },
  };
  