import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
//
import Action from "ProtoType/culture/Action";
//
import DataScience from "ProtoType/culture/DataScience/index";
import NewCulture from "ProtoType/culture/index";
import Performance from "reports/Audit/OKR/index";
import ROI from "reports/Audit/ROI/index";
import Risk from "reports/Audit/Risk/index";
import DEIPDF from "components/_AuditTemplate/index"
import Models from "reports/Models/index";
import Calendar from "reports/Models/Calendar";
import Recommendation from "reports/Models/Recommendation";
import HumanLoop from "reports/HumanLoop/index";
import Enhancement from "reports/HumanLoop/Enhancement";
import Values from "reports/values/index"
import Recruiting from "reports/Recruiting/index"
import Posting from "reports/Recruiting/Posting/index"
import Applicant from "reports/Recruiting/Applicant";
import Ideas from "ProtoType/ideas/index"
import Editor from "ProtoType/Editor"
import Matcher from "ProtoType/Matcher/Recommendation"
import Templates from "ProtoType/Template_creator/Create"
import ActionPlan from "ProtoType/ActionPlan/index"
import Context from "ProtoType/Context/index"
import Accountability from "ProtoType/Accountability";
import DraftEditor from "ProtoType/DraftPlan/Dashboard/index"
import Rec from "ProtoType/RecommendationsV3/index";
import { useLocation } from "react-router-dom";
import SimpleDash from "ProtoType/SimpleDash/index";
import { useDispatch } from "react-redux";
import CreateDraft from "ProtoType/DraftPlan/Create/index";
import Drafts from "ProtoType/DraftPlan/Dashboard/Drafts";
import Explain from "ProtoType/SimpleDash/Explain";
import ExplainDashboard from "ProtoType/SimpleDash/ExplainDash";
import CreateExplain from "ProtoType/SimpleDash/ExplainDash/Create";
import Rules from "ProtoType/Rules/index";
import StoryDetails from "ProtoType/Rules/StoryDetails";
import PlanDetails from "ProtoType/Rules/PlanDetails";
import CreatePlan from "ProtoType/Rules/Create";
import ReviewPlan from "ProtoType/Rules/Review";
import Collab from "ProtoType/DraftPlan/Collaboration/index";
import StoryTemplate from "ProtoType/StoryTemplate/index";
import UserProfile from "ProtoType/Accountability/UserProfile/index";
import GroupData from "ProtoType/GroupData/index";
import GroupDash from "ProtoType/GroupData/Dashboard";
import GroupAnalysis from "ProtoType/GroupData/Analysis";
import GroupManagement from "ProtoType/GroupData/History";
import Entry from "ProtoType/GroupData/History/Entry";
import AnalysisDashboard from "ProtoType/GroupData/AnalysisDashboard";
import Recon from "ProtoType/Recon/index";
import ReconCollect from "ProtoType/Recon/Collect/index";
import ActiveDash from "ProtoType/Recon/Active/index";
import ReconReport from "ProtoType/Recon/Reports/index";
import ReconConversation from "ProtoType/Recon/Reports/Conversation";
import ReconAnalysis from "ProtoType/Recon/Reports/Analysis";
import Nudge from "ProtoType/Nudge/index";
import ReportHome from "ProtoType/ReportHome/index";
import DataStoryV2 from "ProtoType/DataStoryV2/index";
import DataJourney from "ProtoType/DataJourney/index";
import Onboarding from "ProtoType/Onboarding/index";
import ReportCreation from "ProtoType/ReportCreation/index";
import SimpleAnalytics from "ProtoType/SimpleAnalytics/index";
import LeaderAccountability from "ProtoType/LeaderAccountability/index";
import Tasks from "ProtoType/Tasks/index";
import ContextOnboarding from "ProtoType/ContextOnboarding/index";
import RecV4 from "ProtoType/RecV4/index";
import Coach from "ProtoType/Coach/index";
import TaskQuestion from "ProtoType/Tasks/TaskQuestion";
import QuestionData from "ProtoType/Tasks/QuestionData";
import GroupAnalytics from "ProtoType/GroupData/AnalysisDashboard/index";
import Report2 from "ProtoType/GroupData/AnalysisDashboard/Scatter";
import PlanCopilot from "ProtoType/PlanCopilot";
import PlanEditor from "ProtoType/MDXEditor"
import InsightsV2 from "ProtoType/InsightsV2/index";
import Heatmap from "ProtoType/Heatmap/index";

import { SG_GET_CULTURE_AUDIT_REPORTS } from "constants/actions";
import Lost from "404";
//
function RoutesProtoType() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [auditTypeId, setAuditTypeId] = useState();
  const isDEI = location.pathname.includes("edi");

  const { get_auth, get_organizations, get_selectedOrg, get_employees } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
        get_employees: state.employees,
      }),
      shallowEqual
    );

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );

  // State setup
  useEffect(() => {
    let auditTypeId;
    if (get_organizations) {
      auditTypeId = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled
        ? 5
        : isDEI
        ? 4
        : 3;
      setAuditTypeId(auditTypeId);
    }
  }, [get_organizations]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees[get_auth.employee_id]);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth, EmployeeId, get_employees]);

  useEffect(() => {
    if (auditTypeId) {
      if (AmbassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}&organization=${get_selectedOrg?.organization?.id}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}`,
        });
      }
    }
  }, [dispatch, auditTypeId, AmbassadorId, get_selectedOrg?.organization?.id]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  // State setup
  useEffect(() => {
    let auditTypeId;
    if (get_organizations) {
      auditTypeId = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled
        ? 5
        : isDEI
        ? 4
        : 3;
      setAuditTypeId(auditTypeId);
    }
  }, [get_organizations]);

  useEffect(() => {
    const Found = organization?.services_enabled
      ?.filter((srv) => srv?.enabled === true)
      .map((sr) => sr.name);

    setValidRoute(Found);
  }, [organization]);

  useEffect(() => {
    if (auditTypeId) {
      if (AmbassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}&organization=${get_selectedOrg?.organization?.id}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}`,
        });
      }
    }
  }, [dispatch, auditTypeId, AmbassadorId, get_selectedOrg?.organization?.id]);

  // useEffect(() => {
  //   const timer1 = setTimeout(() => {
  //     if (!validRoute?.includes("Prototype")) {
  //       return navigate("/app/unsubscribed");
  //     }
  //   }, 2000);
  //   return () => clearTimeout(timer1);
  // }, [organization, validRoute, navigate]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    if (validRoute) {
      if (validRoute?.includes("Teamtool")) {
        if (employee?.account_type > 7) {
          if (employee?.role > 7) {
            return navigate("/app/access-denied");
          }
        }
      }
    }
  }, [employee, validRoute, navigate]);

  return (
    <Routes>
      <Route path="/" element={<Action />} />
      <Route path="group" element={<NewCulture />} />
      <Route path="data_science" element={<DataScience />} />
      <Route path="performance" element={<Performance />} />
      <Route path="roi" element={<ROI />} />
      <Route path="risk" element={<Risk />} />
      <Route path="model" element={<Models />} />
      <Route path="recommendation" element={<Recommendation />} />
      <Route path="humanloop" element={<HumanLoop />} />
      <Route path="enhancement" element={<Enhancement />} />
      <Route path="calendar" element={<Calendar />} />
      <Route path="values" element={<Values />} />
      <Route path="recruiting" element={<Recruiting />} />
      <Route path="posting" element={<Posting />} />
      <Route path="applicant" element={<Applicant />} />
      <Route path="ideas" element={<Ideas />} />
      <Route path="pdf" element={<DEIPDF />} />
      <Route path={"*"} element={<Lost />} />
      <Route path="editor" element={<Editor />} />
      <Route path="matcher" element={<Matcher />} />
      <Route path="templates" element={<Templates />} />
      <Route path="actionplan" element={<ActionPlan />} />
      <Route path="context" element={<Context />} />
      <Route path="recs" element={<Rec />} />
      <Route path={"recs/:id"} element={<Rec />} />
      <Route path="simpledash" element={<SimpleDash />} />
      <Route path="accountability/:myParam" element={<Accountability />} />
      <Route path="accountability/" element={<Accountability />} />
      <Route path="draft" element={<DraftEditor />} />
      <Route path="draft/create" element={<CreateDraft />} />
      <Route path="draft/drafts" element={<Drafts />} />
      <Route path="explain" element={<Explain />} />
      <Route path="explain/dashboard" element={<ExplainDashboard />} />
      <Route path="explain/dashboard/create" element={<CreateExplain />} />
      <Route path="rules" element={<Rules />} />
      <Route path="story" element={<StoryDetails />} />
      <Route path="plan" element={<PlanDetails />} />
      <Route path="create" element={<CreatePlan />} />
      <Route path="review" element={<ReviewPlan />} />
      <Route path="collab" element={<Collab />} />
      <Route path="storytemplate" element={<StoryTemplate />} />
      <Route path="userprofile/*" element={<UserProfile />} />
      <Route path="groupdata/dashboard" element={<GroupDash />} />
      <Route path="groupdata/analysis" element={<GroupAnalysis />} />
      <Route path="groupdata" element={<GroupManagement />} />
      <Route path="groupdata/history/entry" element={<Entry />} />
      <Route path="groupdata/analysis/dashboard" element={<AnalysisDashboard />} />
      <Route path="recon/collect" element={<ReconCollect />} />
      <Route path="recon/active" element={<ActiveDash />} />
      <Route path="recon" element={<ReconReport />} />
      <Route path="recon/reports/conversation" element={<ReconConversation />} />
      <Route path="groupdata/reports" element={<ReconAnalysis />} />
      <Route path="nudge" element={<Nudge />} />
      <Route path="report" element={<ReportHome />} />
      <Route path="groupdata/upload" element={<GroupData />} />
      <Route path="datastory" element={<DataStoryV2 />} />
      <Route path="datajourney" element={<DataJourney />} />
      <Route path="onboarding" element={<Onboarding />} />      
      <Route path="reportcreation" element={<ReportCreation />} />
      <Route path="simpleanalytics" element={<SimpleAnalytics />} />
      <Route path="leaderaccountability" element={<LeaderAccountability />} />
      <Route path="tasks" element={<Tasks />} />
      <Route path="taskquestion" element={<TaskQuestion />} />
      <Route path="contextonboarding" element={<ContextOnboarding />} />
      <Route path="recv4" element={<RecV4 />} />
      <Route path="coach" element={<Coach />} />
      <Route path="questiondata" element={<QuestionData />} />
      <Route path="groupdata/analysisdashboard" element={<GroupAnalytics />} />
      <Route path="groupdata/analysisdashboard/scatter" element={<Report2 />} />
      <Route path="copilot" element={<PlanCopilot />} />
      <Route path="planeditor" element={<PlanEditor />} />
      <Route path="insightsv2" element={<InsightsV2 />} />
      <Route path="heatmap" element={<Heatmap />} />
    </Routes>
  );
}

export default RoutesProtoType