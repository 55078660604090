import React from "react";
import { View, Text, StyleSheet, Page } from "@react-pdf/renderer";
import Table from "../components/Table";
import OutcomesTable from "../components/OutcomesTable";
import Footer from "../components/Footer";
import Side from "../components/Side";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
    color: "#2C3644",
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
    color: "#2C3644",
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
    color: "#2C3644",
  },
  summary: {
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    color: "#2C3644",
  },
  top: {
    backgroundColor: "#2C3644",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
  outcomesSummary: {
    padding: 10,
    marginTop: 10,
  },
});

const numberToWords = (number) => {
  const numberString = number.toString();
  const numberArray = numberString.split("");
  const words = [];
  const numberWords = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  numberArray.forEach((digit) => {
    words.push(numberWords?.[digit]);
  });

  if (words?.length > 0) {
    return words.join(" ");
  } else {
    return number;
  }
};

const Overview = (props) => {
  const {
    humanFactorData,
    systemFactorData,
    calculated,
    overviewTitles,
    config,
    previousData,
    questions,
    isSportLaw,
    outcomesData,
    outcomesQuestions,
  } = props;

  // const outcomesTable = outcomesQuestions ?

  const getOutcomesTable = (outcomesData, questions) => {
    const outcomesTable = {
      rows: [
        { title: "Critical Outcome", width: "68%" },
        { title: "Score", width: "10%" },
        { title: "Norm", width: "10%" },
        { title: "Change", width: "12%" },
      ],
    };

    let data = [];
    questions?.forEach((ques, idx) => {
      let row = [];
      row.push(ques.name);
      let score =
        Number(
          outcomesData[idx][outcomesData[idx]?.length - 1]?.overallAverage
        ) * 10;
      if (!isNaN(score)) {
        row.push(`${score}%`);
        row.push("--");
        row.push("--");
        data.push(row);
      }
    });

    outcomesTable.data = data;
    return outcomesTable;
  };

  const TableTemplate = !isSportLaw
    ? {
        rows: [
          { title: "Factor", width: "68%" },
          { title: "Score", width: "10%" },
          { title: "Norm", width: "10%" },
          { title: "Change", width: "12%" },
        ],
        data: [
          ["Safety", "96%", "A+", "--", "--"],
          ["Well-being", "96%", "A+", "--", "--"],
          ["Cohesion", "96%", "A+", "--", "--"],
          ["Empowerment", "96%", "A+", "--", "--"],
          ["Personalization", "96%", "A+", "--", "--"],
          ["Belonging", "96%", "A+", "--", "--"],
        ],
      }
    : {
        rows: [
          { title: "Factor", width: "70%" },
          { title: "Score", width: "10%" },
          { title: "Norm", width: "10%" },
          { title: "Change", width: "10%" },
        ],
        data: [
          ["Safety", "96%", "--", "--"],
          ["Well-being", "96%", "--", "--"],
          ["Cohesion", "96%", "--", "--"],
          ["Empowerment", "96%", "--", "--"],
          ["Personalization", "96%", "--", "--"],
          ["Belonging", "96%", "--", "--"],
        ],
      };

  let overallChangePeople = 0;
  let overallChangePerformance = 0;

  const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

  const check_reverse_score = (resp) => {
    let reverse =
      questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
        ?.reverse;
    let response = reverse ? rev_order[resp.response] : resp.response;
    return response;
  };

  const checkReverseAverage = (resp) => {
    let reverse =
      questions.dimensions[resp.dimension]?.factors[Number(resp.section)]
        ?.questions[Number(resp.question)]?.reverse;
    let response = reverse ? 10 - resp.average : resp.average;
    return response;
  };

  const average = (array) => {
    let total = 0;
    let n = 0;
    array.map((item) => {
      total += item;
      n += 1;
    });
    return total / n;
  };

  const get_last_overall_average = (prevData, dimension) => {
    //Filter questions based on the selected pillar first
    let questionsSum = prevData
      .map((item) => item.questions.filter((f) => f.id === dimension))
      .flat()
      .map((item) => check_reverse_score(item));
    return average(questionsSum);
  };

  const get_pillar_averages = (data, questions) => {
    let _data = [];
    let pillars = [];
    questions.dimensions.map((d) => {
      let d_data = [];
      d.factors.map((f) => {
        const dataArray = data
          .map((item) => {
            return item.questions
              .filter((x) => x.id == d.id - 1 && x.factor == f.id - 1)
              .map((q) => check_reverse_score(q, questions));
          })
          .flat();
        let pillarAvg = average(dataArray);
        d_data.push(pillarAvg);
        pillars.push({
          avg: pillarAvg.toFixed(1),
          factor: f.id - 1,
          dimension: d.id - 1,
        });
      });

      _data.push(average(d_data));
    });

    return { overall: _data, pillars: pillars };
  };

  const get_last_factor_average = (prevData, dimension, factor) => {
    //Filter questions based on the selected pillar first
    let questionsSum = prevData
      .map((item) =>
        item.questions.filter((f) => f.id === dimension && f.factor === factor)
      )
      .flat()
      .map((item) => check_reverse_score(item));
    return average(questionsSum) * 10;
  };

  const getAverage = (data) => {
    let total = 0;
    let n = 0;
    data.map((i) => {
      total += i.average * i.n;
      n += i.n;
    });

    return ((total / n) * 10) / 10;
  };

  // find the average using the factor and dimension
  const findFactorAvg = (avgs, factor, dimension) => {
    const avg = avgs?.pillars?.find(
      (p) => p.factor === factor && p.dimension === dimension
    );
    return avg?.avg;
  };
  const pillarAverages =
    previousData?.length > 0 && get_pillar_averages(previousData, questions);

  const getFactorsDetails = (calculated, dimension, factorsData) => {
    let newDetails = factorsData?.map((item) => {
      const dimData = calculated?.responses?.filter(
        (item) => item.dimension === Number(dimension)
      );
      let factorData = dimData?.filter(
        (dimItem) => Number(dimItem.section) === item.id
      );

      const averageValue =
        factorData?.reduce((a, b) => {
          return a + checkReverseAverage(b) * b?.n;
        }, 0) / factorData?.reduce((a, b) => a + b?.n, 0);

      return {
        ...item,
        average: averageValue.toFixed(1) * 10,
        averageForPrevious: averageValue * 10,
      };
    });

    // remove any NaN or undefined values
    newDetails = newDetails?.filter((item) => item.average);

    let formattedData = factorsData?.map((factor) => {
      const currentFactor = newDetails?.find((item) => item.id === factor.id);
      let previousAvg;
      let change;
      if (previousData?.length > 0) {
        previousAvg = isSportLaw
          ? previousData?.length > 0 &&
            get_last_factor_average(previousData, dimension, factor.id)
          : pillarAverages &&
            findFactorAvg(pillarAverages, factor.id, dimension);

        if (previousAvg) {
          change = isSportLaw
            ? (
                previousAvg && currentFactor?.averageForPrevious - previousAvg
              ).toFixed(0)
            : previousAvg && currentFactor?.average - Number(previousAvg * 10);
        }
      }
      if (currentFactor?.average) {
        if (!isSportLaw) {
          return [
            factor.factor,
            `${currentFactor?.average}%`,
            "--",
            change ? `${change}%` : "--",
          ];
        } else {
          return [
            factor.factor,
            `${currentFactor?.average}%`,
            "--",
            change && Number(change) !== 0 ? `${change}%` : "--",
          ];
        }
      }
    });

    formattedData = formattedData?.filter((item) => item);
    return formattedData;
  };

  const humanDataFormatted = getFactorsDetails(calculated, 0, humanFactorData);
  const systemDataFormatted = getFactorsDetails(
    calculated,
    1,
    systemFactorData
  );

  let overallHumanAvg = humanDataFormatted.reduce(
    (a, b) => a + Number(b[1].replace("%", "")),
    0
  );

  let overallFactorAvg = systemDataFormatted.reduce(
    (a, b) => a + Number(b[1].replace("%", "")),
    0
  );

  let sLawPeopleChange;
  let sLawSystemChange;
  if (isSportLaw) {
    const prevPeopleAvg = get_last_overall_average(previousData, 0) * 10;
    sLawPeopleChange =
      overallHumanAvg / humanDataFormatted.length - prevPeopleAvg;
    const prevSystemAvg = get_last_overall_average(previousData, 1) * 10;
    sLawSystemChange =
      overallFactorAvg / systemDataFormatted.length - prevSystemAvg;
  }

  overallHumanAvg = Math.round(overallHumanAvg / humanDataFormatted.length);

  const peopleChange = isSportLaw
    ? sLawPeopleChange
    : pillarAverages && overallHumanAvg - pillarAverages?.overall[0] * 10;

  overallChangePeople = isSportLaw
    ? (peopleChange &&
        Math.abs(Number(peopleChange)).toFixed(0) !== "0" &&
        ` (${peopleChange.toFixed(0)}%)`) ||
      ""
    : previousData && previousData?.length > 0 && !isNaN(peopleChange)
    ? ` (${peopleChange.toFixed(1)}%)`
    : "";

  overallFactorAvg = Math.round(overallFactorAvg / systemDataFormatted.length);
  const systemChange = isSportLaw
    ? sLawSystemChange
    : pillarAverages && overallFactorAvg - pillarAverages?.overall[1] * 10;

  overallChangePerformance = isSportLaw
    ? (systemChange &&
        Math.abs(Number(systemChange)).toFixed(0) !== "0" &&
        ` (${systemChange.toFixed(0)}%)`) ||
      ""
    : previousData && previousData?.length > 0 && !isNaN(systemChange)
    ? ` (${systemChange.toFixed(1)}%)`
    : "";

  const humanFactorTemplate = {
    rows: TableTemplate.rows,
    data: humanDataFormatted,
  };

  const systemFactorTemplate = {
    rows: TableTemplate.rows,
    data: systemDataFormatted,
  };

  return (
    <Page style={styles.page} break={false}>
      <Side {...config} isSportLaw={isSportLaw} />
      <Footer {...config} />
      <Text style={styles.T2}>Factors Overview</Text>
      <View style={styles.line} />

      <Text style={styles.p}>
        The tables below contain summary metrics on the people and performance
        factors within the Culture Index.
      </Text>

      <View wrap={false} break={false}>
        <Text style={[styles.title, { color: config.titleColor }]}>
          {overviewTitles[0]} Dimension Overview
        </Text>
        <Text style={styles.description}>
          The table below contains summary metrics on all&nbsp;
          <Text style={{ fontWeight: 500 }}>
            {overviewTitles[0].toLowerCase()}
          </Text>
          &nbsp;factors within the Culture Index.
        </Text>

        <View
          style={[
            styles.summary,
            { border: `2px solid ${config.tableBorderColor}` },
          ]}
        >
          <View
            style={[styles.top, { backgroundColor: config.tableBorderColor }]}
          >
            <Text>
              {overviewTitles[0]} Dimension Score: {Math.round(overallHumanAvg)}
              % &nbsp;
              {previousData && overallChangePeople}
            </Text>
          </View>
          <View style={styles.bottom}>
            <Text
              style={{ fontSize: "10", fontWeight: 700, marginBottom: -15 }}
            >
              Factor Summary
            </Text>
            <Table
              data={humanFactorTemplate}
              headerColor={config.tableBorderColor}
              isSportLaw={isSportLaw}
            />
          </View>
        </View>
      </View>
      <View wrap={true} break={true} />
      <View wrap={false} break={false}>
        <Text style={[styles.title, { color: config.titleColor }]}>
          {overviewTitles[1]} Dimension Overview
        </Text>
        <Text style={styles.description}>
          The table below contains summary metrics on all&nbsp;
          <Text style={{ fontWeight: 500 }}>
            {overviewTitles[1].toLowerCase()}
          </Text>
          &nbsp;factors within the Culture Index.
        </Text>

        <View
          style={[
            styles.summary,
            { border: `2px solid ${config.tableBorderColor}` },
          ]}
        >
          <View
            style={[styles.top, { backgroundColor: config.tableBorderColor }]}
          >
            <Text>
              {overviewTitles[1]} Dimension Score:{" "}
              {Math.round(overallFactorAvg)}% &nbsp;
              {previousData && overallChangePerformance}
            </Text>
          </View>
          <View style={styles.bottom}>
            <Text
              style={{ fontSize: "10", fontWeight: 700, marginBottom: -15 }}
            >
              Factor Summary
            </Text>
            <Table
              data={systemFactorTemplate}
              headerColor={config.tableBorderColor}
              isSportLaw={isSportLaw}
            />
          </View>
        </View>

        {outcomesData && outcomesQuestions && (
          <>
            <Text style={[styles.title, { color: config.titleColor }]}>
              Outcomes Overview
            </Text>
            <Text style={styles.description}>
              The table below contains summary metrics on all&nbsp;
              <Text style={{ fontWeight: 500 }}>critical outcomes</Text>
              &nbsp;within the Culture Index, which are typically impacted by
              culture.
            </Text>
            <View style={styles.outcomesSummary}>
              <Text
                style={{ fontSize: "10", fontWeight: 700, marginBottom: -15 }}
              >
                Outcomes Summary
              </Text>
              <OutcomesTable
                data={getOutcomesTable(outcomesData, outcomesQuestions)}
                headerColor={config.tableBorderColor}
              />
            </View>
          </>
        )}
      </View>
    </Page>
  );
};;

export default Overview;
