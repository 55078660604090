import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Page } from "@react-pdf/renderer";
import Table from "../components/Table";
import OutcomesChart from "../components/OutcomesChart";
import Footer from "../components/Footer";
import Side from "../components/Side";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
    color: "#2C3644",
  },
  p: {
    fontSize: 11,
    lineHeight: 1.7,
    marginBottom: 10,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 15,
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#F0F5FE",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    marginBottom: 5,
  },
  question: {
    fontSize: 9.5,
    lineHeight: 1.7,
  },
  S1: {
    fontSize: 10,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #2C6BAA",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C6BAA",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
  },
  legendText: {
    fontSize: 9,
    lineHeight: 1.7,
    marginTop: -6,
    marginLeft: 13,
  },
  legendWrapper: {
    flexDirection: "row",
    display: "flex",
  },
  legend: {
    width: 10,
    lineHeight: 1.9,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    borderBottomStyle: "dotted",
  },
  themeSection: {
    // marginTop: 20,
    // marginBottom: 20,
  },
  themeTitle: {
    fontSize: 10,
    fontWeight: 700,
    color: "#2C3644",
    // marginBottom: 10,
  },
  themeWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    // lineHeight: 1.7,
  },
  themeText: {
    marginBottom: 5,
  },
  boldedTheme: {
    fontWeight: 700,
  },
});

const getOutcomeQuestionAverage = (data, i) => {
  const outcomeQuestionAverage =
    Number(data?.questionData?.[i]?.overallQuestionAverage) * 10;
  return outcomeQuestionAverage;
};

const Breakdown = (props) => {
  const { outcome, isSportLaw, data, config } = props;

  const tableTemplate = !isSportLaw
    ? [
        { title: "Question", width: "68%" },
        { title: "Score", width: "10%" },
        { title: "Norm", width: "10%" },
        { title: "Change", width: "12%" },
      ]
    : [
        { title: "Question", width: "70%" },
        { title: "Score", width: "10%" },
        { title: "Norm", width: "10%" },
        { title: "Change", width: "10%" },
      ];

  if (!data) {
    return null;
  }

  const overviewTableData = outcome?.questions?.map((item, i) => {
    const average = getOutcomeQuestionAverage(data[data?.length - 1], i);

    if (!isSportLaw) {
      return [`${item.question}`, `${average}%`, "--", "--"];
    } else {
      return [`${item.question}`, `${average}%`, "--", "--"];
    }
  });

  const overviewTable = {
    rows: tableTemplate,
    data: overviewTableData,
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.T2}>{outcome?.name} Analysis</Text>
      <View style={styles.line} />
      {/* <Text style={styles.description}>
        Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
        tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
        swine, pork chop porchetta picanha spare ribs. Chicken jerky short ribs,
        pastrami pork fatback ground round spare ribs cow t-bone filet mignon
        hamburger leberkas shank. Meatball burgdoggen pork ribeye.
      </Text> */}

      <Table
        data={overviewTable}
        headerColor="#2C3644"
        hideReverseText={false}
      />

      <Text style={[styles.title, { color: config.titleColor }]}>
        Question Analysis
      </Text>

      {outcome?.questions?.map((item, i) => {
        const adjustPadding = i === 0;
        return (
          <View wrap={false} style={[adjustPadding && { marginBottom: 150 }]}>
            <View style={styles.section}>
              <Text style={styles.question}>{outcome?.name} Question</Text>
              <Text style={styles.subTitle}>
                {i + 1}. {item.question}
              </Text>
            </View>
            <View style={styles.offset}>
              <OutcomesChart
                question={item}
                data={data?.[data?.length - 1]?.questionData?.[i]}
                barColor={config.barColor}
                questionId={i}
                isSportLaw={isSportLaw}
              />
            </View>
          </View>
        );
      })}

      <Footer {...config} />
      <Side {...config} isSportLaw={isSportLaw} />
    </Page>
  );
};

export default Breakdown;
