

export const calculateHeatmap = (core_data,filtered_data,anchor,outcomeQ,outcomeStandards) => {



    // Gather the anchor options
    const anchor_options = core_data?.categories[0]?.categories.find(f=>f.id == anchor).options
    const data = anchor_options?.map((option)=>{
        let culture_scores = get_culture_scores(option,filtered_data,anchor,core_data.questions)
        let outcome_scores = get_outcome_scores(option,filtered_data,anchor,outcomeQ)
        let EQ_scores  = get_EQ_scores(option,filtered_data,anchor)
        let EQ360_scores = get_EQ360_scores(option,filtered_data,anchor)
        
        return {
            option:option.name,
            culture_scores:culture_scores,
            outcome_scores:outcome_scores,
            EQ_scores:EQ_scores,
            EQ360_scores:EQ360_scores,
            responses:filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id).length
        }
    }) 
    
    return data
}


const get_culture_scores = (option,filtered_data,anchor,questions) => {

    let anchor_data = filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)
    let overall_scores = []

    // Get the overall culture scores
    anchor_data.forEach((data)=>{
       overall_scores.push(data.questions.map((question)=>question.response))
    })


    // Get the factor scores
    let factor_scores = {}
    let item_scores = {}


    questions?.dimensions.forEach((dimension)=>{
        dimension.factors.forEach((factor)=>{
            let scores = []
                const dimension_id = dimension.id -1
                const factor_id = factor.id -1
                anchor_data.forEach((data)=>{
                    scores.push(data.questions.filter(i=>i.id==dimension_id && i.factor == factor_id).map(r=>r.response))
                })
                const key = JSON.stringify([dimension_id,factor_id]);
                factor_scores[key]= {scores:average_array(scores.flat()),factor:factor.title}

            factor.questions.forEach((question,q_id)=>{
                let item_scores_raw =  anchor_data.map((data)=>{
                    return data.questions.find(i=>i.id==dimension_id && i.factor == factor_id && i.q == q_id)?.response
                })
                const item_key = JSON.stringify([dimension_id,factor_id,q_id])
                item_scores[item_key] = {scores:average_array(item_scores_raw),question:question.q,factor_name:factor.title,dimension_name:dimension.title}

            })
        })
    })

    return {
        overall_scores:average_array(overall_scores.flat()),
        factor_scores:factor_scores,
        item_scores:item_scores
    }
}

const average_array = (arr) => {
    return arr.reduce((a,b)=>a+b,0)/arr.length
}


const get_outcome_scores = (option,filtered_data,anchor,outcomeQ) => {
    let outcome_scores = {}
    let question_scores = {}
    let anchor_data = filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)

    outcomeQ?.questions.forEach((question)=>{
        let name = question.name

        let filtered_responses = anchor_data.map((data)=>data?.employee_outcomes?.responses?.filter(i=>i.q == question.id).map((i)=>i?.response)).flat()
        let scores = average_array(filtered_responses)
        outcome_scores[name] = scores

        
        question.questions.forEach((q)=>{
            let filtered_responses = anchor_data.map((data)=>data?.employee_outcomes?.responses?.filter(i=>i.q == question.id && i.s == q.s).map((i)=>i?.response)).flat()
            let scores = average_array(filtered_responses)
            const item_key = JSON.stringify([q.q,q.s])
            question_scores[item_key] = {scores,question:q.question,outcome:name}
        })

    })

    return {
        overall_scores:outcome_scores,
        question_scores:question_scores
    }

}

const aggregate_vertical_scores = (data) => {
    let outcomes = Object.values(data.outcome_scores.overall_scores)
    if(outcomes.includes(NaN)){
        return data.culture_scores.overall_scores
    }
     return data.culture_scores.overall_scores + outcomes.reduce((a,b)=>a+b,0)
}

export const vertical_sorted_names = (data) => {
    const sorted_names = data.sort((a,b)=>aggregate_vertical_scores(a) - aggregate_vertical_scores(b) ).map((item)=>item.option)
    return sorted_names
}


export const horizontal_sorted_names = (data) => {
    const total_culture_scores = data.map((item)=>item.culture_scores.overall_scores*item.responses).reduce((a,b)=>a+b,0)
    const total_responses = data.reduce((a,b)=>a+b.responses,0)


    if(data.length == 0){
        const sorted_names = [{name:"Culture",score:total_culture_scores/total_responses}]
        return sorted_names.sort((a,b)=>b.score-a.score)

    }


    const outcome_names = Object.keys(data[0].outcome_scores.overall_scores)
    
    let overall_outcome_scores = outcome_names.map((name)=>{
        let local_responses = 0
        let score = data.map((item)=>{
            if(item.outcome_scores.overall_scores[name]){
                local_responses += item.responses
            }
            return item.outcome_scores.overall_scores[name] * item.responses})
        let average_score = score ? score.reduce((a,b)=>b? a+b : a,0)/local_responses : null
        return {name,score:average_score}
    })

    if(Object.keys(data[0].EQ_scores).length>0){
        let overall_EQ = []
        let overall_EQ360 = []
        data.forEach((item)=>{
            overall_EQ.push(item.EQ_scores.Overall)
            overall_EQ360.push(item.EQ360_scores.Overall)
        })
    
        overall_outcome_scores.push({name:'Emotional Intelligence',score:average_array(overall_EQ)})
        overall_outcome_scores.push({name:'Emotional Intelligence 360',score:average_array(overall_EQ360)})
    }


    const sorted_names = [...overall_outcome_scores,{name:"Culture",score:total_culture_scores/total_responses}]
    return sorted_names.sort((a,b)=>b.score-a.score)
}

export const factorTitleSorted = (data) => {
    let factor_scores = []

    if(data.length == 0){
        return []
    }

    const factor_titles = Object.values(data[0].culture_scores.factor_scores).map((item)=>item.factor)
    factor_titles.forEach((title)=>{
        factor_scores.push({scores:average_array(data.map((item)=>Object.values(item.culture_scores.factor_scores).find(i=>i.factor == title).scores)),name:title})
    })

    return factor_scores.sort((a,b)=>a.scores-b.scores)

}

export const addEQ = (data) => {
    const factors = [
        "Experiential EQ",
        "Strategic EQ",
        "Self Awareness",
        "Self Management",
        "Empathy",
        "Social Awareness",
        "Relationship Management",
        "Self-Regard",
        "Self-Actualization",
        "Reality Testing",
        "Impulse Control",
        "Optimism",
        "Social Responsibility",
        "Emotional Expression",
        "Independence",
        "Emotional Self-Awareness",
        "Assertiveness",
        "Emotional Empathy",
        "Cognitive Empathy",
        "Interpersonal Relationships",
        "Stress Tolerance",
        "Flexibility"
      ]

    const EQ360 = [
    "Empathy Score",
    "Experiential EQ",
    "Overall Score",
    "Relationship Management",
    "Self-Awareness",
    "Self-Management",
    "Social Awareness",
    "Strategic EQ"
    ]
      

    data[0].map((item=>{
        if(item?.employee_outcomes?.responses){
            item['EQ'] = []
       
            const score = item.employee_outcomes?.responses[1].response
            const correlation = 0.8;
            const stdDevX = 2.89
     
              factors.forEach((factor)=>{
                 const randomError = Math.random() * Math.sqrt(1 - Math.pow(correlation, 2)) * stdDevX;
                 const newScore = correlation * score + randomError;                   
                   item['EQ'].push({name:factor,score:newScore})
              })

              item['EQ'].push({name:'Overall',score:average_array(item['EQ'].map(i=>i.score))})

              item['EQ360'] = []
              const correlation2 = 0.7;
              const stdDevX2 = 3.5

                EQ360.forEach((factor)=>{
                    const randomError = Math.random() * Math.sqrt(1 - Math.pow(correlation, 2)) * stdDevX2;
                    const newScore = correlation2 * score + randomError;
                      
                      item['EQ360'].push({name:factor,score:newScore})
                 })

                    item['EQ360'].push({name:'Overall',score:average_array(item['EQ360'].map(i=>i.score))})

              return item

        }
      
    }))
    return data
}

const get_EQ_scores = (option,filtered_data,anchor) => {
    let anchor_data = filtered_data[0].filter(f=>f.EQ).filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)
    let EQ_scores = {}

    let factors = anchor_data[0]?.EQ.map((data)=>data.name)

    factors?.forEach((factor)=>{
        let scores = []
        anchor_data.forEach((data)=>{
            scores.push(data.EQ.find(i=>i.name==factor).score)
        })
        console.log(average_array(scores)) 
        EQ_scores[factor] = average_array(scores)
    }
    )

    return EQ_scores
}

const get_EQ360_scores = (option,filtered_data,anchor) => {
    let anchor_data = filtered_data[0].filter(f=>f.EQ360).filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)
    let EQ_scores = {}

    let factors = anchor_data[0]?.EQ360.map((data)=>data?.name)

    factors?.forEach((factor)=>{
        let scores = []
        anchor_data.forEach((data)=>{
            scores.push(data.EQ360.find(i=>i.name==factor).score)
        })
        EQ_scores[factor] = average_array(scores)
    })



    return EQ_scores
}
