import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Query from "./Query";
import AwaitingQuery from "./AwaitingQuery";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SAGA_CONNECT_WEBSOCKET } from "constants/actions";
import styled, { keyframes } from "styled-components";
import { Dimmer } from "semantic-ui-react";

import LogAnimation from "./LogAnimation";
import { StarterQuestions } from "data/live/StarterQuestions";
import { elipseDot } from "./Icons";
import IntroScreen from './_IntroScreen'

export default function ChatWindow(props) {
  const {
    selectedChatRoom,
    setSelectedChatRoom,
    askRecommendation,
    chatHistory,
    questionHistory,
    setNewChannel,
    newChannel,
    query,
    text,
    setLoading: setChatLoading,
    setPendingChatId,
    pendingChatId,
    hide,
    feedback_length,
    raw_feedback,
    params,
    context_length,
    outcomeQ
  } = props;
  const messagesEndRef = useRef(null);
  const messagesStartRef = useRef(null);
  const chatWindowRef = useRef(null);
  const isBrowser = typeof window !== `undefined`;

  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [clickedNew, setClickedNew] = useState(false);
  const [hideInitial, setHideInitial] = useState(true);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentScrollPos, setCurrentScrollPos] = useState(0);
  const [hideLastQuery, setHideLastQuery] = useState(false);
  const [currentWebChatId, setCurrentWebChatId] = useState(null);
  const [pendingQuery, setPendingQuery] = useState(null);
  const [isConnected, setIsConnected] = useState(null);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [unknownError, setUnknownError] = useState(false);

  const timeoutLength = 10000;
  const { get_web_sock, chatToken, pendingMessages } = useSelector(
    (state) => ({
      get_web_sock: state.ai_websocket,
      chatToken: state.auth.chat_token,
      pendingMessages: state.ai_websocket.pendingMessages,
    }),
    shallowEqual
  );

  function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 };

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow
      ? { x: window.scrollX, y: window.scrollY }
      : { x: position.left, y: position.top };
  }

  function useScrollPosition(effect, deps, element, useWindow, wait) {
    const position = useRef(getScrollPosition({ useWindow }));

    let throttleTimeout = null;
    const target = element ? element.current : document.body;

    const callBack = () => {
      const currPos = getScrollPosition({ element, useWindow });
      effect({
        prevPos: position.current,
        currPos,
        scrollHeight: target.scrollHeight,
        clientHeight: target.clientHeight,
      });
      position.current = currPos;
      throttleTimeout = null;
      setCurrentScrollPos(currPos.y);
    };

    useLayoutEffect(() => {
      const handleScroll = () => {
        if (wait) {
          if (throttleTimeout === null) {
            throttleTimeout = setTimeout(callBack, wait);
          }
        } else {
          callBack();
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }, deps);
  }

  useEffect(() => {
    setTimeoutReached(false);
    setIsConnected(null);
  }, [clickedNew]);

  useEffect(() => {
    if (get_web_sock?.isConnected) {
      setIsConnected(true);
    } else {
      if (typeof get_web_sock?.isConnected === "boolean" && timeoutReached) {
        setIsConnected(false);
      } else {
        setTimeout(() => {
          setTimeoutReached(true);
        }, timeoutLength);
      }
    }
  }, [get_web_sock?.isConnected]);

  const checkIfChannelHasPendingMessages = (channelId) => {
    const pendingMessage = Object?.values(get_web_sock?.message).find(
      (message) => message.web_chat_id === channelId && !message?.response
    );
    return pendingMessage;
  };

  const getNewMessage = (ws) => {
    let likelyMessage;
    Object?.values(ws?.message)?.forEach((message) => {
      if (!message?.web_chat_id) {
        likelyMessage = message?.message;
      }
    });

    return likelyMessage;
  };

  useScrollPosition(
    ({ prevPos, currPos, scrollHeight, clientHeight }) => {
      const scrollButtonPos = scrollHeight - clientHeight + currPos.y;
      if (scrollButtonPos > 180) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    },
    [currentScrollPos]
  );

  useEffect(() => {
    setHistoryLoading(true);
    messagesStartRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    setMessages(chatHistory?.interaction_history);
    setHideInitial(true);
    setHideLastQuery(false);
    setTimeout(() => {
      setHistoryLoading(false);
    }, 500);
    setCurrentWebChatId(chatHistory?.interaction_history?.[0]?.web_chat_id);
    if (chatHistory?.interaction_history?.length > 0) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        setShowScrollButton(false);
      }, 1000);
    } else {
      setTimeout(() => {
        messagesStartRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 600);
    }
    setTimeout(() => {
      setHideInitial(false);
    }, 1500);
  }, [chatHistory]);

  useEffect(() => {
    if (get_web_sock?.channel?.id && !selectedChatRoom?.id) {
      setSelectedChatRoom({
        id: get_web_sock?.data?.web_chat_id,
        name: get_web_sock?.data?.name,
        params: params,
      });
      setPendingChatId(null);
    }
  }, [get_web_sock.data, setSelectedChatRoom]);

  useEffect(() => {
    // If one of the objects within message has a key of undefined & there is an error,
    // set the unknown error to the error message.
    if (
      get_web_sock?.message &&
      Object.values(get_web_sock?.message)?.length > 0
    ) {
      Object.values(get_web_sock?.message)?.forEach((item, key) => {
        // If the object key is undefined, and there is an error, set the unknown error to the error message.
        if (item?.error && !item?.server?.response) {
          setUnknownError(item?.error);
        }
      });
    }

    // If the message is an update, dont add it to this list.
    if (get_web_sock?.data?.type === "update") {
      return;
    }

    if (
      !get_web_sock?.data?.web_chat_id ||
      get_web_sock?.data?.web_chat_id === selectedChatRoom?.id ||
      (get_web_sock?.data?.web_chat_id &&
        isNaN(get_web_sock?.data?.web_chat_id)) ||
      get_web_sock?.channel?.id
    ) {
      let messagesHistory = [];
      let messagesObj = {};
      setHideInitial(true);
      if (
        chatHistory?.interaction_history &&
        Array.isArray(chatHistory?.interaction_history)
      ) {
        messagesHistory = [...chatHistory?.interaction_history];
        messagesHistory.forEach((item) => {
          messagesObj[item.id] = item;
        });
      }
      messagesHistory.push(get_web_sock.message);
      Object.keys(get_web_sock.message).forEach((key) => {
        if (messagesObj?.[key] && messagesObj?.[key]?.server?.response) {
          // Skip
        } else {
          messagesObj[key] = get_web_sock.message[key];
        }
      });
      setHideLastQuery(true);
      setMessages(messagesObj);
      setTimeout(() => {
        setShowScrollButton(false);
        messagesEndRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);

      setTimeout(() => {
        setHideInitial(false);
      }, 1000);
    }
  }, [get_web_sock.message]);

  useEffect(() => {
    if (get_web_sock.ws) {
      setLoading(get_web_sock.pending);
      setChatLoading(get_web_sock.pending);
    } else {
      setLoading(true);
    }
  }, [get_web_sock]);

  useEffect(() => {
    if (!get_web_sock.ws && selectedChatRoom?.id) {
      dispatch({
        type: SAGA_CONNECT_WEBSOCKET,
        payload: {
          token: chatToken,
          // token,
          name: selectedChatRoom?.name,
          web_chat_id: selectedChatRoom?.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [dispatch, get_web_sock, selectedChatRoom]);

  useEffect(() => {
    if (newChannel > 0) {
      if (get_web_sock?.channel?.id && get_web_sock?.channel?.name) {
        dispatch({
          type: SAGA_CONNECT_WEBSOCKET,
          payload: {
            token: chatToken,
            // token,
            name: get_web_sock?.channel?.name,
            web_chat_id: get_web_sock?.channel?.id,
          },
        });
        setNewChannel(0);
      }
    }
  }, [dispatch, get_web_sock, newChannel, setNewChannel, chatToken]);

  useEffect(() => {
    if (!pendingChatId && !messages && !selectedChatRoom?.id > 0) {
      setMessages(null);
      setClickedNew(false);
    }
  }, [pendingChatId, messages, selectedChatRoom?.id]);

  function ClickedStarter(e) {
    askRecommendation(e);
    setClickedNew(true);
  }

  useEffect(() => {
    if (
      getPendingMessage(get_web_sock) &&
      getPendingMessage(get_web_sock)?.message
    ) {
      setPendingQuery(getPendingMessage(get_web_sock));
    }
  }, [pendingQuery]);

  // Find the pending message that doesn't have a response
  const getPendingMessage = (ws) => {
    const webChatId = ws.channel.id;
    // Look through ws messages and find the one that matches the webChatId, and has no response.
    let pendingMessage;
    for (const key in ws.message) {
      if (
        ws.message[key].web_chat_id === webChatId &&
        !ws.message[key]?.server?.response &&
        !ws.message[key]?.error
      ) {
        pendingMessage = ws.message[key];
      }
    }
    return pendingMessage;
  };

  if (historyLoading) {
    return (
      <ChatContainer>
        <div
          style={{ float: "left", clear: "both", height: 10 }}
          ref={messagesStartRef}
        ></div>
        <LogAnimation />
      </ChatContainer>
    );
  }

  return (
    <ChatContainer hide={!selectedChatRoom} ref={chatWindowRef}>
      {/* Hide the most recent question since it will be
       * displayed from renderQuery.
       */}
      <>
        {unknownError && (
          <ErrorHeader>
            An error has occured, please refresh the page and try again. Error:
            {unknownError}
          </ErrorHeader>
        )}
        {messages &&
          Array.isArray(messages) &&
          messages.length === 0 &&
          !hideInitial && (
            <ErrorHeader>
              An error has occurred and your question could not be answered,
              please try asking the question again.
            </ErrorHeader>
          )}
        {/* If we are returning to a new chat, and there are no messages, or a message is still pending */}
        {(messages &&
          ((typeof messages === "object" &&
            Object.values(messages)?.length === 0) ||
            messages?.[0]?.web_chat_id !== selectedChatRoom?.id) &&
          get_web_sock?.isConnected &&
          getNewMessage(get_web_sock) && (
            <div>
              <Query
                query={getNewMessage(get_web_sock)}
                showScrollButton={showScrollButton}
                setShowScrollButton={setShowScrollButton}
              />
              <AwaitingQuery context_length={context_length} />
            </div>
          )) ||
          (messages &&
            typeof messages === "object" &&
            Object.values(messages)?.length === 0 &&
            get_web_sock?.isConnected &&
            !getNewMessage(get_web_sock) && (
              <ErrorHeader>
                An error has occurred and your conversation could not be loaded,
                please wait a few moments and try again.
              </ErrorHeader>
            ))}
        {/* (messages?.length > 0 && !loading) || selectedChatRoom) && */}
        {(!messages && !selectedChatRoom?.id > 0) ||
        (!pendingChatId && !messages)
          ? !clickedNew && (
              <ParentContainer>
                <EmptyChatContainer>
                  <IntroScreen
                    feedback_length={feedback_length}
                    raw_feedback={raw_feedback}
                    outcomeQ={outcomeQ}
                  />
                  <QuestionSuggestions>
                    <ChatHeading>What to ask?</ChatHeading>
                    <Description>
                      If you aren’t sure where to start, here are some easy ice
                      breakers to get you started.
                    </Description>
                  </QuestionSuggestions>
                  {/* starter questions */}
                  <Samples enabled={feedback_length > 0}>
                    {StarterQuestions?.map((sq, i) => {
                      return (
                        <Sample
                          key={i}
                          onClick={() => {
                            if (feedback_length > 0) {
                              ClickedStarter(sq?.prompt);
                            }
                          }}
                          delay={i * 0.1}
                          enabled={feedback_length > 0}
                        >
                          {/* <DotWrapper>{elipseDot()}</DotWrapper> */}
                          <SampleTitle>{sq?.title}</SampleTitle>
                          <SampleText> {sq?.ques}</SampleText>
                        </Sample>
                      );
                    })}
                  </Samples>
                  {/* <NoQuestions>
                  <ChatHeading>No questions yet</ChatHeading>
                  <Description>
                    Ask your first query to see the result
                  </Description>
                </NoQuestions> */}
                </EmptyChatContainer>
              </ParentContainer>
            )
          : messages &&
            Object.values(messages).map((item, index) => {
              // pending questions don't have a web_chat_id, so we will check the pendingMessages array
              if (
                !item?.message ||
                (item?.web_chat_id &&
                  selectedChatRoom?.id !== item?.web_chat_id &&
                  !isNaN(item?.web_chat_id))
              ) {
                if (item === null && !hideInitial) {
                  return (
                    <ErrorHeader>
                      A critical error has occurred in this conversation, please
                      start a new conversation to continue.
                    </ErrorHeader>
                  );
                }
                return null;
              }

              // if the web_chat_id is not the last one, and it is not a number, dont show it
              if (
                index !== Object.values(messages).length - 1 &&
                isNaN(item?.web_chat_id)
              ) {
                return null;
              }

              // if the question is still pending, get the question from get_web_sock instead
              if (
                !item?.web_chat_id &&
                get_web_sock?.isConnected &&
                hideInitial &&
                !clickedNew
              ) {
                // if this is not the last message.
                return null;
              }

              return (
                <div key={index}>
                  {typeof isConnected === "boolean" &&
                    !isConnected &&
                    !hideInitial && (
                      <ErrorHeader>
                        There has been an error in the connection, please
                        refresh the page to reconnect
                      </ErrorHeader>
                    )}
                  <Query
                    query={item?.message}
                    showScrollButton={showScrollButton}
                    setShowScrollButton={setShowScrollButton}
                  />
                  {item?.server?.response ||
                  item?.error ||
                  item?.server?.error ? (
                    <Query
                      showScrollButton={showScrollButton}
                      setShowScrollButton={setShowScrollButton}
                      webSocketConnected={get_web_sock?.isConnected}
                      query={item?.server?.response}
                      references={item?.server?.references}
                      type={"response"}
                      error={
                        !item?.server?.response &&
                        (item?.error || item?.server?.error || item?.a === "")
                      }
                    />
                  ) : (
                    (get_web_sock?.isConnected || isConnected === null) && (
                      <AwaitingQuery context_length={context_length} />
                    )
                  )}
                </div>
              );
            })}
        {(get_web_sock?.isConnected &&
          get_web_sock?.data?.web_chat_id === selectedChatRoom?.id &&
          get_web_sock?.data?.message &&
          !get_web_sock?.data?.server?.response?.length > 0 &&
          Array.isArray(messages) &&
          messages?.length > 0 && (
            <div>
              <Query
                query={get_web_sock?.data?.message}
                showScrollButton={showScrollButton}
                setShowScrollButton={setShowScrollButton}
              />
              <AwaitingQuery context_length={context_length} />
            </div>
          )) ||
          (getPendingMessage(get_web_sock) && (
            <div>
              <Query
                query={getPendingMessage(get_web_sock)?.message}
                showScrollButton={showScrollButton}
                setShowScrollButton={setShowScrollButton}
              />
              <AwaitingQuery context_length={context_length} />
            </div>
          ))}
      </>
      {showScrollButton && !hideInitial && (
        <ScrollButton
          onClick={() => {
            messagesEndRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
            setShowScrollButton(false);
          }}
        >
          Scroll to bottom
        </ScrollButton>
      )}
      <div
        style={{ float: "left", clear: "both", height: 50 }}
        ref={messagesEndRef}
      ></div>
    </ChatContainer>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ScrollButton = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 120px;
  right: 20px;
  background-color: #2d70e2;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  z-index: 100;
`;

const ChatContainer = styled.div`
  min-height: 500px;
  overflow-x: hidden;
  transition: transform 0.3s ease;
  margin-top:${props=>props.hide?'20px':'50px'};
  padding-top:${props=>props.hide?'0px':'50px'};

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    margin-top: 10px;
    margin-bottom: 35px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.03);
  }

  width: 100%;
  padding-bottom: 100px;
  display:flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1260px) {
    min-width: 900px;
  }
`;

const MessageContent = styled.div`
  display: flex;
  background-color: ${(props) => (props.isuser ? "#eaf6ff" : "#f4f4f4")};
  justify-content: ${(props) => (props.isuser ? "flex-start" : "flex-end")};
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const MessageError = styled.strong`
  margin-right: 5px;
  font-weight: 600;
  color: red;
`;

const ErrorHeader = styled.div`
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
  color: red;
`;

const ParentContainer = styled.div`
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
`

const EmptyChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0px;

  max-width:800px;
`;

const QuestionSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  margin-bottom: 24px;
`;

const ChatHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: 'Raleway';
  color: #2A3039;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 8px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #2A3039;
  font-family: Raleway;
  line-height: 140%;
`;

const Sample = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  line-height: 1.4;
  padding: 16px;

  width: calc(26.5vw - 30px);

  @media screen and (min-width: 1563px) {
    width: 40%;
  }

  @media screen and (min-width: 1630px) {
    width: 40%;
  }

  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
  cursor: ${({ enabled }) =>
    enabled ? "pointer" : "not-allowed"}; // 2. Disable pointer events

  // 3. Adjust delay for each sample
  animation-delay: ${({ delay }) => delay}s;
`;

const SampleTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const SampleText = styled.div`
  font-size: 12px;
`

const Samples = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)}; // 1. Adjust opacity
`;

const NoQuestions = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  margin-top: 20px;
`;

const DotWrapper = styled.div`
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 6px;
  display: flex;
`;
