import Settings from "assets/images/nav/settings.svg";
import Home from "assets/images/nav/home.svg";
import Management from "assets/images/nav/management.svg";
import L360 from "assets/images/nav/360.svg";
import Report from "assets/images/nav/report.svg";
import PDFIcon from "assets/images/nav/pdf-regular-white.svg";

import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Personality from "assets/images/nav/personality.svg";

import Leadership from "assets/images/nav/leadership.svg";
import Admin from "assets/images/nav/admin.svg";
import MyMood from "assets/images/group-12.svg";
import Survey from "assets/images/nav/survey.svg";

import Data from "assets/images/nav/data.svg";

import Calendar from "assets/images/nav/calendar.png";
import Action from "assets/images/nav/action.png";

import {
  TeamIcon,
  reportIcon,
  actionIcon,
  cultureIcon,
  calendarIcon,
  dataScienceIcon,
  groupIcon,
  recommendationIcon,
  outcomesIcon,
  aiIcon,
  dashboardIcon,
} from "./Icons";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/core/management",
    title: "Team Management",
    image: Management,
    role: 7,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
];
export const OtpNav = [];

export const NavData = [
  {
    header: "teamTool",
    role: 11,
    paid: false,
    team: false,
    group: "Teamtool",
  },
  {
    icon: "home",
    link: "/app/team/dashboard",
    title: "Home",
    image: Home,
    role: 11,
    paid: false,
    team: false,
    group: "Teamtool",
  },
  {
    icon: "heartbeat",
    image: Culture,
    link: "/app/team/team",
    title: "Team Health",
    role: 9,
    team: true,
    paid: true,
    hideForAmbassador: true,
    group: "Teamtool",
  },
  {
    icon: "discussions",
    link: "/app/team/eq",
    title: "Team Mood",
    image: Mood,
    role: 9,
    team: true,
    paid: true,
    group: "Teamtool",
    hideForAmbassador: true,
    subNav: [
      {
        icon: "chart bar outline",
        link: "/app/team/systems",
        title: "Mood Analytics",
        role: 1,
      },
    ],
  },
  {
    icon: "discussions",
    link: "/app/team/personal",
    title: "My Data",
    image: Report,
    role: 11,
    paid: true,
    team: true,
    group: "Teamtool",
  },
  {
    icon: "dna",
    link: "/app/team/personality-dash",
    title: "Personality",
    image: Personality,
    role: 9,
    team: false,
    paid: true,
    personality: true,
    group: "Teamtool",
  },
  {
    icon: "location arrow",
    link: "/app/team/leadership",
    title: "Leadership",
    image: L360,
    role: 5,
    team: false,
    paid: true,
    leadership: true,
    group: "Teamtool",
  },
  {
    icon: "code branch",
    link: "/app/team/teams",
    title: "Team Analytics",
    image: Data,
    role: 6,
    team: false,
    paid: true,
    group: "Teamtool",
  },
  {
    icon: "address book",
    link: "/app/team/debriefs",
    title: "Team Debriefs",
    image: Report,
    role: 9,
    paid: true,
    team: false,
    group: "Teamtool",
  },
  {
    icon: "address book",
    link: "/app/team/survey_settings",
    title: "Survey Settings",
    image: Settings,
    role: 5,
    admin: true,
    paid: true,
    team: false,
    group: "Teamtool",
  },
  {
    header: "Culture",
    role: 9,
    paid: false,
    team: false,
    name: "Audit",
    group: "Audit",
  },
  {
    // icon: "home",
    link: "/app/audit/dashboard",
    title: "Dashboard",
    image: Calendar,
    svg: dashboardIcon,
    role: 9,
    paid: false,
    team: false,
    name: "Audit",
    group: "Audit",
    employee_permission:true,
    innerlogic_only:true,
    no_subfactor:true,
  },
  {
    // icon: "home",
    link: "/app/audit/calendar",
    title: "Calendar",
    image: Calendar,
    svg: calendarIcon,
    role: 9,
    paid: false,
    team: false,
    name: "Audit",
    group: "Audit",
  },
  {
    icon: "address book",
    link: "/app/audit/report",
    title: "Culture Analytics",
    image: Report,
    svg: cultureIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
  },
  {
    icon: "address book",
    link: "/app/audit/engagement",
    title: "Engagement Analytics",
    image: Report,
    svg: groupIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only:true,
    no_subfactor:true,
    employee_permission:true,
  },
  {
    icon: "address book",
    link: "/app/audit/AI",
    title: "Culture Copilot",
    image: Report,
    svg: aiIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    require_feedback_access:true,
    name: "AI",
    group: "Audit",
  },
  // {
  //   icon: "address book",
  //   link: "/app/audit/rec",
  //   title: "Recommendations",
  //   image: Report,
  //   svg: recommendationIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   require_feedback_access:true,
  //   name: "Audit",
  //   group: "Audit",
  //   employee_permission:true,
  //   innerlogic_only:true,
  // },
  {
    icon: "dollar",
    link: "/app/audit/outcomes",
    title: "Outcomes",
    svg: outcomesIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only:true,
    no_subfactor:true,
  },
  // {
  //   icon: "dollar",
  //   link: "/app/audit/simple",
  //   title: "Outcomes",
  //   svg: outcomesIcon,
  //   prototype: true,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit",
  //   innerlogic_only:true,
  //   no_subfactor:true,
  // },
  // {
  //   icon: "dollar",
  //   link: "/app/audit/group",
  //   title: "Group Analytics",
  //   svg: groupIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit",
  // },
  // {
  //   icon: "dollar",
  //   link: "/app/audit/data_science",
  //   title: "Data Science",
  //   svg: dataScienceIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit"
  // },
  // {
  //   icon: "address book",
  //   link: "/app/audit/action",
  //   title: "Action Plan",
  //   image: Action,
  //   svg: actionIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   name: "Audit",
  //   group: "Audit",
  // },
  {
    icon: "address book",
    link: "/app/audit/pdf",
    title: "PDF Report",
    image: PDFIcon,
    svg: reportIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    employee_permission:true,
  },

  {
    // icon: "home",
    link: "/app/otp",
    title: "Calendar",
    fr: "Calendrier",
    image: Calendar,
    svg: calendarIcon,
    role: 9,
    paid: false,
    team: false,
    name: "OTP",
    group: "OTP",
  },
  {
    icon: "address book",
    link: "/app/otp/report",
    title: "Culture Analytics",
    fr: "Analyse de la culture",
    image: Report,
    svg: cultureIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "OTP",
    group: "OTP",
  },
  {
    icon: "address book",
    link: "/app/otp/action",
    title: "Action Plan",
    fr: "Plan d'action",
    image: Action,
    svg: actionIcon,
    role: 9,
    paid: true,
    team: false,
    name: "OTP",
    group: "OTP",
  },
  {
    icon: "address book",
    link: "/app/otp/pdf",
    title: "PDF Report",
    fr: "Rapport PDF",
    image: PDFIcon,
    svg: reportIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_pdf: true,
    name: "OTP",
    group: "OTP",
  },
  {
    header: "DEI",
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  {
    icon: "address book",
    link: "/app/edi",
    title: "Calendar",
    image: Calendar,
    svg: calendarIcon,
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  {
    icon: "address book",
    link: "/app/edi/report",
    title: "DE&I Report",
    svg: cultureIcon,
    image: L360,
    role: 9,
    paid: true,
    team: false,
    name: "EDI",
    group: "EDI",
  },
  // {
  //   icon: "address book",
  //   link: "/app/edi/recommendations",
  //   title: "Recommendations",
  //   image: Report,
  //   svg: recommendationIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  {
    icon: "address book",
    link: "/app/edi/AI",
    title: "DEI Copilot",
    image: Report,
    svg: aiIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "AI",
    group: "EDI",
  },
  {
    icon: "dollar",
    link: "/app/edi/outcomes",
    title: "Outcomes",
    svg: outcomesIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "EDI",
  },
  {
    icon: "dollar",
    link: "/app/edi/group",
    title: "Group Analytics",
    svg: groupIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "EDI",
    group: "EDI",
    demo: false,
  },
  {
    icon: "dollar",
    link: "/app/edi/data_science",
    title: "Data Science",
    svg: dataScienceIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: false,
    name: "EDI",
    group: "EDI",
    demo: true,
  },
  // {
  //   icon: "address book",
  //   link: "/app/edi/action",
  //   title: "Action Plan",
  //   image: Action,
  //   svg: actionIcon,
  //   role: 9,
  //   paid: false,
  //   team: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  // {
  //   icon: "address book",
  //   link: "/app/edi/pdf",
  //   title: "PDF Report",
  //   image: PDFIcon,
  //   svg: reportIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   name: "EDI",
  //   group: "EDI",
  // },
  // common links
  {
    header: "Settings",
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
  {
    icon: "address book",
    link: "/core/members/list",
    title: "Team Management",
    fr: "Gestion de l'équipe",
    image: Management,
    svg: TeamIcon,
    border: true,
    role: 6,
    paid: true,
    team: false,
    name: null,
    require_add_users: true,
    group: "Common",
  },
];

export default NavData;
