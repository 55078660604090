import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Radio, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_CREATE_DEBRIEF_PDF_REPORT } from "constants/actions";
import { v4 as uuidv4 } from "uuid";

const Permissions = ({
  primaries,
  categories,
  parentCategory,
  get_auth,
  anchorName,
  setPdfOptions,
  pdfOptions,
  orgName,
  goBack,
  templateId,
  nomenclature,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    showWarning: false,
  });
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const { get_auth_employee, core_data } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  const createReport = () => {
    const uuid = uuidv4();
    const report = {
      // TODO: Pass this value as a prop.
      template_id: templateId,
      organization: Number(get_auth?.organization_id),
      employee: Number(get_auth?.employee_id),
      tracking_code: uuid,
      params: {
        parent_id: parentCategory,
        parent_children: categories,
        primary_list: primaries,
        pdfOptions: options,
        includeWarning: options.includeWarning,
        orgName: orgName,
      },
    };

    dispatch({
      type: SG_CREATE_DEBRIEF_PDF_REPORT,
      payload: report,
    });

    if (nomenclature === "Story") {
      navigate("/leader/audit/reports/stories");
    } else {
      navigate("/leader/audit/reports");
    }
  };

  return (
    <Container>
      <BackButton onClick={() => goBack()}>
        <Icon name="chevron left" style={{ marginTop: -7 }} />
        Back
      </BackButton>
      <T1>{nomenclature || "Report"} Options</T1>
      <Description>
        Please select the permissions that apply for the{" "}
        {nomenclature?.toLowerCase() || "report"} you are requesting to be
        generated.
      </Description>
      <Card>
        <Option>
          <T2>
            Add {anchorName} warning message{" "}
            <Radio
              checked={options?.includeWarning}
              onChange={() => {
                setOptions({
                  ...options,
                  includeWarning: !options.includeWarning,
                });
                setPdfOptions({
                  ...pdfOptions,
                  includeWarning: !options.includeWarning,
                });
              }}
              value={options?.includeWarning}
              toggle
            />
          </T2>
          <Text>
            This option will toggle a warning message that indicates how to
            interpret the results.
          </Text>
        </Option>

        {/* <Option>
          <T2>
            Allow PDF Download <Radio toggle />
          </T2>
          <Text>
            This option allows anyone with access to download a PDF copy of the
            report
          </Text>
        </Option>

        <T2>Share options</T2>
        <Text>
          This option will allow the report to be shared with the members you
          have selected to share the report with.
        </Text>

        <Row>
          <Input
            placeholder="Add member..."
            style={{ width: 300, marginRight: 20 }}
          />
          <Button>Add</Button>
        </Row>

        <T3>General Access</T3>
        <Dropdown
          inline
          options={friendOptions}
          defaultValue={friendOptions[0].value}
          style={{ zIndex: 10 }}
        /> */}
      </Card>

      <ButtonContainer>
        <Button2 onClick={() => createReport()}>
          Generate {nomenclature || "Report"}
        </Button2>
      </ButtonContainer>
    </Container>
  );
};

export default Permissions;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  font-family: "Raleway";
`;
const T2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.div`
  max-width: 80%;
  line-height: 1.5;
`;

const Option = styled.div`
  margin-bottom: 20px;
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Card = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ebf1fd;
  border-radius: 10px;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;

const T3 = styled.div`
  font-size: 14px;
  font-weight: bold;

  margin-top: 20px;
`;
const Button = styled.div`
  background-color: #2a3039;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #1a2029;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;
    z-index:-1;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Button2 = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

const friendOptions = [
  {
    key: "Anyone with access to this department",
    text: "Anyone with access to this department",
    value: "Anyone with access to this department",
  },
  {
    key: "Only members who have been added",
    text: "Only members who have been added",
    value: "Only members who have been added",
  },
];
