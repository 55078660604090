import styled from "styled-components";
import React, { useState, useEffect, useRef, useMemo } from "react";
import SummaryTable from "./SummaryTable";
import BarGraph from "./BarGraph";
import Ranking from "./Ranking";
import AIDropdown from "./AIDropdown";
import template from "./template.json";
import * as html2Image from "html-to-image";
import { useToasts } from "react-toast-notifications";
import Overview from "reports/Audit/Overview";
import OverviewOptions from "../PDF/components/OverviewOptions";
import { Loader, Dimmer,Icon } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

// Actions
import {
  SG_UPDATE_PDF_SAVED_COMMENTS,
  SG_GET_PDF_SAVED_COMMENTS,
  CLEAR_CHAT_GPT,
  SET_SURVEY_VERSION,
  SG_FETCH_USER_EMPLOYEE,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_STANDARD,
  SET_QUESTIONS,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SG_FETCH_DEBRIEF_SCHEDULE
} from "constants/actions";

import {
  calculate_otp_table_data,
  filter_otp_data,
} from "../../reports/Audit/data_navigation/calculate_data";
import { SURVEY_INFO } from "constants/surveys";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

import {
  getColConfig,
  getNSOTableData,
  buildRankingTables,
  buildOverallRankingTables,
  getOptions,
  getTranslatedText,
  navigationMap,
} from "./pdfUtils";
import OTPInstance from "components/PDF/components/OTPInstance";
import { getFeedbackOTP } from "../PDF/utils/cultureUtils";
import translations from "./translations.json";
import { useLanguageSetting } from "language/redux_store";

import {
  checkPersonality,
  convert_personality_to_categories,
  check_comments,
} from "utilities/structureUtils";

import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  get_by_id,
} from "reports/Audit/data_navigation/calculate_data";

const CWFPDF = () => {
  const dispatch = useDispatch();

  const [comments, setComments] = useState([]);
  const [currentComment, setCurrentComment] = useState("");

  // AI State
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState([]);
  const [gptResponse, setGptResponse] = useState([]);

  const [page, setPage] = useState(-1);
  const [calculated, setCalculated] = useState();
  const [colConfig, setColConfig] = useState();
  const [tableData, setTableData] = useState();
  const [showControl, setShowControl] = useState(true);
  const [spiderOptions, setSpiderOptions] = useState();
  const [spiderTwoOptions, setSpiderTwoOptions] = useState();
  const [filteredData, setFilteredData] = useState();
  const [URI, setURI] = useState(null);
  const [URIReady, setURIReady] = useState(false);
  const exportRef = useRef();
  const exportRefTwo = useRef();
  const [filteredCategories, setFilteredCategories] = useState();
  const [otpView, setOtpView] = useState(false);
  const [otpDualView, setOtpDualView] = useState(false);

  const location = useLocation();
  const { addToast } = useToasts();

  const { state } = location;
  const rule = 2;
  const lang = state.isFrench ? "fr" : "en";

  const [URI_OTP, setURIOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [pdfId, setPdfId] = useState();
  const [reportObj, setReportObj] = useState();
  const [restoredState, setRestoredState] = useState();
  const [searchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [updatedCoverTitle, setUpdatedCoverTitle] = useState("");
  const [filteredAuditReports, setFilteredAuditReports] = useState([]);
  const [surveyVersion, setSurveyVersion] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();
  const [accessSubfactor, setAccessSubfactor] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [questionStructure, setQuestionStructure] = useState();
  const [sportsFiltered, setSportsFiltered] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [dateStart, setDateStart] = useState();
  const [reportId, setReportId] = useState();
  const [reportData, setReportData] = useState();
  const [surveyDate, setSurveyDate] = useState();
  const [completionRate, setCompletionRate] = useState();
  const [organizationData, setOrganizationData] = useState(null);
  const [selectedReport, setSelectedReport] = useState();

  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual
  );

  // const navigate = useNavigate();

  // ******** Selectors ********
  const {
    get_employee_user_category,
    pdfComments,
    survey_version,
    get_employees,
    get_employee_org_category,
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    nav_state,
    get_survey_questions,
    get_organizations,
    get_auth,
    org,
    get_debrief_schedules,
  } = useSelector(
    (state) => ({
      get_debrief_schedules: state.debrief_schedule,
      get_survey_questions: state.surveyquestions.survey_questions,
      nav_state: state.audit.nav,
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
      get_auth: state.auth,
      WhiteLabel: state.white_label?.white_label,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      pdfComments: state.PDFComments.pdfComments,
      survey_version: state.audit.survey_version,
      get_employees: state.employees,
      get_employee_org_category: state.employee_category?.employee_org_category,
      org: state.organizations,
      get_selectedOrg: state.selectedOrg,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationData(
      org[Number(get_auth?.organization_id) || org?.organization?.id]
    );
  }, [org, get_auth?.organization_id]);

  /***********  Custom Hooks  *************/
  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_SAVED_COMMENTS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (surveyVersion) {
      dispatch({
        type: SET_SURVEY_VERSION,
        payload: surveyVersion?.value,
      });
    }
  }, [surveyVersion]);

  useEffect(() => {
    if (!currentUser && !get_employee_user_category?.length > 0) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: {
          id: Number(get_auth?.employee_id),
        },
      });

      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${get_auth?.employee_id}`,
      });
    }
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (reportObj?.debrief_schedule) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: {
          id: reportObj?.debrief_schedule,
        },
      });
    }
  }, [reportObj]);

  useEffect(() => {
    if (get_debrief_schedules?.[reportObj?.debrief_schedule]) {
      setCompletionRate({
        totalResponses:
          get_debrief_schedules?.[reportObj?.debrief_schedule]?.total_responses,
        totalRequests:
          get_debrief_schedules?.[reportObj?.debrief_schedule]?.total_requests,
      });

      setSurveyDate(
        get_debrief_schedules?.[reportObj?.debrief_schedule]?.scheduled_for
      );
    }
  }, [get_debrief_schedules, reportObj]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    setAccessSubfactor(false);
    if (get_employees) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employees?.userEmp);
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employees.userEmp?.access_subfactor_data === 1) {
        setAccessSubfactor(true);
      }

      if (get_employees.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employees.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employees]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (
      employeeCategories &&
      employeeCategories?.category &&
      get_survey_structure.length > 0
    ) {
      let otpView = true;
      const primaryList = get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary"
      )?.[0]?.options;

      const empCategories = employeeCategories?.category.map((ci) => ci?.value);

      primaryList.forEach((li) => {
        if (!empCategories.includes(li.name)) {
          otpView = false;
        }
      });

      if (otpView === true && empCategories.length > primaryList.length) {
        setOtpDualView(true);
      }
      setOtpView(otpView);
    }
  }, [employeeCategories, get_survey_structure]);

  useEffect(() => {
    if (!calculated && searchParams.get("id")) {
      const restoredState = pdfComments.find((comment) => {
        return comment?.details?.pdfId === searchParams.get("id");
      });

      if (restoredState) {
        setRestoredState(restoredState);
      }
    }
  }, [restoredState, state, searchParams, calculated]);

  useEffect(() => {
    if (get_culture_audit_reports) {
      let sorted_responses = get_culture_audit_reports?.response?.sort(
        (a, b) => b.id - a.id
      );

      if (sorted_responses) {
        let surveys = [];

        sorted_responses.map((item) => {
          if (!surveys.find((f) => f.id === item.question.sort_order)) {
            surveys.push({
              id: item.question.sort_order,
              value: item.question,
              text: item.question.name,
            });
          }
        });

        if (state?.sortOrder) {
          const selectedSurvey = surveys?.find(
            (survey) => survey?.id === state?.sortOrder
          );
          setSurveyVersion(selectedSurvey);
        }

        if (restoredState?.sortOrder) {
          const selectedSurvey = surveys?.find(
            (survey) => survey?.id === restoredState?.sortOrder
          );
          setSurveyVersion(selectedSurvey);
        }
      }
    }
  }, [state?.sortOrder, restoredState?.sortOrder, get_culture_audit_reports]);

  useEffect(() => {
    if (state.id && !pdfId) {
      setPdfId(state.id);
    }

    if (pdfId && pdfComments && pdfComments.length > 0 && !reportObj) {
      const pdfComment = pdfComments.find((comment) => {
        return comment?.details?.pdfId === pdfId;
      });

      if (pdfComment) {
        setReportObj(pdfComment);
      }
    }
  }, [pdfId, state, pdfComments, reportObj]);

  useEffect(() => {
    if (reportObj) {
      const { details } = reportObj;
      if (details?.comments?.length > 0) {
        setComments(details?.comments);
      }
      if (details?.selectedQuestion?.length > 0) {
        setSelectedQuestion(details?.selectedQuestion);
      }
      if (details?.selectedPrompt?.length > 0) {
        setSelectedPrompt(details?.selectedPrompt);
      }
      if (details?.gptResponse?.length > 0) {
        setGptResponse(details?.gptResponse);
      }

      if (details?.coverTitle) {
        setUpdatedCoverTitle(details?.coverTitle);
      }
    }
  }, [reportObj]);

  const updateCoverTitle = (title) => {
    setUpdatedCoverTitle(title);
  };

  // TODO: use this in calculations before committing
  const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : rule;

  useEffect(() => {
    if (state.options?.reportId || restoredState?.options?.reportId) {
      setReportId(state.options?.reportId || restoredState?.options?.reportId);
    }
  }, [state.options?.reportId, restoredState?.options?.reportId]);

  useEffect(() => {
    if (state.options?.startDate || restoredState?.options?.startDate) {
      setDateStart(
        state.options?.startDate || restoredState?.options?.startDate
      );
    }

    if (state.options?.endDate || restoredState?.options?.endDate) {
      setDateEnd(state.options?.endDate || restoredState?.options?.endDate);
    }
  }, [
    state.options?.startDate,
    restoredState?.options?.startDate,
    state.options?.endDate,
    restoredState?.options?.endDate,
  ]);

  useEffect(() => {
    if (state.options?.sports || restoredState?.options?.sports) {
      setSportsFiltered(
        state.options?.sports || restoredState?.options?.sports
      );
    }
  }, [state.options?.sports, restoredState?.options?.sports]);

  useEffect(() => {
    if (survey_version) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues;

        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };
        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, survey_version]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0]) {
      const DefState = build_default_State(get_survey_structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });
      if (get_culture_audit_reports.response) {
        if (get_culture_audit_reports.response.length > 0) {
          let sorted_responses = get_culture_audit_reports.response.sort(
            (a, b) => b.id - a.id
          );

          if (survey_version) {
            sorted_responses = sorted_responses.filter(
              (f) => f.question.sort_order === survey_version.sort_order
            );
          }
          if (dateStart) {
            setReportData([]);
          }

          if (checkPersonality(get_organizations)) {
            //Modify the results to include personality as a categorical variable
            sorted_responses =
              convert_personality_to_categories(sorted_responses);
          }

          const sportsFiltered =
            state?.options?.sports || restoredState?.options?.sports;

          if (sportsFiltered?.length > 0) {
            sorted_responses = sorted_responses.filter((responseGroup) => {
              return sportsFiltered.includes(
                responseGroup.name.replace(/[ \t]+(\r?$)/, "")
              );
            });
          }

          let most_recent;
          if (reportId && sorted_responses) {
            most_recent = get_by_id(sorted_responses, reportId);
            sorted_responses
              .filter((responseGroup) => responseGroup.id === reportId)
              ?.map((i) => i.responses)
              .flat()
              .map((i) => i.response)
              .filter((f) => !Array.isArray(f));
          } else if (dateStart && dateEnd && sorted_responses) {
            sorted_responses = sorted_responses.filter(
              (responseGroup) => responseGroup.responses.length > 0
            );

            sorted_responses = sorted_responses.filter(
              (responseGroup) =>
                new Date(responseGroup.date) <= new Date(dateEnd) &&
                new Date(responseGroup.date) >= new Date(dateStart)
            );

            // If not admin, filter categories which user does not have access to.

            most_recent = get_most_recent(sorted_responses);
          } else {
            most_recent = get_most_recent(sorted_responses);
          }

          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );
            setReportData([most_recent, ...historical_data]);

            // setComments(check_comments(most_recent, get_employees));

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    dateEnd,
    dateStart,
    reportId,
    get_organizations,
    survey_version,
  ]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (
      // (!calculated || !calculated?.responses?.length > 0) &&
      reportData &&
      get_survey_structure?.[0] &&
      (state.anchor || restoredState?.anchor) &&
      questionStructure
    ) {
      setCalculated(
        calculate_otp_table_data(
          reportData,
          get_survey_structure[0],
          state.anchor || restoredState?.anchor,
          orgRule,
          questionStructure,
          state.selectedSport,
          true
        )
      );
    }
  }, [
    reportData,
    get_survey_structure[0],
    state.anchor,
    questionStructure,
    state.selectedSport,
  ]);

  // 2. Get column config for table
  useEffect(() => {
    if (get_survey_structure?.[0]) {
      setColConfig(getColConfig(get_survey_structure[0], lang, translations));
    }
  }, [get_survey_structure[0]]);

  // 3. Get summary table data
  useEffect(() => {
    if (
      calculated &&
      calculated?.responses.length > 0 &&
      get_survey_structure?.[0] &&
      colConfig
    ) {
      const tableDataArr = getNSOTableData(
        calculated,
        get_survey_structure[0],
        questionStructure,
        colConfig,
        lang,
        translations
      );
      setTableData(tableDataArr);
    }
  }, [calculated, colConfig, get_survey_structure[0], questionStructure]);

  // Set filtered data
  useEffect(() => {
    if (!filteredData && reportData && nav_state) {
      setFilteredData(filter_otp_data(reportData, state.selectedSport));
    }
  }, [reportData, nav_state, filteredData]);

  // If template is OTP and OTP view, we need a second chart
  // This will abstract a new ready-state using 1 or both charts (depending on which is needed)
  useEffect(() => {
    if (URI) {
      setURIReady(true);
    }
  }, [URI, URI_OTP]);

  useEffect(() => {
    if (tableData) {
      setPageLoading(false);
    }
  }, [tableData]);

  useEffect(() => {
    if (!spiderOptions && calculated?.responses.length > 0) {
      setSpiderOptions(
        getOptions(0, calculated, questionStructure).filter(
          (item) => item !== null
        )
      );
    }

    if (
      spiderOptions &&
      calculated?.responses.length > 0 &&
      !spiderTwoOptions
    ) {
      setSpiderTwoOptions(
        getOptions(1, calculated, questionStructure)?.filter(
          (item) => item !== null
        )
      );
    }
  }, [spiderOptions, calculated, spiderTwoOptions, questionStructure]);

  const getComponent = () => {
    if (page > -1) {
      if (template.items[page].component === 1 && tableData) {
        return (
          <SummaryTable
            title={getTranslatedText("domainOverallScore", lang, translations)}
            average={tableData[template.items[page].dimension].dimensionAvg}
            title2={getTranslatedText("byRoles", lang, translations)}
            headers={getTranslatedText("headers", lang, translations)}
            data={tableData[template.items[page].dimension]}
            isFrench={lang === "fr"}
          />
        );
      }

      if (template.items[page].component === 2 && tableData) {
        const matchingTitle =
          lang === "fr"
            ? navigationMap?.find((map) => map.page === page)?.fr
            : navigationMap?.find((map) => map.page === page)?.title;
        const matchingData = tableData?.[
          template.items[page].dimension
        ].data?.find((data) => {
          return data.factor.toLowerCase() === matchingTitle.toLowerCase();
        });

        const barData = {
          ...matchingData,
        };

        const responsesCopy = [...barData.factorResponses];

        responsesCopy.sort((a, b) => {
          let aAverage = a?.average;
          let bAverage = b?.average;
          if (!a?.average) aAverage = 0;
          if (!b?.average) bAverage = 0;
          return bAverage - aAverage;
        });

        barData.factorResponses = responsesCopy;

        return (
          <BarGraph
            title={getTranslatedText(barData.factor, lang, translations)}
            average={barData?.factorAverage?.toFixed(1)}
            categories={get_survey_structure[0]}
            isFrench={lang === "fr"}
            data={barData}
          />
        );
      }

      if (
        template.items[page].component === 3 &&
        calculated?.responses &&
        questionStructure &&
        get_survey_structure[0]
      ) {
        const ranking = !template.items[page].overall
          ? buildRankingTables(
              calculated?.responses,
              get_survey_structure[0],
              questionStructure,
              template.items[page].dimension,
              template.items[page].rank,
              lang === "fr",
              translations
            )
          : buildOverallRankingTables(
              calculated?.responses,
              get_survey_structure[0],
              questionStructure,
              template.items[page].rank,
              lang === "fr",
              translations
            );
        const title = getTranslatedText(
          template.items[page].rank,
          lang,
          translations
        );
        if (ranking) {
          return (
            <Ranking
              title={title}
              headers={lang === "fr" ? frenchRankHeaders : rankHeaders}
              data={ranking}
              isFrench={lang === "fr"}
            />
          );
        }
      }

      if (template.items[page].component === 4) {
        const aiIndex = template.items[page].index;
        let qIds;
        if (survey_version?.sort_order === 130) {
          if (page <= 10) {
            qIds = [
              117, 118, 119, 120, 121, 122, 123, 124, 129, 130, 131, 132, 133,
              134, 135,
            ];
          } else {
            qIds = [125, 126, 127, 128, 136, 137, 138, 139];
          }
        }
        return (
          <AIDropdown
            dimensionId={template.items[page].dimension}
            questionsIndex={template.items[page].questionsIndex}
            dimension={getTranslatedText(
              template.items[page].title,
              lang,
              translations,
              true
            )}
            surveyQuestions={survey_version}
            reportData={reportData[0]}
            selectedSport={state.selectedSport}
            setSelected={(value) => handleSelectedQuestion(value, aiIndex)}
            selected={selectedQuestion[aiIndex]}
            setPrompt={(value) => handleSetPrompt(value, aiIndex)}
            prompt={selectedPrompt[aiIndex]}
            gptResponse={gptResponse[aiIndex]}
            clearGptResponse={(q) => handleClearGptResponse(aiIndex, q)}
            setGptResponse={(value, q) =>
              handleSetGptResponse(value, aiIndex, q)
            }
            selectedLanguage={lang === "en" ? "english" : "french"}
            hasTranslation={lang !== "en"}
            qIds={qIds}
          />
        );
      }
    } else {
      return (
        <CustomTitleContainer>
          <ReportTitleLabel>
            {getTranslatedText("reportTitleLabel", lang, translations)}
          </ReportTitleLabel>
          <Description>
            {getTranslatedText("reportSubtitle", lang, translations)}
          </Description>
          <CustomTitleInput
            value={updatedCoverTitle}
            onChange={(e) => updateCoverTitle(e.target.value)}
          />
        </CustomTitleContainer>
      );
    }
  };

  /**
   * Handler functions
   * 1. Add comments to the comments array
   * 2. Handle page navigation
   */
  const addComments = (value) => {
    let newComments = comments;
    setCurrentComment(value);
    newComments[page] = value;

    setComments(newComments);
  };

  const handlePageNavigation = (page) => {
    if (reportObj) {
      dispatch({
        type: SG_UPDATE_PDF_SAVED_COMMENTS,
        payload: {
          ...reportObj,
          details: {
            ...reportObj.details,
            coverTitle: updatedCoverTitle,
            comments: comments,
            selectedQuestion: selectedQuestion,
            selectedPrompt: selectedPrompt,
            gptResponse: gptResponse,
          },
        },
      });

      dispatch({
        type: CLEAR_CHAT_GPT,
      });
    }
    if (page > -1) {
      if (template.items[page].component === 4) {
        const leadershipQuestions = [107];
        const hasComments = getFeedbackOTP(
          filter_otp_data(reportData, state.selectedSport),
          questionStructure,
          get_survey_structure[0],
          leadershipQuestions[0]
        )?.filter((comment) => comment?.text !== "");
        if (hasComments?.length > 0 || survey_version?.sort_order === 130) {
          setPage(page);
          setCurrentComment(comments[page + 1] || "");
        } else {
          setPage(page + 1);
          setCurrentComment(comments[page + 2] || "");
        }
      } else {
        setPage(page);
        setCurrentComment(comments[page] || "");
      }
    } else {
      setPage(page);
    }
  };

  const handleGenerateAi = (gptResp) => {
    if (reportObj) {
      dispatch({
        type: SG_UPDATE_PDF_SAVED_COMMENTS,
        payload: {
          ...reportObj,
          details: {
            ...reportObj.details,
            comments: comments,
            selectedQuestion: selectedQuestion,
            selectedPrompt: selectedPrompt,
            gptResponse: gptResp,
          },
        },
      });
    }
  };

  const handleSelectedQuestion = (value, index) => {
    const currentSelections = [...selectedQuestion];
    if (currentSelections[index]) {
      currentSelections[index] = value;
    } else {
      currentSelections.push(value);
    }
    setSelectedQuestion(currentSelections);
  };

  const handleSetPrompt = (value, index) => {
    const currentPrompts = [...selectedPrompt];
    if (currentPrompts[index]) {
      currentPrompts[index] = value;
    } else {
      currentPrompts.push(value);
    }
    setSelectedPrompt(currentPrompts);
  };

  const handleSetGptResponse = (value, index, q) => {
    const currentGptResponse = [...gptResponse];
    // if (value?.[q]?.response) {
    if (
      (currentGptResponse?.[index]?.length > 0 &&
        currentGptResponse?.[index][q]) ||
      (currentGptResponse?.[index]?.length > 0 &&
        currentGptResponse?.[index][q] === null) ||
      (currentGptResponse?.length > index - 1 &&
        currentGptResponse?.[index] === null)
    ) {
      if (currentGptResponse?.[index]) {
        currentGptResponse[index][q] = value[q];
      } else {
        currentGptResponse[index] = [value[q]];
      }
    } else {
      if (!currentGptResponse?.[index]) {
        currentGptResponse.push([value[q]]);
      } else {
        currentGptResponse[index].push(value[q]);
      }
    }
    setGptResponse(currentGptResponse);
    // if (currentGptResponse?.[index][q]?.response) {
    handleGenerateAi(currentGptResponse);
    // }
    // }
  };

  const handleClearGptResponse = (index, q) => {
    const currentGptResponse = [...gptResponse];
    if (currentGptResponse?.[index]?.length > 0) {
      currentGptResponse[index][q] = null;
    } else {
      currentGptResponse[index] = null;
    }
    setGptResponse(currentGptResponse);
  };

  /**
   * Helper functions
   *
   */
  const canComponentRender = (data) => {
    return (
      questionStructure &&
      get_survey_structure[0] &&
      nav_state &&
      WhiteLabel &&
      data &&
      tableData &&
      !pageLoading &&
      calculated?.responses.length > 0
    );
  };

  const handleExport = async () => {
    await exportAsImage(exportRef.current);
    if (otpView || otpDualView) {
      await exportAsImage(exportRefTwo.current, true);
    }
  };

  /**
   *
   * @param {*} el node to be rendered as an image
   * @param {*} height height for rendered image
   * @param {*} width width for rendered image
   * @param {*} exclusionClasses
   */
  const exportAsImage = async (el, otp = false) => {
    setLoading(true);
    addToast("generatingPDF", { appearance: "info" });

    const filter = (el) => {
      const exclusionClasses = ["legend-container", "title-bar"];
      return !exclusionClasses.some((classname) =>
        el.classList?.value?.includes(classname)
      );
    };

    const fontEmbedCss = await html2Image.getFontEmbedCSS(el);
    const imageStyle = {
      filter: filter,
      fontEmbedCss,
      style: { display: "block", left: "35%" },
      cacheBust: true,
      canvasWidth: 800,
      canvasHeight: 600,
      height: 1140,
      width: 1140,
    };

    if (otp) {
      const canvas = await html2Image.toCanvas(el, imageStyle);
      setURIOtp(canvas.toDataURL());
    } else {
      const canvas = await html2Image.toCanvas(el, imageStyle);
      setURI(canvas.toDataURL());
    }
  };

  /**
   * Render functions
   */
  const loadingComponent = (
    <div
      style={{
        position: "relative",
        marginLeft: -10,
      }}
    >
      <Loader inline active size="small" />
    </div>
  );

  const commentComponent = page > -1 && (
    <CurrentReport>
      <CommentTitle>
        {getTranslatedText(template.items[page].title, lang, translations)}
      </CommentTitle>
      <Description>
        {getTranslatedText("addCommentsText", lang, translations)}
      </Description>
      <Text
        placeholder={getTranslatedText("enterComments", lang, translations)}
        value={comments[page] || currentComment}
        onChange={(e) => addComments(e.target.value)}
      />
    </CurrentReport>
  );

  const nextButton =
    page < template.items.length - 1 ? (
      <Button
        style={{ cursor: "pointer" }}
        onClick={() => handlePageNavigation(page + 1)}
      >
        <Icon name="angle right" style={{ margin: 0 }} />
      </Button>
    ) : (
      // <OverviewOptions
      //   btnAction={() => generatePDF()}
      //   btnText={getTranslatedText("createPDFReport", lang, translations)}
      //   btnEnabled={calculated?.responses.length > 0}
      //   showControl={showControl}
      //   setShowControl={(show) => setShowControl(show)}
      //   optionsKey="sport"
      //   structure={get_survey_structure}
      //   setPdfGenerating={(isGenerating) => setPdfGenerating(isGenerating)}
      //   />
      <CreateButton style={{ cursor: "pointer" }} onClick={() => generatePDF()}>
        {getTranslatedText("createPDFReport", lang, translations)}
      </CreateButton>
    );

  let otpChart;

  // if (otpView || otpDualView) {
  //   otpChart = tableData && canComponentRender(reportData) && (
  //     <div ref={exportRefTwo} key="overview2">
  //       <Overview
  //         data={reportData}
  //         questions={questionStructure}
  //         structure={get_survey_structure[0]}
  //         nav={nav_state}
  //         rule={orgRule}
  //         pdf={true}
  //         otpPdf={true}
  //         otpView={true}
  //         WhiteLabel={WhiteLabel}
  //         selected={1}
  //         overview={true}
  //         selectedSport={state.selectedSport}
  //         language={lang}
  //       />
  //     </div>
  //   );
  // }

  // const overviewChart = tableData && canComponentRender(reportData) && (
  //   <div ref={exportRef}>
  //     <Overview
  //       data={reportData}
  //       questions={questionStructure}
  //       structure={get_survey_structure[0]}
  //       nav={nav_state}
  //       rule={orgRule}
  //       pdf={false}
  //       otpPdf={true}
  //       WhiteLabel={WhiteLabel}
  //       selected={1}
  //       overview={true}
  //       selectedSport={state.selectedSport}
  //       language={lang}
  //     />
  //   </div>
  // );

  // const pdfInstance = useMemo(
  //   () =>
  //     calculated?.responses.length > 0 &&
  //     URIReady &&
  //     spiderOptions &&
  //     spiderTwoOptions && (
  //       <OTPInstance
  //         organizationData={organizationData}
  //         reportData={reportData}
  //         nav_state={nav_state}
  //         WhiteLabel={WhiteLabel}
  //         questionStructure={questionStructure}
  //         get_survey_structure={get_survey_structure}
  //         selectedReport={selectedReport}
  //         rule={orgRule}
  //         URI={URI}
  //         URI_OTP={URI_OTP}
  //         calculated={calculated}
  //         anchor={state.anchor}
  //         overview={true}
  //         isOTP={true}
  //         otpFormat={true}
  //         setPdfGenerating={(isGenerating) => setPdfGenerating(isGenerating)}
  //         spiderOptions={spiderOptions}
  //         spiderTwoOptions={spiderTwoOptions}
  //         filteredData={filteredData}
  //         selectedSport={state.selectedSport}
  //         reportComments={comments}
  //         aiData={gptResponse}
  //         sportName={state?.sportName || restoredState?.sportName}
  //         isFrench={lang === "fr"}
  //         customTitle={state?.customTitle || restoredState?.customTitle}
  //         completionRate={completionRate}
  //         surveyDate={surveyDate}
  //         // {...props}
  //         // otpView={props.otpVersion}
  //       />
  //     ),
  //   [
  //     calculated,
  //     URIReady,
  //     get_survey_structure,
  //     questionStructure,
  //     spiderOptions,
  //     spiderTwoOptions,
  //   ]
  // );

  const generatePDF = () => {
    const transferObject = {
      coverSubtitle: "PDF summary report",
      coverTitle: updatedCoverTitle || restoredState?.details?.coverTitle,
      anchor: state.anchor || restoredState?.anchor,
      config: pdfConfig,
      buttonEnabled: true,
      sortOrder: survey_version?.sort_order,
      // reportData: reportData,
      overview: true,
      reportComments:
        comments ||
        state?.details?.comments ||
        restoredState?.details?.comments,
      otpFormat: true,
      isOTP: true,
      selectedSport: state?.selectedSport || restoredState?.selectedSport,
      sportName: state?.sportName || restoredState?.sportName,
      aiData:
        state?.details?.gptResponse || restoredState?.details?.gptResponse,
      otpVersion: false,
      isFrench: state?.isFrench || restoredState?.isFrench,
      auditReports: get_culture_audit_reports,
      completionRate: completionRate,
      surveyDate: surveyDate,
      survey_version: JSON.parse(JSON.stringify(survey_version)),
      structure: get_survey_structure[0],
      calculated: calculated,
      options: {
        startDate: dateStart,
        endDate: dateEnd,
        sports: [state?.sportName || restoredState?.sportName],
      },
    };

    // Open a new window
    const newTab = window.open(
      `${window.location.origin}/app/otp/generate-pdf`,
      "_blank"
    );

    // Create a broadcast channel
    const bc = new BroadcastChannel("pdf_channel");

    // Once the window is open it will send a message to us to let us know it is ready.
    bc.onmessage = (ev) => {
      if (ev?.data?.windowOpen) {
        bc.postMessage(transferObject);
      }
      // newTab.postMessage(
      //   transferObject,
      //   `${window.location.origin}/app/otp/generate-pdf`
      // );
      // bc.postMessage(transferObject);
    };
  };


  return (
    <Container>
      <Dimmer.Dimmable blurring dimmed={pageLoading}>
        <Dimmer active={pageLoading}>
          <LoadingContainer>
            <p>{getTranslatedText("loadingPage", lang, translations)}</p>
            <Loader size="medium">
              {getTranslatedText("loading", lang, translations)}
            </Loader>
          </LoadingContainer>
        </Dimmer>
        <ContentContainer>
          <Navigation
            currentPage={page}
            handlePageChange={(p) => handlePageNavigation(p)}
            lang={lang}
          />
          <div style={{ width: "800px" }}>
            <Direction>
              <Title>
                <Link
                  to={{
                    pathname: "/app/otp/pdf",
                  }}
                >
                  <BackButton>
                    <Icon name="angle left" />
                    {getTranslatedText("cancel", lang, translations)}
                  </BackButton>
                </Link>
                {getTranslatedText("reportBuilderSport", lang, translations)}
              </Title>

              <NavButtonContainer>
                {(page > -1 && (
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePageNavigation(page - 1)}
                  >
                    <Icon name="angle left" style={{ margin: 0 }} />
                  </Button>
                )) || (
                  <Link
                    to={{
                      pathname: "/app/otp/pdf",
                    }}
                  >
                    <Button>
                      <Icon name="angle left" style={{ margin: 0 }} />
                    </Button>
                  </Link>
                )}
                <NextButtonWrapper>
                  {!pdfGenerating && nextButton}
                </NextButtonWrapper>
                {/* {pdfGenerating && loadingComponent} */}
                {/* {page >= template.items.length - 1 && (
                  
                )} */}
              </NavButtonContainer>
            </Direction>

            {getComponent()}

            {page > -1 && template.items[page].comment && commentComponent}
          </div>
        </ContentContainer>
      </Dimmer.Dimmable>
      {/* Wrapper for charts */}
      {/* <div
        style={{
          overflow: "hidden",
          position: "absolute",
          height: "0%",
          left: 0,
          top: 0,
          zIndex: -999,
        }}
      >
        {overviewChart}
        {otpChart && otpChart}
      </div> */}
    </Container>
  );
};;

export default CWFPDF;

const headers = ["Athlete", "Coach", "SSSM", "Support"];

const rankHeaders = ["AV", "A", "C", "SSM", "NSO"];
const frenchRankHeaders = ["AV", "A", "E", "SSM", "ONS"];

const CustomTitleContainer = styled.div`
  padding-bottom: 15px;
  display: flex;

  flex-direction: column;

  margin-bottom: 50px;
`;

const CustomTitleInput = styled.input`
  width: 400px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
`;

const NavButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: "100%";
`;

const NextButtonWrapper = styled.div`
  margin-left: 10px;
`;

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #2d70e2;
`;

const ReportTitleLabel = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 10px;
`;

const Text = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 5px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;

  padding: 15px;
  resize: none;
  border: 1px solid #ccc;

  margin-top: 10px;
`;

const CommentTitle = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 10px;
`;

const AIGeneration = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const CurrentReport = styled.div``;

const Direction = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

const Button = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  color: #b3b3b3;
  font-size: 20px;
`;

const CreateButton = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d70e2;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
`;

const Image = styled.img`
 width: 600px;
 margin-bottom: 40px;
`;

const LoadingContainer = styled.div`
 min-height: 40vh;
 padding: 30px;

 @media (max-width: 768px) {
  padding: 20px;
 }
`;

const PDFWrapper = styled.div`
 top: 0;
 min-width: 100px;
 padding-right: 10px;
 justify-content: center;
 align-items: center;
`;

const InstancePosition = styled.div`
 position: absolute;
 top: 0;
 right: 0;
 height: 100%;
 justify-content: center;
 align-items: center;
`;

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
color:#2A3039;
margin-bottom:10px;
`

const BackButton = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color:#666D79;
  margin-right: 10px;
  cursor: pointer;
`;
