import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Form, Accordion, Icon, Checkbox } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  SG_FETCH_TASK_TEMPLATE,
  SG_GET_TASK_TEMPLATES,
  SG_UPDATE_TASK_TEMPLATE,
} from "constants/actions";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";

const createEditFormat = (templateToEdit) => {
  const { name, id, sort_order, user_tasks } = templateToEdit;
  const newTemplate = {
    id,
    name,
    sort_order: sort_order,
    user_tasks: [...user_tasks],
  };
  return newTemplate;
};

const EditTemplatePage = ({
  handleSave,
  handleClose,
  employees,
  taskId,
  nextCb,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [removedAssignees, setRemovedAssignees] = useState([]);
  const [assigneesUpdated, setAssigneesUpdated] = useState(false);
  const [formData, setFormData] = useState({});
  const [template, setTemplate] = useState({});
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { coreData, taskTemplates, fetchedTemplate } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      taskTemplates: state.task_templates,
      fetchedTemplate: state.task_templates?.fetched_template,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (fetchedTemplate?.name) {
      const template = fetchedTemplate;
      setFormData(createEditFormat(template));
      setTemplate(template);
    }
  }, [taskTemplates]);

  const handleAccordionClick = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleTaskChange = (e, { name, value }) => {
    let updatedDescription = formData?.user_tasks?.find(
      (task) => task.task_type === "description"
    );

    if (!updatedDescription) {
      updatedDescription = {
        task_type: "description",
        text: value,
      };

      setFormData({
        ...formData,
        user_tasks: [...formData.user_tasks, updatedDescription],
      });
      return;
    }

    updatedDescription.text = value;

    const updatedTasks = formData?.user_tasks?.map((task) =>
      task.task_type === "description" ? updatedDescription : task
    );

    setFormData({
      ...formData,
      user_tasks: [...updatedTasks],
    });
  };

  const handleToggle = (e, { name, checked }) => {
    let updatedTask = formData?.user_tasks?.find(
      (task) => task.task_type === name
    );

    if (!updatedTask) {
      updatedTask = {
        task_type: name,
        selection: checked,
      };

      setFormData({
        ...formData,
        user_tasks: [...formData.user_tasks, updatedTask],
      });
      return;
    }

    updatedTask.selection = checked;

    const updatedTasks = formData?.user_tasks?.map((task) =>
      task.task_type === name ? updatedTask : task
    );

    setFormData({
      ...formData,
      user_tasks: [...updatedTasks],
    });
  };

  const handleQuestionChange = (e, { name, value, index, id }) => {
    const updatedQuestion = formData?.user_tasks?.find(
      (question) => question?.id === id
    );

    updatedQuestion.question = value;
    const updatedQuestions = formData?.user_tasks?.map((q) =>
      q?.id === id ? updatedQuestion : q
    );

    setFormData({
      ...formData,
      user_tasks: [...updatedQuestions],
    });
  };

  const handleQuestionToggle = (e, { name, checked, id }) => {
    const updatedQuestion = formData?.user_tasks?.find(
      (question) => question?.id === id
    );
    updatedQuestion.aiEnhanced = checked;
    const updatedTasks = formData?.user_tasks?.map((q) =>
      q?.id === id ? updatedQuestion : q
    );

    setFormData({
      ...formData,
      user_tasks: [...updatedTasks],
    });
  };

  const addQuestion = () => {
    const newQuestion = {
      id: uuidv4(),
      question: "",
      aiEnhanced: false,
      task_type: "question",
    };

    setFormData({
      ...formData,
      user_tasks: [...formData.user_tasks, newQuestion],
    });
  };

  const removeQuestion = (id) => {
    const updatedQuestions = formData.user_tasks.filter(
      (question) => question.id !== id
    );
    setFormData({ ...formData, user_tasks: updatedQuestions });
  };

  const saveTemplate = () => {
    dispatch({
      type: SG_UPDATE_TASK_TEMPLATE,
      payload: formData,
    });
    navigate("/leader/audit/manage/tasktemplates_v2");
  };

  const saveAndScheduleTemplate = () => {
    dispatch({
      type: SG_UPDATE_TASK_TEMPLATE,
      payload: formData,
    });
    nextCb();
    navigate(`/leader/audit/manage/tasks/schedule/${taskId}`);
  };

  const copyAndCreateTemplate = () => {
    const newTemplate = { ...formData }; // Generate a new ID for the copied template
    // Pass newTemplate to the create page through the navigation object
    navigate(`/leader/audit/manage/tasktemplates_v2/create`, {
      state: { template: newTemplate },
    });
  };

  useEffect(() => {
    dispatch({ type: SG_FETCH_TASK_TEMPLATE, payload: id || taskId });
  }, []);

  return (
    <PageContainer>
      <Header>Edit Survey</Header>
      <Content>
        <Title>Edit Survey Details</Title>
        <Form>
          <Form.Input
            label="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
          />
          <Form.Input
            label="Survey Description"
            name="task_description"
            value={
              formData?.user_tasks?.find(
                (task) => task.task_type === "description"
              )?.text || ""
            }
            onChange={handleTaskChange}
          />
          <Form.Field>
            <Checkbox
              label="Feedback Tips"
              name="feedback_notes"
              checked={
                formData?.user_tasks?.find(
                  (task) => task.task_type === "feedback_notes"
                )?.selection || false
              }
              onChange={handleToggle}
              toggle
            />
          </Form.Field>
        </Form>
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Questions
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            {formData?.user_tasks
              ?.filter((task) => task?.task_type === "question")
              ?.map((question, index) => (
                <QuestionDisplay key={question.id}>
                  <Form.Input
                    style={{
                      width: "100%",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                    label={`Question #${index + 1}`}
                    name="question"
                    value={question.question}
                    onChange={(e, { name, value }) =>
                      handleQuestionChange(e, { name, value, id: question?.id })
                    }
                  />
                  <BottomRow>
                    <Form.Field>
                      <Checkbox
                        label="AI Enhanced"
                        toggle
                        name="aiEnhanced"
                        checked={question.aiEnhanced}
                        onChange={(e, { name, checked }) =>
                          handleQuestionToggle(e, {
                            name,
                            checked,
                            id: question?.id,
                          })
                        }
                      />
                    </Form.Field>
                    <RemoveButton onClick={() => removeQuestion(question.id)}>
                      Remove Question
                    </RemoveButton>
                  </BottomRow>
                </QuestionDisplay>
              ))}
            <AddButton onClick={addQuestion}>+ Add Question</AddButton>
          </Accordion.Content>
        </Accordion>
      </Content>
      <Footer>
        <Button
          onClick={() => navigate(`/leader/audit/manage/tasktemplates_v2`)}
        >
          Cancel
        </Button>
        <ButtonCoupler>
          <Button primary onClick={saveTemplate}>
            Save Changes
          </Button>
          <Button primary onClick={saveAndScheduleTemplate}>
            Save & Schedule Survey
          </Button>
        </ButtonCoupler>
      </Footer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
`;

const Content = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  color: white;
  background-color: ${({ primary }) => (primary ? "#2d70e2" : "#666d79")};
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

const ButtonCoupler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
`;

const AddButton = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
  max-width: 150px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const QuestionDisplay = styled.div`
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const QuestionRow = styled.div`
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
`;

const RemoveButton = styled.div`
  color: #f15062;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export default EditTemplatePage;
