import React from "react";
import { View, Text, StyleSheet, Font, Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Segmentation from "../components/Segmentation";
import { calculate_table_data } from "../../../reports/Audit/data_navigation/calculate_data";
import Side from "../components/Side";
import Footer from "../components/Footer";
// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";

/**
 *
 * @param {description: string, factors: string} props
 *
 * @returns
 */
const Methodology = (props) => {
  const {
    nav,
    categories,
    rule,
    structure,
    data,
    anchor,
    config,
    previous,
    questions,
  } = props;

  // Create styles
  const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      fontWeight: 700,
      paddingBottom: 5,
      borderBottom: "0.5px solid black",
      width: "100%",
      marginBottom: 15,
      color: "#2C3644",
    },
    page: {
      width: "100%",
      height: "100vh",
      position: "relative",
      padding: 60,
      color: "#2C3644",
      paddingHorizontal: 80,
    },
    p: {
      fontSize: 11,
      marginBottom: 15,
      lineHeight: 1.7,
      color: "#2C3644",
    },
    p2: {
      fontSize: 9,
      marginBottom: 10,
      color: "#2C3644",
    },
    T2: {
      fontSize: 20,
      fontWeight: 700,
      color: "#2C3644",
    },
    line: {
      width: 75,
      height: 4,
      backgroundColor: "#2C3644",
      marginTop: 5,
      marginBottom: 20,
    },
    description: {
      fontWeight: 700,
      fontSize: 14,
      marginTop: 15,
      color: "#2C3644",
    },
    section: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "#F0F5FE",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
      marginBottom: 20,
    },
    subSection: {
      display: "flex",
      flexDirection: "row",
      fontWeight: 700,
      fontSize: 16,
      height: "100%",
      alignItems: "center",
    },
    subTitle: {
      fontWeight: 700,
      fontSize: 16,
      color: "#2C3644",
    },
    S1: {
      fontSize: 10,
      marginRight: 15,
      color: "#2C3644",
    },
    img: {
      height: 20,
    },
    offset: {
      width: "100%",
    },
    summary: {
      border: "2px solid #000921",
      borderRadius: 5,
      marginTop: 20,
      marginBottom: 20,
    },
    top: {
      backgroundColor: "#000921",
      color: "white",
      fontSize: 16,
      fontWeight: 700,
      padding: 5,
    },
    bottom: {
      padding: 10,
      marginTop: 20,
    },
    steps: {
      width: "90%",
      display: "flex",
      flexDirection: "row",
      marginBottom: 3,
    },
    square: {
      height: 6,
      width: 6,
      backgroundColor: "#000921",
      marginLeft: 20,
      marginRight: 10,
      marginTop: 5,
    },
    wrappingTitle: {
      fontSize: 16,
      fontWeight: 700,
      paddingBottom: 5,
      borderBottom: "0.5px solid black",
      width: "100%",
      color: "#2E34D0",
      marginBottom: 15,
    },
    quote: {
      fontSize: 13,
      lineHeight: 1.7,
      textAlign: "center",
      alignItems: "flex-end",
    },
    author: {
      paddingTop: 10,
      width: "100%",
      fontSize: 10,
      lineHeight: 1.2,
      justifyContent: "flex-start",
    },
    quoteText: {
      fontWeight: 500,
    },
    quoteSection: {
      paddingTop: 35,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: "#F0F5FE",
      display: "flex",
      flexDirection: "column",
      marginTop: 25,
      marginBottom: 20,
      marginLeft: 5,
      width: "45%",
    },
    numbered: { fontWeight: 500, marginRight: 10, fontSize: 9 },
  });

  let subSelected = false;
  const navItems = Object.keys(nav);
  const navOptions = [];
  navItems.forEach((item) => {
    let found = false;
    const anchorName = categories.categories.find((f) => f.id === anchor);
    if (nav[item].selected === false) {
      subSelected = true;
      found = true;
    }

    if (found) {
      let subNavItems = Object.keys(nav[item]);
      subNavItems = subNavItems.filter(
        (key) => key !== "priority" && key !== "selected"
      );

      let selected = subNavItems.filter((subNav) => nav[item]?.[subNav].sub);
      if (selected.length > 0) {
        selected.forEach((selectedNav) => {
          let subFactorsKeys = Object.keys(nav[item]?.[selectedNav].subfactors);
          subFactorsKeys.forEach((subKey) => {
            if (nav[item]?.[selectedNav].subfactors?.[subKey].selected) {
              navOptions.push(nav[item]?.[selectedNav].subfactors?.[subKey].id);
            }
          });
        });
      }
    }
  });

  const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

  const check_reverse_score = (resp) => {
    let reverse =
      questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
        ?.reverse;
    let response = reverse ? rev_order[resp.response] : resp.response;
    return response;
  };

  const average = (array) => {
    let total = 0;
    let n = 0;
    array.map((item) => {
      total += item;
      n += 1;
    });
    return total / n;
  };

  const get_pillar_averages = (data, questions) => {
    let _data = [];
    let pillars = [];
    questions.dimensions.map((d) => {
      let d_data = [];
      d.factors.map((f) => {
        let pillarAvg = average(
          data[0].questions
            .filter((x) => x.id == d.id - 1 && x.factor == f.id - 1)
            .map((q) => check_reverse_score(q, questions))
        );
        d_data.push(pillarAvg);
        pillars.push({
          avg: pillarAvg.toFixed(1),
          factor: f.id - 1,
          dimension: d.id - 1,
        });
      });
      _data.push(average(d_data));
    });

    return { overall: _data, pillars: pillars };
  };

  const generate_table_data = () => {
    const anchors = categories.categories.filter((f) => f.id === anchor);
    return anchors.map((item) => {
      let _data = calculate_table_data(
        data,
        nav,
        categories,
        item.id,
        rule,
        structure
      );

      let navLookup = [];
      if (subSelected) {
        _data.responses.forEach((response) => {
          navLookup.push({
            id: response.role,
            label: response?.label?.name,
          });
        });
      }
      return {
        name: item.name,
        id: item.id,
        data: _data.overall,
        lookup: navLookup,
      };
    });
  };

  const anchorGroup = categories.categories.find((cat) => cat.id === anchor);
  const pillarAverages =
    previous?.length > 0 && get_pillar_averages(previous, questions);

  const introduction = (
    <View style={{ flexDirection: "row" }}>
      <View style={{ width: "70%" }}>
        <Text style={[styles.wrappingTitle, { color: config.titleColor }]}>
          Introduction
        </Text>
        <Text style={styles.p}>
          Even in the healthiest environments, opportunities to provide
          meaningful feedback are often hard to find. Worse yet, people are
          concerned about providing truthful responses for fear of retribution.
          While sport organizations currently measure success in a limited
          fashion, there is a growing appetite to understand the health and
          effectiveness of their culture. We help sport organizations achieve
          this in a safe, secure, and anonymous way.
        </Text>
      </View>
      <View style={styles.quoteSection}>
        <View style={styles.quote}>
          <Text style={styles.quoteText}>
            “A nation's culture resides in the hearts and in the soul of its
            people.”
          </Text>
          <Text style={styles.author}>- Mahatma Gandhi</Text>
        </View>
      </View>
    </View>
  );

  const sportIndex = (
    <View wrap={false}>
      <Text style={[styles.title, { color: config.titleColor }]}>
        The Sport Culture Index
      </Text>
      <Text style={styles.p}>
        The Sport Culture Index is an evidence-based assessment platform that
        quantifies culture in sport environments in the areas of safety,
        well-being, cohesion, empowerment, personalization, belonging, vision,
        values, environment, accountability, adaptability, and communication.
        Developed in 2020 by three sport psychology practitioners (PhD, MSc,
        MSc), an international level sport coach (MEd Leadership) and an Olympic
        level data analyst (MSc), the assessment leverages several validated
        psychometric scales and empirically validated concepts from sport and
        organizational psychology. The information provided by each respondent
        is aggregated to reveal powerful team and organizational trends.
      </Text>
    </View>
  );

  const methodology = (
    <View wrap={false}>
      <Text style={[styles.title, { color: config.titleColor }]}>
        Assessment Methodology
      </Text>
      <Text style={styles.p}>
        The methodology of the Sport Culture Index (SCI) is grounded in evidence
        suggesting that most meaningful sport culture variables fall into two
        primary categories: people factors (i.e., dynamics, sentiments, and
        relationships) and performance factors (i.e., processes, practices, and
        structures). The SCI allows sport organizations to understand where they
        are indexing on the primary categories of people and performance
        factors, helping them gain an understanding of their overall approach.
        Ultimately, the goal of this report is to provide reliable,
        evidence-based information to guide cultural action steps and
        transformation strategies.
      </Text>
    </View>
  );

  const wrap = navOptions.length > 5;

  const segmentation = (
    <View wrap={wrap}>
      <Text style={[styles.title, { color: config.titleColor }]}>
        Segmentation By {anchorGroup.name}
      </Text>
      <Segmentation
        categories={categories}
        structure={structure}
        calculated={[...generate_table_data()]}
        hideText={true}
        config={config}
        rule={rule}
        wrap={wrap}
        subSelected={subSelected}
        previous={pillarAverages}
      />
    </View>
  );

  const nextSteps = (
    <View wrap={false}>
      <Text style={[styles.title, { color: config.titleColor }]}>
        How to create change using this report
      </Text>
      <Text style={styles.p}>
        Changing culture is a process, not an event. Therefore, this report
        alone is not a sufficient culture change strategy. The effectiveness of
        this report, like all data, ultimately rests on your willingness to
        reflect, engage, and commit to action. Here are some keys to keep in
        mind as you work to turn the data into transformation.
      </Text>
      <View style={styles.steps}>
        <Text style={styles.numbered}>1.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Establish a sense of urgency.</Text>
          &nbsp;&nbsp;Examine the findings of this report with a factual lens.
          Productive change begins when you have the discipline to confront the
          facts of your current reality, whatever they might be.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>2.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Set your priorities.</Text>
          &nbsp;&nbsp;As Brene Brown suggests in her book Dare to Lead, “if you
          have more than three priorities, you have no priorities”. Resist the
          urge to boil the ocean, and rather, hone in on a few key areas you
          have the capacity to impact over the coming months.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>3.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Form a guiding coalition.</Text>
          &nbsp;&nbsp;Assemble a diverse, representative, and influential group
          to lead the change effort. Bring this team together to drive ideation
          in an open and honest way based on the priorities from the report, and
          ensure they are empowered to act on the groups vision.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>4.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Create short term wins.</Text>
          &nbsp;&nbsp;Begin to implement initiatives that are visible and
          obvious based on the feedback provided in the report. These don't have
          to be monumental, but rather little changes that send a signal that
          things are in motion and the feedback provided is making a difference
          right away.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>5.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Strategize long term success.</Text>
          &nbsp;&nbsp;In the midst of celebrating short term culture wins,
          continue to plan bigger initiatives that will be instilled more
          gradually.
        </Text>
      </View>
    </View>
  );

  return (
    <Page style={styles.page}>
      <Side {...config} />
      <Footer {...config} />
      <View>
        <Text style={styles.T2}>Report Methods</Text>
        <View style={styles.line} />
        {/* {introduction}
        {sportIndex}
        {methodology} */}
        {segmentation}
        {nextSteps}
      </View>
    </Page>
  );
};

Font.register({
  family: "Poppins",
  fonts: [{ src: Poppins }],
  format: "truetype",
});

Methodology.propTypes = {
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string,
  coverSubtitle: PropTypes.string,
  metadata: PropTypes.object,
};

export default Methodology;
