import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import Summary from "./Summary";
import Individual from "./Individual";
import Question from "./Questions";
import { useParams } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Reports from "./Reports";
import ReportsAccess from "./ReportsAccess";

import { SG_FETCH_TASK_SCHEDULE } from "constants/actions";

// Function to get the most recent responses for each employee
const getRecentResponses = (data) => {
  const responses = {};
  data.task_responses.forEach((response) => {
    const employeeId = response.employee;
    if (
      !responses[employeeId] ||
      response.created_at > responses[employeeId].created_at
    ) {
      responses[employeeId] = response;
    }
  });
  return Object.values(responses);
};

const QuestionData = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [responses, setResponses] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const { id } = useParams();

  const { fetchedTemplate, get_employees, fetched_scheduled_task } =
    useSelector(
      (state) => ({
        fetchedTemplate: state.task_templates?.fetched_template,
        get_employees: state.employees,
        fetched_scheduled_task: state.task_templates?.fetched_scheduled_task,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_FETCH_TASK_SCHEDULE,
      payload: id,
    });
  }, [id]);

  useEffect(() => {
    if (fetched_scheduled_task?.task_responses) {
      setResponses(getRecentResponses(fetched_scheduled_task));
    }
  }, [fetched_scheduled_task]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setActiveTab(2);
  };

  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <Summary
            assignees={fetched_scheduled_task?.assigned_employees}
            responses={responses}
            employees={get_employees}
            id={id}
          />
        );
      case 1:
        return (
          <Question
            responses={responses}
            employees={get_employees}
            template={fetched_scheduled_task}
            selectEmpCb={handleUserSelect}
          />
        );
      case 2:
        return (
          <Individual
            assignees={fetched_scheduled_task?.assigned_employees}
            responses={responses}
            employees={get_employees}
            selectedIndividual={selectedUser}
            setIndividual={setSelectedUser}
          />
        );

      case 3:
        return (
          <Reports
            assignees={fetched_scheduled_task?.assigned_employees}
            responses={responses}
            employees={get_employees}
            reports={fetched_scheduled_task?.task_reports}
            id={id}
          />
        );

      case 4:
        return (
          <ReportsAccess
            task={fetched_scheduled_task}
            employees={get_employees}
            id={id}
          />
        );

      default:
        return (
          <Summary
            assignees={fetched_scheduled_task?.assigned_employees}
            responses={responses}
            employees={get_employees}
            id={id}
          />
        );
    }
  };

  return (
    <Container>
      <Content>
        <Tabs>
          <Tab active={activeTab == 0} onClick={() => setActiveTab(0)}>
            Summary
          </Tab>
          <Tab active={activeTab == 1} onClick={() => setActiveTab(1)}>
            Questions
          </Tab>
          <Tab active={activeTab == 2} onClick={() => setActiveTab(2)}>
            Individual
          </Tab>
          <Tab active={activeTab == 3} onClick={() => setActiveTab(3)}>
            Reports
          </Tab>
          <Tab active={activeTab == 4} onClick={() => setActiveTab(4)}>
            Access
          </Tab>
        </Tabs>

        {renderTab(activeTab)}
      </Content>
    </Container>
  );
};

export default QuestionData;

// Function to get initials from the name
const getInitials = (firstName, lastName) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};
const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#e74c3c",
  "#e67e22",
  "#f1c40f",
  "#e84393",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
];

// Hash function to consistently map initials to a color
const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return colors[hash % colors.length];
};

const InitialsCircle = ({ firstName, lastName }) => {
  const initials = getInitials(firstName, lastName);
  const color = hashStringToColor(initials);

  return <Circle bgColor={color}>{initials}</Circle>;
};

const Container = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 30px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;

const Description = styled.div``;

const Tab = styled.div`
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  color: ${(props) => (props.active ? "#000" : "#999")};
  cursor: pointer;
  padding: 10px;
  border-bottom: ${(props) => (props.active ? "2px solid #000" : "none")};
  transition: all 0.3s;
  margin-right: 10px;
  font-family: "Raleway", sans-serif;
  &:hover {
    color: #000;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  max-width: 1200px;
  width: 100%;
`;

const Response = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Answer = styled.div`
  margin-bottom: 10px;
`;

const Follow = styled.div`
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
`;

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right: 10px;
`;