import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import DataRanking from "./DataRanking";
import { overall, average_cat } from "./data_navigation/calculate_data";

const old_color = "rgba(227, 176, 31,0.2)";

function calculateAverageResponse(data) {
  const sumAndCount = {};

  // Summing responses and counting entries for each id
  data.forEach(item => {
      if (!sumAndCount[item.id]) {
          sumAndCount[item.id] = { sum: 0, count: 0 };
      }
      sumAndCount[item.id].sum += item.response;
      sumAndCount[item.id].count++;
  });

  // Calculating average for each id
  const averages = {};
  for (const id in sumAndCount) {
      averages[id] = sumAndCount[id].sum / sumAndCount[id].count;
  }

  return averages;
}

const LegendStyle = (props) => {

  let averages = calculateAverageResponse(props.data.ranking)

  console.log(averages)
  return (
    <Container
      hover={
        props.highlight === null
          ? true
          : props.highlight == props.i
          ? true
          : false
      }
      pdf={props.otpPdf}
      selected={props.selected == props.i}
      onMouseEnter={() => props.hover(props.i)}
      onMouseLeave={() => props.hover(null)}
    >
      <Row
        onClick={() =>
          props.onClick(props.selected == props.i ? null : props.i)
        }
      >
        <Circle color={props.data.color} pdf={props.otpPdf} />
        <Title>
          {props.isFrench
            ? props.title.fr
              ? props.title.fr
              : props.title.name
            : props.title.name}
        </Title>

        <Score pdf={props.otpPdf}>
          <div>{averages[0].toFixed(1)}</div>
          <Sub>{props.language_hook('Person')}</Sub>
        </Score>
        <Score>
          <div>{averages[1].toFixed(1)}</div>
          <Sub>{props.language_hook('Performance')}</Sub>
        </Score>
      </Row>

      <Details selected={props.selected == props.i}>
        <Title2>{props.language_hook("Culture Domain Ranking")}</Title2>
        <DataRanking
          data={props.data}
          isFrench={props.isFrench}
          questions={props?.questions}
        />
      </Details>
    </Container>
  );
};

export default LegendStyle;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color || "green"} !important;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 5px 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  min-width: ${(props) => (props.pdf ? "1000px" : "0px")}
  &:hover {
    background-color: rgba(21, 92, 223, 0.05);
    color: black;
  }
`;

const Title = styled.div`
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const SubTitle = styled.div`
  width: 200px;
`;

const Title2 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #7e7e7e;
  margin-bottom: 29px;
`;

const Title3 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #696969;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Row2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-right: ${(props) => (props.pdf ? "10px" : "0px")};
  /* identical to box height */

  color: #6d6d6d;
  height: 40px;
`;

const Culture = styled.div``;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;

  color: #7e7e7e;
`;

const Circle = styled.div`
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  border-radius: 7.5px;
  margin-right: ${(props) => (props.pdf ? "10px" : "0px")};
  background-color: ${(props) => props.color};
`;

const Details = styled.div`
  max-height: ${(props) => (props.selected ? "1000px" : "0")};
  transition: all 0.5s;
  overflow: hidden;
  padding-top: ${(props) => (props.selected ? "10px" : "0")};
`;

const Dated = styled.div`
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: #3078ff;
  padding: 5px 10px;
  color: white;
`;
