import styled from "styled-components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Pagination } from "semantic-ui-react";

// Colors for different statuses
const statusColors = {
  Pending: "#FEA711",
  DownloadsAvailable: "#00B67A",
};

// Function to map report type names
const mapReportType = (type) => {
  switch (type) {
    case "version 2":
      return "Culture Summary";
    default:
      return type;
  }
};

// Function to extract and map the data for cards
const mapCardData = (data, categories, templates, status) => {
  const { params, created_at, status_text, id, template_id, download_files } =
    data;

  const parentCategory = categories[0]?.categories?.find(
    (cat) => cat.id === params.parent_id
  );
  const template = templates?.find((temp) => temp.id === template_id);

  return {
    id,
    categoryName: parentCategory?.name || params.orgName,
    templateName: mapReportType(template?.name),
    status: status_text === "Queued for processing" ? "Pending" : status_text,
    createdDate: format(new Date(created_at), "MMMM d, yyyy"),
    downloads: download_files?.map((file) => ({
      ...file,
      additional_info: data.additional_info?.find(
        (info) => info.share_id === file.share_id
      ),
      categoryName: parentCategory?.options?.find(
        (opt) => opt.id === file.category
      )?.name,
      hasError:
        !data.additional_info?.find((info) => info.share_id === file.share_id)
          ?.json_data && status === 21,
    })),
  };
};

// Function to parse the additional info for title and description
const parseAdditionalInfo = (additional_info) => {
  const titleMatch = additional_info?.json_data?.title?.match(/"([^"]+)"/);
  return {
    title: titleMatch ? titleMatch[1] : "No title available",
    description: additional_info?.json_data?.tldr || "No description available",
  };
};

const Card = ({ download, data, navigate, isPending }) => {
  const { title, description } = parseAdditionalInfo(download?.additional_info);
  const status = download ? "DownloadsAvailable" : "Pending";
  const color = statusColors[status];

  return (
    <CardContainer
      onClick={() =>
        navigate(download ? `insight/${data.id}/${download.share_id}` : "#")
      }
      isPending={isPending}
    >
      <CardContent>
        <CardMeta>
          <span>
            {isPending
              ? data?.categoryName
              : download?.categoryName || data?.categoryName}
          </span>{" "}
          | <span>{data.createdDate}</span>
        </CardMeta>
        <CardTitle>{!isPending ? title : "Generating your story.."}</CardTitle>
        {/* {!isPending && (
          <CardDescription>
            {description.length > 100
              ? `${description.substring(0, 100)}...`
              : description}
          </CardDescription>
        )}
        */}
      </CardContent>
      <CardStatus>
        <Caret>&gt;</Caret>
      </CardStatus>
    </CardContainer>
  );
};

const CardsList = ({ data, categories, templates }) => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = React.useState(1);
  const [pagedData, setPagedData] = React.useState([]);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    setPagedData(data.slice(start, end));
  }, [activePage, data]);

  return (
    <CardsContainer>
      {pagedData?.map((item) => {
        const mappedData = mapCardData(
          item,
          categories,
          templates?.response,
          item?.status
        );

        if (mappedData.downloads.length === 0 && item?.status !== 21) {
          return (
            <Card
              key={item.id}
              download={null}
              data={mappedData}
              navigate={navigate}
              isPending={item?.status === 1 || item?.status === 5}
            />
          );
        }
        return mappedData.downloads
          ?.filter((download) => !download.hasError)
          .map((download) => (
            <Card
              key={download.share_id}
              download={download}
              data={mappedData}
              navigate={navigate}
            />
          ));
      })}
      {totalPages > 1 && (
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePaginationChange}
        />
      )}
    </CardsContainer>
  );
};

export default CardsList;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  width: 100%;
  cursor: ${(props) => (props.isPending ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isPending ? 0.5 : 1)};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CardTitle = styled.h3`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 10px;
  line-height: 1.4;
  color:#2A3039;
`;

const CardDescription = styled.p`
  font-family: "Raleway";
  font-size: 14px;
  margin: 0 0 10px;
  color: #555;
`;

const CardMeta = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2D70E2;
  font-weight: 600;
  margin-bottom: 10px;
`;

const CardStatus = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const StatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const Caret = styled.div`
  font-size: 18px;
  font-weight: bold;
`;