import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dimmer,
  Loader,
  Icon,
  Popup,
  Modal,
  Dropdown,
  Button as ModalButton,
} from "semantic-ui-react";
import { format } from "date-fns";
import {
  SG_ADD_USER_TASK,
  SG_DELETE_USER_TASK,
  SG_CREATE_TASK_SCHEDULE,
} from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

const TemplatesList = ({
  templates,
  setDeleteId,
  setDeleteItem,
  setUpdateModal,
  edit,
  copy,
  setEditTemplate,
  setPageMode,
  setTab,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Table
      data={templates}
      setDeleteId={setDeleteId}
      setDeleteItem={setDeleteItem}
      loading={loading}
      setUpdateModal={setUpdateModal}
      edit={edit}
      copy={copy}
      setEditTemplate={setEditTemplate}
      setPageMode={setPageMode}
      setTab={setTab}
    />
  );
};

const userMap = (usersList, employeeList, managers) => {
  const list = usersList?.map((user) => {
    return {
      key: user?.employee_id,
      value: user?.employee_id,
      text: user?.employee_full_name,
      id: user?.id,
    };
  });

  const empList = employeeList?.employees;
  const inactiveList = empList?.filter(
    (emp) => emp.status === 99 || emp.status === 98
  );

  const filteredList = list.filter(
    (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
  );

  // Filter list so that only the managers are returned.
  if (managers) {
    const managerIds = managers.map((manager) => manager.id);
    const filteredManagers = filteredList.filter((emp) =>
      managerIds.includes(emp.key)
    );
    return filteredManagers;
  }

  return filteredList;
};

const AssignUserModal = ({
  onClose,
  modalOpen,
  setModalOpen,
  assign,
  template,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignedUsersList, setAssignedUsersList] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { get_employee_org_category, get_employees, user_manager } =
    useSelector(
      (state) => ({
        get_employees: state.employees,
        get_employee_org_category:
          state.employee_category?.employee_org_category,
        user_manager: state.user_manager,
      }),
      shallowEqual
    );

  const handleUserAdd = (value) => {
    if (!assignedUsersList.includes(Number(value))) {
      setAssignedUsersList([...assignedUsersList, Number(value)]);
      const user = get_employee_org_category.find(
        (u) => u.employee_id === value
      );
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <Modal open={modalOpen} onClose={onClose} size="small">
      <Modal.Header>Assign additional users to this custom survey</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>
            Select members to add to this custom survey's assignee list
          </ModalTitle>
          <ModalDescription>
            When you select a member, they will receive an email notifying them
            that they have a custom survey available.
          </ModalDescription>
          <ModalRow>
            <Dropdown
              placeholder="Select a user"
              fluid
              search
              value={selectedUsers}
              selection
              onChange={(e, { value }) => handleUserAdd(value)}
              options={userMap(
                get_employee_org_category,
                get_employees,
                user_manager?.managerUsers?.results
              )}
            />
          </ModalRow>
          <AssigneeTable onMouseOut={() => setHoveredRow(null)}>
            <AssigneeHeader>
              <AssigneeHeaderCell width={50}>Name</AssigneeHeaderCell>
              <AssigneeHeaderCell width={50}>Actions</AssigneeHeaderCell>
            </AssigneeHeader>
            {selectedUsers?.map((user, i) => {
              return (
                <AssigneeRow
                  key={user.employee_id}
                  onMouseOver={() => setHoveredRow(i)}
                  style={{
                    backgroundColor:
                      hoveredRow === i ? "#f0f0f0" : "transparent",
                  }}
                >
                  <AssigneeCell width={50}>
                    {user.employee_full_name}
                  </AssigneeCell>
                  <AssigneeCell
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter(
                            (u) => u.employee_id !== user.employee_id
                          )
                        );
                        setAssignedUsersList(
                          assignedUsersList.filter(
                            (id) => id !== user.employee_id
                          )
                        );
                      }}
                    >
                      <Icon name="trash" />
                    </DeleteButton>
                  </AssigneeCell>
                </AssigneeRow>
              );
            })}
          </AssigneeTable>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ModalButton onClick={() => setModalOpen(false)}>Cancel</ModalButton>
        <ModalButton
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            assign(assignedUsersList);
            setModalOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const ScheduleTaskModal = ({
  onClose,
  modalOpen,
  setModalOpen,
  schedule,
  template,
}) => {
  return (
    <Modal open={modalOpen} onClose={onClose} size="small">
      <Modal.Header>Create/Schedule a survey</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>Create a new survey using this template</ModalTitle>
          <ModalDescription>
            This will create a new survey using the selected template. Once
            created you can assign the survey to employees from the
            Pending/Completed tab.
          </ModalDescription>
          <ModalRow>
            {/* Add information about current template. */}
            <ModalDescription>
              <b>Template Name:</b> {template?.name}
            </ModalDescription>
            <ModalDescription>
              <b>Created By:</b> {template?.created_by}
            </ModalDescription>
            <ModalDescription>
              <b>Last Updated:</b>{" "}
              {format(new Date(template?.updated_at), "MM/dd/yyyy")}
            </ModalDescription>
            {/* Display questions list */}
            <ModalDescription>
              <b>Questions:</b>
              <ul>
                {template?.questions?.map((question) => (
                  <li key={question.id}>{question.question}</li>
                ))}
              </ul>
            </ModalDescription>
          </ModalRow>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ModalButton onClick={() => setModalOpen(false)}>Cancel</ModalButton>
        <ModalButton
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            schedule(template?.id);
            setModalOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const Table = ({ data, setDeleteId, loading, setTab }) => {
  const [hovered, setHovered] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const dispatch = useDispatch();
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const assignTask = (template, employees) => {
    const structuredPayload = {
      task_id: template.id,
      employee_id_list: employees,
    };

    dispatch({
      type: SG_ADD_USER_TASK,
      payload: structuredPayload,
    });

    // TODO: Make this smarter.
    addToast("Task assigned successfully", { appearance: "success" });
  };

  return (
    <TableContainer>
      {showAssignModal && (
        <AssignUserModal
          modalOpen={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          setModalOpen={setShowAssignModal}
          template={selectedTask}
          assign={(users) => assignTask(selectedTask, users)}
        />
      )}
      {showScheduleModal && (
        <ScheduleTaskModal
          modalOpen={showScheduleModal}
          onClose={() => setShowScheduleModal(false)}
          setModalOpen={setShowScheduleModal}
          template={selectedTask}
          schedule={(id) => {
            setShowScheduleModal(false);
            const payloadObj = {
              task_template_user_tasks: selectedTask?.id,
              name: selectedTask?.name,
            };
            dispatch({
              type: SG_CREATE_TASK_SCHEDULE,
              payload: payloadObj,
            });
            addToast("Survey scheduled successfully", {
              appearance: "success",
            });
            setTab(1);
          }}
        />
      )}
      <Header>
        <TableHead width={80}>Template Name</TableHead>
        {/* <TableHead width={20}>Created By</TableHead> */}
        {/* <TableHead width={30}>Last Updated</TableHead> */}
        {/* <TableHead width={20}>Status</TableHead> */}
        <TableHead width={20}>Actions</TableHead>
      </Header>
      {loading && (
        <Dimmer active inverted>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
      )}
      {!loading && data?.length === 0 && (
        <EmptyContainer>
          <EmptyText>No templates found</EmptyText>
        </EmptyContainer>
      )}
      {!loading &&
        data?.length > 0 &&
        data?.map((template) => {
          return (
            <React.Fragment key={template.id}>
              <Row
                onMouseEnter={() => setHovered(template.id)}
                onMouseLeave={() => setHovered(null)}
                rowIsHovered={hovered === template.id}
              >
                <TableData width={80}>
                  <TemplateName>{template.name}</TemplateName>
                </TableData>
                {/* <TableData width={20}>
                  <TemplateName>{template.created_by}</TemplateName>
                </TableData>
                <TableData width={30}>
                  <TemplateName>
                    {format(new Date(template.updated_at), "MM/dd/yyyy")}
                  </TemplateName>
                </TableData> */}
                {/* <TableData width={20}>
                  <StatusContainer
                    style={{
                      backgroundColor: statusColors[template.status].container,
                      color: statusColors[template.status].text,
                    }}
                  >
                    {template.status}
                  </StatusContainer>
                </TableData> */}
                <TableData width={20}>
                  <ActionsContainer>
                    <Popup
                      trigger={
                        <ShareButton
                          onClick={() => {
                            navigate(
                              `/leader/audit/manage/tasktemplates/edit/${template.id}`
                            );
                          }}
                        >
                          <Icon name="edit" />
                        </ShareButton>
                      }
                      content="Edit Template"
                      position="top center"
                      inverted
                    />
                    <Popup
                      trigger={
                        <ShareButton
                          onClick={() => {
                            navigate(
                              `/leader/audit/manage/tasktemplates/schedule/${template.id}`
                            );
                          }}
                        >
                          <Icon name="calendar" />
                        </ShareButton>
                      }
                      content="Schedule Custom Survey"
                      position="top center"
                      inverted
                    />
                  </ActionsContainer>
                </TableData>
              </Row>
            </React.Fragment>
          );
        })}
    </TableContainer>
  );
};

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

const ShareButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
`;

const ModalDescription = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
`;

const ModalRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TableHead = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #666d79;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
`;

const EmptyText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #666d79;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TemplateName = styled.div``;

const TableData = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const AssigneeTable = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  font-family: "Raleway", sans-serif;
  margin-top: 40px;
`;

const AssigneeHeader = styled.div`
  display: flex;
  width: 100%;
`;

const AssigneeHeaderCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
`;

const AssigneeRow = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
`;

const AssigneeCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
`;


export default TemplatesList;
