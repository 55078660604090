/// Libraries
import React from "react";
import { Document, StyleSheet, Font } from "@react-pdf/renderer";

// Components
import Intro from "./Intro";
import Methodology from "./Methodology";
import Risks from "./Risks";
import Overview from "./Overview";
import TableOfContents from "./TableOfContents";
import FeedbackSection from "./Feedback";
import FactorOverview from "./FactorOverview";
import CultureMap from "./CultureMap";
import Breakdown from "./Breakdown";
import SportLawIntro from "./SportLaw/SportLawIntro";
import SportLawMethodology from "./SportLaw/MethodologySL";
import SportLawOverview from "./SportLaw/Overview";
import OutcomesBreakdown from "./OutcomesBreakdown";

// Templates
import defaultTemplate from "../templates/default.json";
import sportLawTemplate from "../templates/sport-law.json";
import OTPTemplate from "../templates/OTP.json";

// Utils
import { getFeedback } from "../utils/cultureUtils";

// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";
// import Infograph from "./Infograph";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 60,
    paddingHorizontal: 80,
    fontFamily: "Poppins",
  },
  section: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  title: {
    fontSize: 40,
    textAlign: "center",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  table: {
    marginHorizontal: 10,
  },
  subHeading: {
    fontSize: 12,
    paddingBottom: 50,
  },
  spiderSubHeading: {
    fontSize: 12,
    paddingBottom: 30,
    display: "flex",
    textAlign: "center",
    zIndex: 999,
  },
  heading: {
    paddingBottom: "15px",
    left: 0,
    fontSize: 22,
    fontWeight: 500,
  },
  doc: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
  spiderImage: {
    left: 55,
    top: -28,
    maxHeight: 300,
    maxWidth: 400,
    height: 300,
    width: 400,
    zIndex: -99,
  },
  spiderHeading: {
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: 500,
  },
});

const Template = (props) => {
  const {
    chart,
    nav,
    categories,
    rule,
    structure,
    data,
    reportData,
    calculated,
    anchor,
    config,
    selectedReport,
    selectedOrg,
    WhiteLabel,
    filterData,
    overview,
    spiderOptions,
    spiderTwoOptions,
    sportName,
    // dialOne,
  } = props;

  const { introTemplate } = config?.[0].data;
  const orgTemplate =
    introTemplate === 2
      ? sportLawTemplate
      : introTemplate === 3
      ? OTPTemplate
      : defaultTemplate;

  const isSportLaw = introTemplate === 2;

  // Table of Contents Util functions
  const getFactor = (dimension, factor) => {
    return structure.dimensions[dimension].factors.find((f) => f.id === factor)
      .title;
  };

  const getSelectedSportId = (data) => {
    const responseSample = data.responses;

    const selectedCategory = responseSample?.[0].categories.find(
      (category) => category.priority === "primary"
    );
    return selectedCategory;
  };

  const selectedSport = getSelectedSportId(reportData?.[0]);
  const prevDataFiltered = selectedSport?.subfactor
    ? filterData?.filter_data_last?.filter(
        (fd) =>
          fd?.categories?.find((cat) => cat.priority === "primary")
            .subfactor === selectedSport.subfactor
      )
    : filterData?.filter_data_last;

  let numberOfValidCategoriesGlobal = 0;
  const getQuestionSection = (dimension, factor) => {
    let numberOfValidCategories = 0;
    const arrayOfValidCategories = calculated?.responses
      ?.filter((resp) => resp.average > 0)
      .map((result) => result.role);

    if (numberOfValidCategoriesGlobal === 0) {
      arrayOfValidCategories.forEach((element, index) => {
        if (arrayOfValidCategories.indexOf(element) === index) {
          numberOfValidCategoriesGlobal += 1;
        }
      });
    }
    arrayOfValidCategories.forEach((element, index) => {
      if (arrayOfValidCategories.indexOf(element) === index) {
        numberOfValidCategories += 1;
      }
    });

    const QUESTIONS_PER_PAGE = numberOfValidCategories > 1 ? 2 : 3;
    // Questions are Min/Max Width of 300, this guarentees a maximum of 2 qustions per page

    const questionTotals = get_question_list(
      dimension,
      factor,
      structure
    ).length;
    const fullPages =
      Math.floor(questionTotals / QUESTIONS_PER_PAGE) +
      (numberOfValidCategories === 1 ? 1 : 0);
    const partialPages = QUESTIONS_PER_PAGE % questionTotals > 0 ? 1 : 0;

    return {
      title: getFactor(dimension, factor),
      pages: fullPages + partialPages,
    };
  };

  const get_question_list = (dimension, factor, questions) => {
    return questions.dimensions[dimension].factors.find((f) => f.id === factor)
      .questions;
  };

  // const subSectionOne =
  //   calculated?.responses?.length > 0 &&
  //   structure.dimensions[0].factors.map((item, i) => {
  //     return getQuestionSection(0, item.id);
  //   });

  // const subSectionTwo =
  //   calculated?.responses?.length > 0 &&
  //   structure.dimensions[1].factors.map((item, i) => {
  //     return getQuestionSection(1, item.id);
  //   });

  const getDimensionsAvg = (data, dimension) => {
    const averages = data.summary?.filter(
      (factorSummary) => factorSummary.dimension === dimension.toString()
    );

    let total = 0;
    averages.forEach((avg) => {
      total = total + avg.average;
    });

    return (total / averages.length) % 1 === 0
      ? total / averages.length
      : (total / averages.length).toFixed(2);
  };

  const humanFactorsAvg = getDimensionsAvg(calculated, 0);
  const systemFactorsAvg = getDimensionsAvg(calculated, 1);
  const anchorGroup = categories.categories.find((cat) => cat.id === anchor);
  let totalResponses = 0;

  Object.keys(calculated.overall).forEach((k) => {
    totalResponses = totalResponses + calculated.overall[k].n1;
  });

  const showFeedbackSection = () => {
    let showFeedback = false;

    const options = props.structure?.comments
      ?.filter((item, i) => item?.response?.length > 0)
      .map((item, i) => {
        return {
          text: item.question,
          value: i,
        };
      });

    options?.forEach((option, i) => {
      const feedbackData = getFeedback(
        option.value,
        props.categories,
        props.filterData.filter_data,
        props.structure
      );
      if (feedbackData && feedbackData.length > 0) {
        showFeedback = true;
      }
    });
    return showFeedback;
  };

  const getIntroTemplate = (templateId = 1) => {
    switch (templateId) {
      case 2:
        return (
          <SportLawIntro
            selectedReport={selectedReport}
            selectedOrg={selectedOrg}
            config={orgTemplate}
          />
        );
      default:
        return (
          <Intro
            config={config}
            selectedReport={selectedReport}
            selectedOrg={selectedOrg}
            overview={overview}
            anchor={anchorGroup}
            totalResponses={totalResponses}
            sportName={sportName}
          />
        );
    }
  };

  const getMethodologyTemplate = (templateId = 1) => {
    switch (templateId) {
      case 2:
        return (
          <SportLawMethodology
            anchor={anchor}
            structure={structure}
            categories={categories}
            data={reportData}
            nav={nav}
            rule={rule}
            config={orgTemplate}
          />
        );
      default:
        return (
          <Methodology
            anchor={anchor}
            structure={structure}
            categories={categories}
            data={reportData}
            previous={prevDataFiltered}
            nav={nav}
            rule={rule}
            config={orgTemplate}
            questions={structure}
          />
        );
    }
  };

  const getOverviewTemplate = (templateId = 1) => {
    switch (templateId) {
      case 2:
        return (
          <SportLawOverview
            reportData={reportData}
            nav={nav}
            categories={categories}
            rule={rule}
            anchor={anchor}
            structure={structure}
            {...props}
            config={orgTemplate}
            isSportLaw={isSportLaw}
          />
        );
      default:
        return (
          <Overview
            reportData={reportData}
            nav={nav}
            categories={categories}
            rule={rule}
            anchor={anchor}
            structure={structure}
            {...props}
            previous={prevDataFiltered}
            overviewTitles={[
              structure.dimensions[0].title,
              structure.dimensions[1].title,
            ]}
            config={orgTemplate}
          />
        );
    }
  };

  const intro = getIntroTemplate(introTemplate);
  const overviewSection = getOverviewTemplate(introTemplate, isSportLaw);

  return (
    <Document style={styles.doc}>
      {/* INTRO PAGE */}
      {intro}

      {/* <Infograph config={orgTemplate} structure={structure} /> */}
      {/* TABLE OF CONTENTS SECTION */}
      {/* <TableOfContents
        // showFeedbackSection={showFeedbackSection()}
        subSections={[subSectionOne, subSectionTwo]}
        overviewTitles={[
          structure.dimensions[0].title,
          structure.dimensions[1].title,
        ]}
        config={orgTemplate}
        introTemplate={introTemplate}
        anchor={anchor}
        categories={categories}
        numberOfValidCategories={numberOfValidCategoriesGlobal}
      /> */}

      {/* METHODOLOGY */}
      {/* {methodology} */}

      {/* SECTION INTRO
      <SectionIntro title={"Introduction to Culture Factors"} /> */}

      {/* FACTOR BREAKDOWN */}
      <FactorOverview
        humanFactorData={spiderOptions}
        humanOverallAverage={humanFactorsAvg}
        calculated={calculated}
        systemFactorData={spiderTwoOptions}
        systemOverallAverage={systemFactorsAvg}
        config={orgTemplate}
        questions={structure}
        isSportLaw={isSportLaw}
        overviewTitles={[
          structure.dimensions[0].title,
          structure.dimensions[1].title,
        ]}
        previousData={prevDataFiltered}
        outcomesData={props?.outcomesData}
        outcomesQuestions={props?.outcomesQuestions}
      />

      {/* RISK SECTION */}
      {/* {introTemplate === 1 && (
        <Risks
          data={calculated.responses}
          calculated={calculated}
          question={structure}
          categories={categories}
          anchor={anchor}
          config={orgTemplate}
          nav={nav}
        />
      )} */}

      {/* CULTURE INDEX MAP */}
      <CultureMap chart={chart} config={orgTemplate} isSportLaw={isSportLaw} />

      {/* CONTENT SECTION */}
      {/* <ContentBlock title={"Getting Culture Right Starts Here"} /> */}

      {/* QUESTION BREAKDOWN */}
      {calculated?.responses?.length > 0 &&
        structure.dimensions[0].factors.map((item, i) => {
          // if (i > 2) {
          //   return null;
          // }
          return (
            <Breakdown
              index={i}
              key={i}
              overviewTitle={structure.dimensions[0].title}
              question={item}
              dimension={0}
              info={calculated.responses}
              questions={structure}
              anchor={anchor}
              categories={categories.categories}
              config={orgTemplate}
              navBar={nav}
              filterData={filterData}
              previous={prevDataFiltered}
              isSportLaw={isSportLaw}
            />
          );
        })}
      {calculated?.responses?.length > 0 &&
        structure.dimensions[1].factors.map((item, i) => {
          return (
            <Breakdown
              key={i}
              question={item}
              dimension={1}
              info={calculated.responses}
              questions={structure}
              config={orgTemplate}
              categories={categories.categories}
              anchor={anchor}
              overviewTitle={structure.dimensions[1].title}
              filterData={filterData}
              navBar={nav}
              previous={prevDataFiltered}
              isSportLaw={isSportLaw}
            />
          );
        })}
      {props?.outcomesData &&
        props?.outcomesQuestions &&
        props?.outcomesQuestions?.length > 0 &&
        props?.outcomesQuestions?.map((outcome, i) => (
          <OutcomesBreakdown
            index={i}
            key={i}
            outcome={outcome}
            data={props?.outcomesData[i]}
            config={orgTemplate}
            isSportLaw={isSportLaw}
          />
        ))}

      {/* GLIMPSE SECTION */}
      {overviewSection}

      {/* FEEDBACK SECTION */}
      {showFeedbackSection() && introTemplate === 2 && props.showFeedback && (
        <FeedbackSection
          filterData={filterData.filter_data}
          WhiteLabel={WhiteLabel}
          data={data}
          structure={structure}
          categories={categories}
          config={orgTemplate}
          isSportLaw={isSportLaw}
        />
      )}
    </Document>
  );
};

export default Template;
