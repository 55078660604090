import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import { Modal, Button, Dropdown } from "semantic-ui-react";
import {
  SG_UPDATE_EMPLOYEE_AUDIT,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_ADD_MANAGER_USER,
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { deleteIcon } from "../../reports/components/Icons";

const MANAGER_PERMISSIONS = [
  {
    permissionLabel: "Is a manager",
    permissionDescription: "Member will have access to the manager dashboard.",
    permissionValue: "is_manager",
  },
];

// Default payload for managers
const generatePayload = (employeeId) => {
  const payload = {
    enable_login: true,
    // first_name: ,
    // last_name: ,
    survey_add_users: 1,
    survey_schedule: 1,
    survey_status: 1,
    read_feedback: 1,
    // access_subfactor_data: ,
    access_data_analytics: 1,
    id: employeeId,
  };

  return payload;
};

const generateAuthDetails = (employeeId, permissions) => {
  const usersPermissionObject = permissions?.find(
    (permission) => permission?.employee_id === employeeId
  )?.details;

  return usersPermissionObject;
};

const structurePayload = (users, currentManagers) => {
  const employee_category_list = [];
  const employeeIds = users.map((user) => user.id);

  users.forEach((user) => {
    const userId = user.id;
    const empCategory = user?.data?.currentPermissions;

    // Only add the category if it hasn't been added already
    if (
      !empCategory?.find(
        (cat) =>
          cat?.name_id === user?.data?.detailedPermissions?.name_id &&
          cat?.value_id === user?.data?.detailedPermissions?.value_id
      )
    ) {
      employee_category_list?.push({
        employee_id: userId,
        employee_category: user?.data?.detailedPermissions,
      });
    }
  });

  // Ensure all the ids in employeeIds are unique
  const uniqueIds = [...new Set(employeeIds)];
  // if the user is already a manager, remove them from the unique list.
  const filteredIds = uniqueIds.filter((id) => {
    return !currentManagers.find((manager) => manager.id === id);
  });

  return {
    employee_ids: filteredIds,
    employee_category_list: employee_category_list,
    active: 1,
    manager: 1,
    access: 1,
  };
};

const AddUser = () => {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const dispatch = useDispatch();

  const {
    get_auth,
    get_employee_org_category,
    core_data,
    get_employee_permission,
    user_manager,
    get_employees,
  } = useSelector(
    (state) => ({
      get_employees: state.employees,
      user_manager: state.user_manager,
      get_auth: state.auth,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_error: state.errors,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  const getCategoryOptions = (data) => {
    const categories = data?.[0]?.categories?.filter(
      (cat) => !cat?.demographic
    );

    let options = categories?.flatMap((cat) => {
      return cat?.options?.map((opt) => {
        return {
          key: opt.id,
          text: `${cat?.name} - ${opt?.name}`,
          value: opt.id,
        };
      });
    });

    return options;
  };

  // Dispatch Calls
  useEffect(() => {
    if (get_auth?.organization_id) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `organization=${get_auth?.organization_id}`,
      });

      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `organization=${get_auth?.organization_id}`,
      });
    }
  }, [dispatch, get_auth]);

  const add = (category, addedUser) => {
    const primaryOptions = core_data?.categories?.[0]?.categories?.flatMap(
      (item) => item?.options
    );

    const userObject = get_employee_org_category?.find(
      (user) => user?.employee_id === addedUser
    );

    // Create an array of the selected categories, pulled from the primaryOptions array
    const selectedCategories = primaryOptions.filter((option) =>
      category.includes(option.id)
    );

    // Currently there will be only 1 selected category, however I'm keeping
    // the code like this because the end result doesn't change, and
    // it will be easier to expand in the future if needed
    const detailedPermissions = selectedCategories.map((category) => {
      const parentCategory = core_data?.categories?.[0]?.categories?.find(
        (cat) => cat.options?.find((op) => op.id === category.id)
      );

      const option = parentCategory.options.find((op) => op.id === category.id);

      return {
        name_id: parentCategory.id,
        name: parentCategory.name,
        listValue: `${parentCategory.name} - ${option.name}`,
        value: option.name,
        text: option.name,
        value_id: option.id,
        priority: parentCategory.priority,
      };
    });

    const currentPerms = generateAuthDetails(
      userObject?.employee_id,
      get_employee_permission?.employee_permission
    );

    const structuredUser = {
      id: userObject?.employee_id,
      name: userObject?.employee_full_name,
      data_access: detailedPermissions?.[0]?.listValue,
      new_object: {
        first_name: userObject?.employee_full_name?.split(" ")[0],
        last_name: userObject?.employee_full_name?.split(" ")[1],
        id: userObject?.employee_id,
        status: 1,
        email_status: 1,
      },
      data: {
        user: userObject,
        currentPermissions: currentPerms,
        categories: selectedCategories,
        permissionsPayload: generatePayload(userObject?.employee_id),
        detailedPermissions: {
          name: detailedPermissions?.[0]?.name,
          value: detailedPermissions?.[0]?.value,
          name_id: detailedPermissions?.[0]?.name_id,
          priority: detailedPermissions?.[0]?.priority,
          value_id: detailedPermissions?.[0]?.value_id,
        },
      },
    };

    setUsers([...users, structuredUser]);
    setSelectedCategory(null);
    setSelectedUser(null);
  };

  const handleSave = (users) => {
    const payload = structurePayload(
      users,
      user_manager?.managerUsers?.results
    );
    const newAdditions = users?.map?.((user) => {
      return user?.new_object;
    });

    const permissionsPayloads = users?.map?.((user) => {
      return user?.data?.permissionsPayload;
    });

    dispatch({
      type: SG_ADD_MANAGER_USER,
      payload: payload,
      newAdditions: newAdditions,
    });

    // Loop through permissionsPayloads and dispatch the update to each
    permissionsPayloads.forEach((payload) => {
      dispatch({
        type: SG_UPDATE_EMPLOYEE_AUDIT,
        payload: payload,
      });
    });

    setUsers([]);
  };

  const removeUser = (user) => {
    setUsers(users.filter((u) => u.id !== user.id));
  };

  const userMap = (usersList, employeeList) => {
    const list = usersList?.map((user) => {
      const employeeObj = employeeList?.employees?.find(
        (emp) => emp.id === user?.employee_id
      );
      return {
        key: user?.employee_id,
        value: user?.employee_id,
        text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
        id: user?.id,
      };
    });

    const empList = employeeList?.employees;
    const inactiveList = empList?.filter(
      (emp) => emp.status === 99 || emp.status === 98
    );

    const filteredList = list.filter(
      (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
    );

    return filteredList;
  };

  return (
    <Modal
      trigger={<Button>Add member</Button>}
      onClose={() => {
        setSelectedCategory(null);
        setSelectedUser(null);
        setUsers([]);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      size="small"
      closeIcon
      open={open}
    >
      <Modal.Header>Add members to share data</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Title>Select members and data access</Title>
          <Description>
            Select one or more members you'd like to give data access to. The
            category you select will be the only data this user can access.
          </Description>
          <Row>
            <DropdownContainer>
              <Label>Member</Label>
              <Dropdown
                placeholder="Select a user"
                fluid
                search
                value={selectedUser}
                selection
                onChange={(e, { value }) => setSelectedUser(value)}
                options={userMap(get_employee_org_category, get_employees)}
              />
            </DropdownContainer>

            <DropdownContainer>
              <Label>Data access</Label>
              <Dropdown
                placeholder="Select a category"
                fluid
                search
                value={selectedCategory}
                selection
                onChange={(e, { value }) => setSelectedCategory(value)}
                options={getCategoryOptions(core_data?.categories)}
              />
            </DropdownContainer>

            <Button
              disabled={!selectedCategory || !selectedUser}
              onClick={() => add(selectedCategory, selectedUser)}
              style={{ height: 35 }}
            >
              + Add
            </Button>
          </Row>

          <UserTable onMouseOut={() => setHoveredRow(null)}>
            <Header>
              <T1 width={40}>Member</T1>
              <T1 width={60}>Data Access</T1>
            </Header>

            {users.map((user, i) => {
              return (
                <Row2
                  key={i}
                  onMouseOver={() => setHoveredRow(i)}
                  style={{
                    backgroundColor:
                      hoveredRow === i ? "#f0f0f0" : "transparent",
                  }}
                >
                  <T2 width={40}>{user.name}</T2>
                  <T2
                    width={60}
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <ActionRow>
                      {user.data_access}
                      <DeleteButton
                        onClick={() => removeUser(user)}
                        onMouseOver={() => setHoveredRow(i)}
                      >
                        {deleteIcon()}
                      </DeleteButton>
                    </ActionRow>
                  </T2>
                </Row2>
              );
            })}
          </UserTable>

          <Bottom />
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>

        <Button
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            handleSave(users);
            setOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};;

export default AddUser;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
`;

const DeleteButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666d79;
  font-size: 12px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
`;

const Row2 = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
`;

const DropdownContainer = styled.div`
  margin-right: 20px;
`;

const Label = styled.div`
  font-size: 12px;
  font-family: "Raleway", sans-serif;
`;

const UserTable = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  font-family: "Raleway", sans-serif;
  margin-top: 40px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
`;

const T1 = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
`;

const T2 = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
`;

const Bottom = styled.div`
  margin-top: 50px;
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;