import React from "react";
import styled from "styled-components";

// Create a simple tabs component that takes in a list of tab names and a function to set the selected tab
// it should render the tab names and call the function when a tab is clicked
// the selected tab should be highlighted in some way
const Tabs = ({ tabs, setSelectedTab, selectedTab }) => {
  return (
    <TabContainer>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          onClick={() => setSelectedTab(index)}
          selectedTab={selectedTab === index}
        >
          {tab}
        </Tab>
      ))}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-family: "Raleway";
  font-size: 12px;
  font-weight: 600;
  color: ${({ selectedTab }) => (selectedTab ? "#2d70e2" : "#2a3039")};
  border-bottom: ${({ selectedTab }) =>
    selectedTab ? "2px solid #2d70e2" : "none"};
  margin-right: 10px;
  transition: all 0.3s;
  &:hover {
    color: #2d70e2;
  }
`;

export default Tabs;
