import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
//
import AuditSurvey from "auditSurvey/indexV2";
import AuditReport from "reports/Audit/index";
import Categories from "settings/Categories/index";
import NotSubscribed from "404/NotSubscribed";
import Action from "reports/Action";
import PDFReport from "reports/PDFReport/index";
import GeneratePDF from "reports/PDFReport/GenerateWindow";
import DataScience from "reports/Audit/DataScience/index";
import TeamSummary from "reports/Audit/TeamSummary/index";
import AI from "reports/AI/index";

import Home from "home/index";
import ActionAnalysis from "reports/ActionAnalysis/index";
import Archive from "reports/Action/Archive";
import Recommendations from "reports/Recommendations/index";
import Outcomes from "reports/Outcomes/index";
import Dashboard from "reports/Dashboard/index";
import Feedback from "reports/Feedback/index";
import Engagement from "reports/Engagement/index";
import TagAnalytics from "reports/Tag";
import Pillar from "reports/Pillar/index";
import RecommendationBuilder from "reports/RecommendationBuilder";
import RecommendationPDF from "reports/RecommendationPDF/index";
import Retention from "reports/Retention/index";
import Test from "reports/AI/ChartComponents/test";
import RecV2 from "reports/RecommendationsV2/index";
import DataStory from "reports/DataStories/index";
import DataStoryDashboard from "reports/DataStories/dashboard/index";
import DataJourneyDashboard from "reports/DataJourney/dashboard/index";
import DataJourney from "reports/DataJourney/index";
import SimpleAnalytics from "app/audit/simple/index";
import LeaderRoutes from "./LeaderRoutes";
import PendingList from "components/CultureAudit/PendingList_V2"
import HistoryList from "components/CultureAudit/HistoryList_V2"

//
import Lost from "404";
import { useLocation } from "react-router-dom";

import { SG_GET_CULTURE_AUDIT_REPORTS } from "constants/actions";

function RoutesCultureAudit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [surveyType, setSurveyType] = useState();
  const [auditTypeId, setAuditTypeId] = useState();
  const isDEI = location.pathname.includes("edi");
  const simpleRoutes = [];
  const leaderRoutes = ["/simple", "/leader/audit", "/leader/manage"];
  const whiteListedRoutes = ["/leader/audit/calendar"];

  const isSimpleRoute = simpleRoutes.some((route) =>
    location.pathname.includes(route)
  );

  const isLeaderRoute = leaderRoutes.some((route) =>
    location.pathname.includes(route)
  );

  const isWhiteListedRoute = whiteListedRoutes.some((route) =>
    location.pathname.includes(route)
  );

  const isSimple = isSimpleRoute && !isWhiteListedRoute;
  const isLeader = isLeaderRoute && !isWhiteListedRoute;

  const {
    get_auth,
    get_organizations,
    get_selectedOrg,
    get_employees,
    get_culture_audit_reports,
    get_survey_structure,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_error: state.errors,
      get_organizations: state.organizations,
      get_selectedOrg: state.selectedOrg,
      get_employees: state.employees,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );

  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees[get_auth.employee_id]);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth, EmployeeId, get_employees]);

  // State setup
  useEffect(() => {
    let surveyType;
    let auditTypeId;
    if (get_organizations) {
      surveyType = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled
        ? 8
        : isDEI
        ? 7
        : 6;
      auditTypeId = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled
        ? 5
        : isDEI
        ? 4
        : 3;

      setSurveyType(surveyType);
      setAuditTypeId(auditTypeId);
    }
  }, [get_organizations]);

  useEffect(() => {
    if (auditTypeId) {
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}&organization=${get_selectedOrg?.organization?.id}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${auditTypeId}`,
        });
      }
    }
  }, [dispatch, auditTypeId, ambassadorId, get_selectedOrg?.organization?.id]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  useEffect(() => {
    if (isSimple || isLeader) {
      return setValidRoute(["Audit"]);
    }
    const Found = organization?.services_enabled
      ?.filter((srv) => srv?.enabled === true)
      .map((sr) => sr.name);

    setValidRoute(Found);
  }, [organization]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!validRoute?.includes("Audit")) {
        return navigate("/app/unsubscribed");
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization, validRoute, navigate]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    if (validRoute) {
      if (validRoute?.includes("Teamtool")) {
        if (employee?.account_type > 7) {
          if (employee?.role > 7) {
            return navigate("/app/access-denied");
          }
        }
      }
    }
  }, [employee, validRoute, navigate]);

  return (
    <>
      {validRoute && isLeader && <LeaderRoutes />}
      {!isSimple && !isLeader && validRoute && (
        <Routes>
          <Route path="calendar" element={<PendingList />} />
          <Route path={"report"} element={<AuditReport />} />
          <Route path={"category"} element={<Categories />} />
          <Route path={"pdf"} element={<PDFReport />} />
          <Route path={"generate-pdf"} element={<GeneratePDF />} />
          <Route path={"action"} element={<Action />} />
          <Route path={"data_science"} element={<DataScience />} />
          <Route path={"recommendations"} element={<Recommendations />} />
          <Route path={"outcomes"} element={<Outcomes />} />
          <Route path={"group"} element={<TeamSummary />} />
          <Route path={"dashboard"} element={<Dashboard />} />
          <Route path={"feedback"} element={<Feedback />} />
          <Route path={"engagement"} element={<Engagement />} />
          <Route path={"tag"} element={<TagAnalytics />} />
          <Route
            path={"recommendation-builder"}
            element={<RecommendationBuilder />}
          />
          <Route path={"pillar"} element={<Pillar />} />
          <Route path={"RecPDF"} element={<RecommendationPDF />} />
          <Route path={"Retention"} element={<Retention />} />
          <Route path={"AI"} element={<AI />} />
          <Route path={"*"} element={<Lost />} />
          <Route path={"action-debrief"} element={<ActionAnalysis />} />
          <Route path={"action-archive"} element={<Archive />} />
          <Route path={"test"} element={<Test />} />
          <Route path={"rec"} element={<RecV2 />} />
          <Route path={"rec/:id"} element={<RecV2 />} />
          <Route path={"datastory"} element={<DataStoryDashboard />} />
          <Route path={"datastory/:id"} element={<DataStory />} />
          <Route path={"journeys"} element={<DataJourneyDashboard />} />
          <Route path={"journeys/:id"} element={<DataJourneyDashboard />} />
          <Route path={"journey"} element={<DataJourney />} />
          <Route path={"simple/:id"} element={<SimpleAnalytics />} />
          <Route path={"calendar/active"} element={<PendingList/>} />
          <Route path={"calendar/history"} element={<HistoryList/>} />

        </Routes>
      )}
    </>
  );
}


export default RoutesCultureAudit;