import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import MarkdownRenderer from "./markdown";
import { Icon } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { SG_FETCH_DEBRIEF_PDF_REPORT } from "constants/actions";

const InsightsV2 = () => {
  const { id, share_id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  let navigate = useNavigate();

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_DEBRIEF_PDF_REPORT,
        payload: {
          id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (pdfReports?.[id]) {
      const markdownData = pdfReports?.[id]?.additional_info?.find(
        (item) => item.share_id === share_id
      );
      setData(markdownData?.json_data);
    }
  }, [pdfReports]);

  return (
    <Container>
      <Content>
        <BackButton onClick={() => navigate("/leader/audit/simple/insights")}>
          <Icon name="chevron left" style={{ marginTop: -7 }} />
          Back
        </BackButton>
        <Title>Data Story</Title>

        {data && (
          <MarkdownRenderer
            markdown={data?.resp}
            tldr={data?.tldr}
            quotes={data?.quotes}
          />
        )}
      </Content>
    </Container>
  );
};

export default InsightsV2;

const Container = styled.div`
  padding: 20px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 700px;
`;

const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #2d70e2;
  margin-bottom: 10px;
  font-weight: bold;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;
