import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Empty from "../../../reports/Empty";
import New from "./New";
import List from "./List";
import Tabs from "./Tabs";
import { useToasts } from "react-toast-notifications";
import PendingList from "./Pending";
import {
  SG_GET_TASK_SCHEDULES,
  SG_ADD_TASK_TEMPLATE,
  SG_ADD_USER_TASK,
  SG_DELETE_USER_TASK,
  SG_GET_TASK_TEMPLATE_SELECT,
} from "constants/actions";
import { Dimmer, Loader, Modal } from "semantic-ui-react";

const transformData = (data) => {
  // TODO: Do something about the null values.
  return data?.map((result) => {
    return {
      id: result.id,
      name: result.name,
      // created_by: result.created_by,
      // updated_at: result.updated_at,
      status: result.status_text,
      // assignees: result.assigned_employees,
      questions: null,
      created_by: null,
      updated_at: null,
      assignees: null,
    };
  });
};

const TaskTemplates = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { addToast } = useToasts();

  const { coreData, taskTemplates, task_templates_select_list, created } =
    useSelector(
      (state) => ({
        coreData: state.audit?.core_data,
        taskTemplates: state.task_templates?.scheduled_tasks,
        task_templates_select_list:
          state.task_templates?.task_templates_select_list,
        created: state.task_templates?.created,
      }),
      shallowEqual
    );

  const addNewTemplate = (template) => {
    const formattedPayload = {
      name: template.name,
      tracked_user_tasks: {
        task_description: template.description,
        task_questions: template?.questions,
      },
      assigned_employees: template.assignees,
    };

    dispatch({ type: SG_ADD_TASK_TEMPLATE, payload: formattedPayload });
    addToast("Template added successfully", { appearance: "success" });
    setSelectedTab(1);
  };

  useEffect(() => {
    if (created) {
      dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    }
  }, [created, dispatch]);

  useEffect(() => {
    dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    dispatch({ type: SG_GET_TASK_SCHEDULES });
  }, []);

  useEffect(() => {
    if (
      taskTemplates?.results?.length > 0 &&
      task_templates_select_list?.length > 0
    ) {
      setTemplates(transformData(task_templates_select_list));
    }
  }, [taskTemplates, task_templates_select_list]);

  // Create a function to edit a template
  // it should take the template object as an argument
  // and update the template with the same id in the templates array
  const editTemplate = (template) => {
    addToast("Template updated successfully", { appearance: "success" });
  };

  return (
    // Create a simple placeholder component that displays the text "Task Templates"
    // it should also contain a tab conditionally rendering either the List or New component
    <Container>
      <UpdateModal
        edit={(template) => editTemplate(template)}
        open={updateModal}
        setOpen={setUpdateModal}
        template={selectedTemplate}
        coreData={coreData}
      />
      <Center>
        <Title>Custom Surveys</Title>
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={["Survey Templates", "Pending/Completed"]}
        />
        {selectedTab === 0 ? (
          <New
            add={(template) => addNewTemplate(template)}
            templates={templates}
            setUpdateModal={setUpdateModal}
            edit={(template) => editTemplate(template)}
          />
        ) : (
          <PendingList tasks={templates} />
        )}
      </Center>
    </Container>
  );
};

const UpdateModal = ({ open, setOpen, template, edit, coreData }) => {
  const [name, setName] = useState(template?.name);
  const [description, setDescription] = useState(template?.description);
  const [questions, setQuestions] = useState(template?.questions);
  const [assignees, setAssignees] = useState(template?.assignees);

  const handleUpdate = () => {
    edit({
      id: template?.id,
      name,
      description,
      questions,
      assignees,
    });
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="small">
      <Modal.Header>Edit Template</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Title>Name</Title>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
          />
          <Title>Description</Title>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
          />
          <Title>Questions</Title>
          <Questions
            questions={questions}
            setQuestions={setQuestions}
            assignees={assignees}
          />
          <Title>Assignees</Title>
          <Assignees
            coreData={coreData}
            assignees={assignees}
            setAssignees={setAssignees}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleUpdate}>Update</Button>
      </Modal.Actions>
    </Modal>
  );
};

const Assignees = ({ assignees, setAssignees, coreData }) => {
  const [selected, setSelected] = useState();

  const handleSelect = (employee) => {
    if (selected === employee) {
      setSelected(null);
      setAssignees(assignees.filter((a) => a.id !== employee.id));
    } else {
      setSelected(employee);
      setAssignees([...assignees, employee]);
    }
  };

  return (
    <AssigneeContainer>
      {coreData?.employees?.map((employee) => (
        <Assignee
          key={employee.id}
          selected={selected?.id === employee.id}
          onClick={() => handleSelect(employee)}
        >
          {employee.first_name} {employee.last_name}
        </Assignee>
      ))}
    </AssigneeContainer>
  );
};

const Questions = ({ questions, setQuestions, assignees }) => {
  const [question, setQuestion] = useState("");
  const [type, setType] = useState("text");

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question,
        type,
        aiEnhanced: type === "ai",
      },
    ]);
    setQuestion("");
  };

  return (
    <QuestionContainer>
      <Input
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Enter question"
      />
      <Select value={type} onChange={(e) => setType(e.target.value)}>
        <option value="text">Text</option>
        <option value="ai">AI</option>
      </Select>
      <Button onClick={addQuestion}>Add</Button>
      {questions?.map((q, index) => (
        <Question key={index}>
          {q.question} - {q.type}
        </Question>
      ))}
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Question = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
`;

const Select = styled.select`
  font-family: "Inter", sans-serif !important;
  border-radius: 20px !important;
  font-size: 10px !important;
  color: #7e7e7e;
`;

const AssigneeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const Assignee = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#2d70e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d70e2")};
  border: 1px solid #2d70e2;
`;

const TextArea = styled.textarea`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
  height: 100px;
  border-radius: 5px;
  padding: 10px;
`;

const Input = styled.input`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  margin-top: 0px;
  animation: ${fadeIn} 0.3s;
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

export default TaskTemplates;