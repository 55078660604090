import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Dimmer,
  Loader,
  Modal,
  Icon,
  Accordion,
  Dropdown,
  Tab,
} from "semantic-ui-react";
import { format } from "date-fns";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SG_GET_TASK_SCHEDULES,
  SG_GET_TASK_TEMPLATES,
  SG_ADD_USER_TASK,
  SG_DELETE_USER_TASK,
} from "constants/actions";
import { useToasts } from "react-toast-notifications";

const statusColors = {
  Scheduled: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
  Error: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  Completed: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  Pending: {
    container: "#F3F7FF",
    text: "#2D70E2",
  },
  New: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  "In Progress": {
    container: "#F3F7FF",
    text: "#2D70E2",
  },
  Active: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
};

const Assignees = ({ assignees, template }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignedUsersList, setAssignedUsersList] = useState(assignees);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [removedUsers, setRemovedUsers] = useState([]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { get_employee_org_category, get_employees, user_manager } =
    useSelector(
      (state) => ({
        get_employees: state.employees,
        get_employee_org_category:
          state.employee_category?.employee_org_category,
        user_manager: state.user_manager,
      }),
      shallowEqual
    );

  const handleAddSave = () => {
    const payload = {
      task_ref_id: template?.task_ref_id,
      employee_id_list: assignedUsersList,
    };

    dispatch({ type: SG_ADD_USER_TASK, payload });
    addToast("Assignees added successfully", {
      appearance: "success",
    });
  };

  const handleRemoveSave = () => {
    const payload = {
      task_ref_id: template?.task_ref_id,
      employee_id_list: removedUsers,
    };

    dispatch({ type: SG_DELETE_USER_TASK, payload });
    addToast("Assignees removed successfully", {
      appearance: "success",
    });
  };

  const handleUserAdd = (value) => {
    if (!assignedUsersList.includes(Number(value))) {
      setAssignedUsersList([...assignedUsersList, Number(value)]);
      const user = get_employee_org_category.find(
        (u) => u.employee_id === value
      );
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleUserRemove = (user) => {
    setRemovedUsers([...removedUsers, user]);
    setAssignedUsersList(
      assignedUsersList.filter((id) => id !== user.employee_id)
    );
  };

  const userMap = (usersList, employeeList, managers) => {
    const list = usersList?.map((user) => {
      const employeeObj = employeeList?.employees?.find(
        (emp) => emp.id === user?.employee_id
      );
      return {
        key: user?.employee_id,
        value: user?.employee_id,
        text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
        id: user?.id,
      };
    });

    const empList = employeeList?.employees;
    const inactiveList = empList?.filter(
      (emp) => emp.status === 99 || emp.status === 98
    );

    const filteredList = list.filter(
      (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
    );

    // Filter list so that only the managers are returned.
    if (managers) {
      const managerIds = managers.map((manager) => manager.id);
      const filteredManagers = filteredList.filter((emp) =>
        managerIds.includes(emp.key)
      );
      return filteredManagers;
    }

    return filteredList;
  };

  const panes = [
    {
      menuItem: "Add",
      render: () => (
        <Tab.Pane>
          <SectionDescription>
            Select members to add to this survey's assignee list
          </SectionDescription>
          <SubInfo>
            When you select a member, they will receive an email notifying them
            that they have a survey available.
          </SubInfo>
          <ContainerRow>
            <Dropdown
              placeholder="Select a user"
              fluid
              search
              value={selectedUsers}
              selection
              onChange={(e, { value }) => handleUserAdd(value)}
              options={userMap(
                get_employee_org_category,
                get_employees,
                user_manager?.managerUsers?.results
              )}
            />
          </ContainerRow>
          <ContainerRow>
            <AssigneeTable onMouseOut={() => setHoveredRow(null)}>
              <AssigneeHeader>
                <AssigneeHeaderCell width={50}>Name</AssigneeHeaderCell>
                <AssigneeHeaderCell width={50}>Actions</AssigneeHeaderCell>
              </AssigneeHeader>
              {selectedUsers?.map((user, i) => (
                <AssigneeRow
                  key={user.employee_id}
                  onMouseOver={() => setHoveredRow(i)}
                  style={{
                    backgroundColor:
                      hoveredRow === i ? "#f0f0f0" : "transparent",
                  }}
                >
                  <AssigneeCell width={50}>
                    {user.employee_full_name}
                  </AssigneeCell>
                  <AssigneeCell
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter(
                            (u) => u.employee_id !== user.employee_id
                          )
                        );
                        setAssignedUsersList(
                          assignedUsersList.filter(
                            (id) => id !== user.employee_id
                          )
                        );
                      }}
                    >
                      <Icon name="trash" />
                    </DeleteButton>
                  </AssigneeCell>
                </AssigneeRow>
              ))}
            </AssigneeTable>
          </ContainerRow>
          <ContainerActions>
            <Button
              enabled={true}
              onClick={() => {
                setSelectedUsers([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // assign(assignedUsersList);
                // setModalOpen(false);
                if (selectedUsers.length === 0) {
                  return;
                }
                handleAddSave();
                setSelectedUsers([]);
              }}
              enabled={selectedUsers.length > 0}
              positive
            >
              Save
            </Button>
          </ContainerActions>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Remove",
      render: () => (
        <Tab.Pane>
          <SectionDescription>
            Select members to remove from this survey's assignee list
          </SectionDescription>
          <SubInfo>
            When you remove a member, they will no longer have access to this
            survey.
          </SubInfo>
          <ContainerRow>
            <AssigneeTable onMouseOut={() => setHoveredRow(null)}>
              <AssigneeHeader>
                <AssigneeHeaderCell width={50}>Name</AssigneeHeaderCell>
                <AssigneeHeaderCell width={50}>Actions</AssigneeHeaderCell>
              </AssigneeHeader>
              {assignedUsersList
                ?.filter((user, i) => !removedUsers.includes(user))
                ?.map((user, i) => {
                  const userObj = get_employee_org_category.find(
                    (u) => u.employee_id === user
                  );
                  return (
                    <AssigneeRow
                      key={user.employee_id}
                      onMouseOver={() => setHoveredRow(i)}
                      style={{
                        backgroundColor:
                          hoveredRow === i ? "#f0f0f0" : "transparent",
                      }}
                    >
                      <AssigneeCell width={50}>
                        {userObj.employee_full_name}
                      </AssigneeCell>
                      <AssigneeCell
                        width={50}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <DeleteButton onClick={() => handleUserRemove(user)}>
                          <Icon name="trash" />
                        </DeleteButton>
                      </AssigneeCell>
                    </AssigneeRow>
                  );
                })}
            </AssigneeTable>
          </ContainerRow>
          <ContainerActions>
            <Button
              enabled={true}
              onClick={() => {
                // Reset list of removed users
                setRemovedUsers([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // Handle actual API action for removing users
                // setModalOpen(false);
                if (removedUsers.length === 0) {
                  return;
                }
                handleRemoveSave();
              }}
              enabled={removedUsers.length > 0}
              positive
            >
              Save
            </Button>
          </ContainerActions>
        </Tab.Pane>
      ),
    },
  ];

  return <Tab panes={panes} />;
};

const mapTemplatesToTask = (task) => {
  const mapped = task?.results.map((task) => {
    task.questions = [];
    task.created_by = null;
    task.description = null;
    return task;
  });

  return mapped;
};

const PendingList = ({ setDeleteId, setDeleteItem }) => {
  const [loading, setLoading] = useState(true);
  const [activeTask, setActiveTask] = useState(null);
  const [formattedTasks, setFormattedTasks] = useState([]);
  const dispatch = useDispatch();

  const { scheduledTasks, taskTemplates } = useSelector(
    (state) => ({
      scheduledTasks: state.task_templates.scheduled_tasks,
      taskTemplates: state.task_templates.task_templates,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (scheduledTasks) {
      setLoading(false);
    }
  }, [scheduledTasks]);

  useEffect(() => {
    dispatch({
      type: SG_GET_TASK_SCHEDULES,
    });

    if (!taskTemplates?.results) {
      dispatch({ type: SG_GET_TASK_TEMPLATES });
    }
  }, []);

  console.log(scheduledTasks, taskTemplates);
  useEffect(() => {
    if (scheduledTasks?.results?.length > 0) {
      const mappedTasks = mapTemplatesToTask(scheduledTasks);
      setFormattedTasks(mappedTasks);
    }
  }, [scheduledTasks, taskTemplates]);

  return (
    <>
      <TaskModal task={activeTask} onClose={() => setActiveTask(null)} />
      {!loading &&
        formattedTasks?.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            onClick={() => setActiveTask(task)}
          />
        ))}
    </>
  );
};

const TaskModal = ({ task, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (e, { index }) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Modal size="small" open={task !== null} onClose={() => onClose()}>
      <Modal.Header>{task?.name}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <TopDescription>Information about the survey</TopDescription>
          <TaskInfoContainer>
            <TaskInfoSection>
              <TaskInfoTitle>Created By</TaskInfoTitle>
              <TaskInfoValue>{task?.created_by?.name}</TaskInfoValue>
            </TaskInfoSection>
            <TaskInfoSection>
              <TaskInfoTitle>Last Updated</TaskInfoTitle>
              <TaskInfoValue>
                {task?.updated_at &&
                  format(new Date(task?.updated_at), "MM/dd/yyyy")}
              </TaskInfoValue>
            </TaskInfoSection>
            <TaskInfoSection>
              <TaskInfoTitle>Creation Date</TaskInfoTitle>
              <TaskInfoValue>
                {task?.created_at &&
                  format(new Date(task?.created_at), "MM/dd/yyyy")}
              </TaskInfoValue>
            </TaskInfoSection>
            {/* <TaskInfoSection>
              <TaskInfoTitle>Completion Progress</TaskInfoTitle>
              <TaskInfoValue>
                {task?.totalCompleted}/{task?.totalSent}
              </TaskInfoValue>
            </TaskInfoSection> */}
            <TaskStatusSection>
              <TaskInfoTitle
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Survey Status
              </TaskInfoTitle>
              <StatusContainer
                style={{
                  backgroundColor: statusColors[task?.status_text]?.container,
                  color: statusColors[task?.status_text]?.text,
                }}
              >
                {task?.status_text}
              </StatusContainer>
            </TaskStatusSection>
          </TaskInfoContainer>
          <TaskQuestionSection>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleAccordionClick}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Icon name="dropdown" />
                  <TaskInfoTitle>Questions</TaskInfoTitle>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                {task?.questions.map((question) => (
                  <TaskQuestion key={question?.id}>
                    <TaskQuestionText>{question?.question}</TaskQuestionText>
                    <TaskQuestionText>
                      {question?.aiEnhanced ? "AI Enhanced" : "Not AI Enhanced"}
                    </TaskQuestionText>
                  </TaskQuestion>
                ))}
              </Accordion.Content>
            </Accordion>
          </TaskQuestionSection>
          <AssigneeSection>
            <Accordion>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleAccordionClick}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Icon name="dropdown" />
                  <TaskInfoTitle>Assignees</TaskInfoTitle>
                </div>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <Assignees
                  assignees={task?.assigned_employees}
                  template={task}
                />
              </Accordion.Content>
            </Accordion>
          </AssigneeSection>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

const TaskItem = ({ task, onClick }) => {
  const navigate = useNavigate();
  return (
    <TaskContainer>
      <TaskTitle>{task.name}</TaskTitle>
      <TaskDate>
        Updated: {format(new Date(task?.updated_at), "MM/dd/yyyy")}
      </TaskDate>

      <TaskStatus>
        <StatusContainer
          style={{
            backgroundColor: statusColors[task.status_text].container,
            color: statusColors[task.status_text].text,
          }}
        >
          {task.status_text}
        </StatusContainer>
      </TaskStatus>
      <TaskCompletion>
        {task?.assigned_employees?.length > 0 ? (
          <>
            <Icon
              name="user"
              style={{ fontSize: 16, color: "#B3B3B3", marginRight: 6 }}
            />
            {task?.assigned_employees?.length}
          </>
        ) : (
          "Unassigned survey"
        )}
      </TaskCompletion>
      <ViewButton
        onClick={() => navigate(`/leader/audit/manage/task/${task.id}`)}
      >
        View
      </ViewButton>
    </TaskContainer>
  );
};

const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 8px 20px 0px #3f86fe14;
  justify-content: space-between;
  width: 100%;
`;

const TaskTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const TaskDate = styled.div`
  font-size: 14px;
  color: #666d79;
`;

const TaskStatus = styled.div`
  font-size: 14px;
`;

const ViewButton = styled.div`
  color: #2d70e2;
  font-size: 12px;
  padding: 6px 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border: none;
  border-radius: 5px;
  margin-left: 20px;
`;

const TaskCompletion = styled.div`
  font-size: 14px;
  color: #666d79;
  display: flex;
  flex-direction: row;
`;

const TopDescription = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Raleway;
`;

const TaskInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;

const TaskInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 10px;
  font-size: 14px;
  font-family: Raleway;
  color: #666d79;
  margin-bottom: 10px;
`;

const TaskStatusSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 10px;
  font-size: 14px;
  font-family: Raleway;
  color: #666d79;
  margin-bottom: 10px;
`;

const TaskInfoTitle = styled.div`
  font-weight: 600;
  margin-bottom: 5px;
  font-family: Raleway;
  font-size: 14px;
  color: #666d79;
`;

const TaskInfoValue = styled.div`
  font-family: Raleway;
  font-size: 14px;
  color: #666d79;
`;

const TaskQuestionSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  font-family: Raleway;
  font-size: 14px;
`;

const TaskQuestion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #f8f8f8;
  font-family: Raleway;
  font-size: 14px;
  color: #666d79;
  border: 1px solid #dfdfdf;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  opacity: ${(props) => (props.enabled ? 1 : 0.5)};
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
  font-family: "Raleway";
  border-radius: 8px;
`;

const TaskQuestionText = styled.div`
  font-family: Raleway;
  font-size: 14px;
  color: #666d79;
  width: 80%;
`;

const SAMPLE_TEMPLATES = [
  {
    id: 1,
    name: "Sample Template 1",
    description: "This is a sample template",
    created_by: "John Doe",
    updated_at: "2021-09-01",
    status: "Scheduled",
    totalSent: 0,
    totalCompleted: 0,
    scheduled_date: "2021-09-10",
    questions: [
      {
        id: 1,
        question: "Sample Question 1",
        type: "text",
        aiEnhanced: false,
      },
      {
        id: 2,
        question: "Sample Question 2",
        type: "text",
        aiEnhanced: true,
      },
    ],
  },
  {
    id: 2,
    name: "Sample Template 2",
    totalSent: 10,
    totalCompleted: 5,
    description: "This is a sample template",
    created_by: "Jane Doe",
    updated_at: "2021-09-02",
    status: "Pending",
    scheduled_date: "2021-09-10",
    questions: [
      {
        id: 1,
        question: "Sample Question 1",
        type: "text",
        aiEnhanced: false,
      },
    ],
  },
  {
    id: 3,
    name: "Sample Template 3",
    description: "This is a sample template",
    created_by: "John Doe",
    updated_at: "2021-09-03",
    scheduled_date: "2021-09-10",
    totalSent: 10,
    totalCompleted: 10,
    status: "Completed",
    questions: [
      {
        id: 1,
        question: "Sample Question 1",
        type: "text",
        aiEnhanced: false,
      },
    ],
  },
];

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`;

const ShareButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TableHead = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #666d79;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
`;

const SectionDescription = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
`;

const SubInfo = styled.div`
  font-size: 14px;
  font-family: "Raleway", sans-serif;
`;

const ContainerRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10px;
`;

const AssigneeHeader = styled.div`
  display: flex;
  width: 100%;
`;

const AssigneeCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
`;

const AssigneeHeaderCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
`;

const AssigneeRow = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
`;

const AssigneeTable = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  font-family: "Raleway", sans-serif;
  margin-top: 20px;
`;

const ContainerActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const EmptyText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #666d79;
  padding: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TemplateName = styled.div``;

const TableData = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
`;

const AssigneeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const AssigneeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Assignee = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#2d70e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d70e2")};
  border: 1px solid #2d70e2;
`;

export default PendingList;
