import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Questions from "./Questions";

import {
  generate_chart_change_data,
  generate_chart_factor_changes,
  generate_question_changes,
  generate_outcome_question_changes,
} from "../calculations";
import Chart from "react-apexcharts";
import { SET_NEW_NAVIGATION } from "constants/actions";
import { filter_data, get_overall_average } from "reports/Tag/calculations";
import Empty from "./components/EmptyChange";
import Navigation from "./Navigation";
import AICopilot from "./components/AICopilot";

const Change = ({ navigation, rule, outcomeQ }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState();
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorOptions, setAnchorOptions] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [AIChatOpen, setAIChatOpen] = useState(false);

  const { latestData, lastData, core_data, selectedFilters } = useSelector(
    (state) => ({
      anchor: state.audit?.anchor,
      get_survey_questions: state.surveyquestions.survey_questions,
      selectedFilters: state.audit?.new_filters,
      latestData: state.audit?.filtered_data,
      lastData: state.audit?.filtered_last_data,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    // Set the navigation to the default value
    const defaultNav = {
      selected1: null,
      selected2: null,
      selected3: null,
      factor: null,
      outcome: {
        name: "Culture",
        id: 0,
      },
    };
    dispatch({
      type: SET_NEW_NAVIGATION,
      payload: defaultNav,
    });
  }, []);

  useEffect(() => {
    if (latestData && latestData?.length > 0) {
      let filteredData;
      if (latestData?.[0]?.[0]) {
        filteredData = filter_data(latestData[0], selectedFilters);
      } else {
        filteredData = filter_data(latestData, selectedFilters);
      }
      setCurrentData(filteredData);
      let filteredLastData;
      if (lastData?.[0]?.[0]) {
        filteredLastData = filter_data(lastData[0], selectedFilters);
      } else {
        filteredLastData = filter_data(lastData, selectedFilters);
      }

      setPrevData(filteredLastData);
    }
  }, [latestData, selectedFilters, lastData]);

  // Data to be displayed when overview is selected and culture
  useEffect(() => {
    if (currentData && prevData.length > 0) {
      if (
        navigation?.outcome?.id > 0 &&
        !navigation?.sub_category &&
        navigation?.factor?.id
      ) {
        if (navigation.factor?.id) {
          setQuestionData(
            generate_outcome_question_changes(
              currentData,
              prevData,
              navigation,
              outcomeQ,
              rule
            )
          );
          return;
        }
      }

      if (navigation.factor?.name && navigation.factor?.name != "Overview") {
        setQuestionData(
          generate_question_changes(
            currentData,
            prevData,
            navigation,
            core_data?.questions,
            rule
          )
        );

        return;
      }

      if (navigation?.outcome && !navigation?.sub_category?.id) {
        let data_type = navigation?.outcome;

        setChartData(
          generate_chart_change_data(
            currentData,
            prevData,
            navigation,
            core_data?.categories?.[0],
            data_type,
            rule
          )
        );

        return;
      }
      if (
        navigation?.sub_category?.id ||
        navigation.sub_category?.name == "Factor overview"
      ) {
        let data_type = navigation?.outcome;
        // if (data_type?.id > 0) {
        //   setChartData(null);
        //   return;
        // }
        setChartData(
          generate_chart_factor_changes(
            currentData,
            prevData,
            navigation,
            core_data?.questions,
            rule
          )
        );
      }
    } else {
      setChartData(null);
      setLoading(false);
    }
  }, [prevData, currentData, navigation, selectedFilters]);

  useEffect(() => {
    if (chartData) {
      setLoading(true);
      let sorted_data = chartData.sort((a, b) => (b.diff > a.diff ? 1 : -1));
      let _series = [
        {
          data: sorted_data.map((item) => {
            return {
              x: item.name,
              y: Math.round(item.diff) === 0 ? 0.1 : Math.round(item.diff),
              id: item.option_id,
            };
          }),
        },
      ];
      let _options = data3.options;
      _options.colors = sorted_data.map((item) =>
        Math.round(item.diff) >= 0 ? "#27CDA7" : "#F15062"
      );
      _options.xaxis.categories = sorted_data.map((item) => item.name);

      let max = Math.max(...sorted_data.map((item) => item.diff)) + 5;
      let min = Math.min(...sorted_data.map((item) => item.diff));

      _options.yaxis.max = max > 0 ? max : 0;

      _options.yaxis.min = min < 0 ? min : 0;

      _options.yaxis.title.text = `${navigation?.outcome?.name} score changes`;

      _options.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          let nav = { ...navigation };
          if (navigation?.sub_category?.name !== "Overview") {
            nav.factor = {
              name: sorted_data[config.dataPointIndex].name,
              id: sorted_data[config.dataPointIndex].option_id,
              dimension: sorted_data[config.dataPointIndex].dimension,
              type: "factor",
            };
          } else {
            nav.sub_category = {
              name: sorted_data[config.dataPointIndex].name,
              id: sorted_data[config.dataPointIndex].option_id,
              type: "cat_option",
            };
          }
          dispatch({
            type: SET_NEW_NAVIGATION,
            payload: nav,
          });
        },
      };

      setSeries(_series);
      setOptions(_options);
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    }
  }, [chartData, navigation]);

  const renderChart = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    if (navigation.factor?.name && navigation.factor?.name != "Overview") {
      return (
        <>
          <Questions
            data={questionData}
            navigation={navigation}
            isChange={true}
            setAIChatOpen={setAIChatOpen}
            read_feedback={core_data?.permissions?.read_feedback}
          />
        </>
      );
    }

    return (
      <Chart
        options={options}
        series={series}
        type="bar"
        width={800}
        height={600}
      />
    );
  };

  return (
    <>
      {AIChatOpen && (
        <AICopilot
          open={AIChatOpen}
          setOpen={setAIChatOpen}
          navigation={navigation}
        />
      )}
      <Container>
        <Title>
          {navigation?.outcome?.id === 0
            ? selected
              ? `${anchorOptions.options[selected].name} culture factor`
              : "Overall culture"
            : navigation?.outcome?.name}{" "}
          score changes
        </Title>
        <Description>
          The following chart shows the difference between the most recent
          scores for each of the selected organization entities.
        </Description>

        <Navigation outcomes={outcomeQ} core_data={core_data} />

        {!loading && (!chartData || chartData?.length === 0) ? (
          <Empty />
        ) : (
          renderChart()
        )}
      </Container>
    </>
  );
};

export default Change;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
`;

const data3 = {
  series: [
    {
      data: [10, 8, 4.4, -3, -8, -20, -22],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 800,
      height: 600,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          if (val === 0.1) {
            return "0%";
          }
          return val + "%";
        },
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        if (val === 0.1) {
          return "0";
        }
        return val;
      },
    },
    colors: [
      "#27CDA7",
      "#27CDA7",
      "#27CDA7",
      "#F15062",
      "#F15062",
      "#F15062",
      "#F15062",
    ],
    xaxis: {
      categories: [
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department G",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
          colors: "#2D70E2",
        },
        rotateAlways: true,
        minHeight: 200,
      },
    },
    yaxis: {
      min: -30,
      max: 20,
      decimalsInFloat: 0,
      forceNiceScale: true,
      title: {
        text: "Overall culture score changes",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};

const data4 = {
  series: [
    {
      data: [17, 15, 5, -3, -8, -9, -10],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 800,
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    grid: {
      padding: {
        bottom: 60,
      },
    },
    colors: [
      "#27CDA7",
      "#27CDA7",
      "#27CDA7",
      "#F15062",
      "#F15062",
      "#F15062",
      "#F15062",
    ],
    xaxis: {
      categories: [
        "Psychological Safety",
        "Adaptability",
        "Empathy",
        "Belonging",
        "Learning",
        "Values",
        "Clarity",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
          colors: "#2D70E2",
        },
        rotateAlways: true,
        maxHeight: 200,
      },
    },
    yaxis: {
      min: -30,
      max: 20,
      decimalsInFloat: 0,
      title: {
        text: "Overall culture score changes",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
